import { once } from 'lodash/fp'
import { readonly, ref } from 'vue'
import { EnhancedWatchlist, UseWatchListAPI } from './types'
import {
  getAddProductToWatchlistEndpoint,
  createWatchListEndpoint,
  getWatchListsEndpoint,
  getRenameWatchListEndpoint,
  getDeleteWatchListEndpoint,
  getDeleteProductFromWatchlistEndpoint,
  getUpdateProductInWatchlistEndpoint,
  getResetWatchlistProductsQuantityEndpoint,
  getImportWatchListEndpoint,
} from '../../constants/api-endpoints'
import parseResponse from '../../util/misc/parse-response'
import createRequestWithJsonPayload from '../../util/misc/create-request-with-json-payload'

const getWatchListAPI = once(
  (initialValue?: EnhancedWatchlist[]): UseWatchListAPI => {
    const watchlist = ref<EnhancedWatchlist[]>(initialValue ?? [])
    const refreshWatchlistRef = (newWatchlist: EnhancedWatchlist[]) => {
      watchlist.value = newWatchlist
      return newWatchlist
    }
    // each update to the watchlist should return us the complete watchlist with the updates applied to it
    const onSuccess = (res: Response) =>
      parseResponse<EnhancedWatchlist[]>(res).then(refreshWatchlistRef)
    const getWatchlist = (): Promise<EnhancedWatchlist[]> =>
      fetch(getWatchListsEndpoint()).then(onSuccess)
    const createWatchlist = ({ name }: { name: string }) =>
      createRequestWithJsonPayload(createWatchListEndpoint(), { name }).then(
        onSuccess,
      )
    const renameWatchlist = (watchlistId: string, { name }: { name: string }) =>
      createRequestWithJsonPayload(getRenameWatchListEndpoint(watchlistId), {
        name,
      }).then(onSuccess)
    const deleteWatchlist = (watchlistId: string) =>
      fetch(getDeleteWatchListEndpoint(watchlistId), {
        method: 'delete',
      }).then(onSuccess)
    const resetProductsQuantity = (watchlistId: string) =>
      fetch(getResetWatchlistProductsQuantityEndpoint(watchlistId)).then(
        (res) => parseResponse<EnhancedWatchlist>(res),
      )
    const addProduct = (
      watchlistId: string,
      productId: string,
      quantity?: number,
      unit?: string,
    ) =>
      createRequestWithJsonPayload(
        getAddProductToWatchlistEndpoint(watchlistId, productId),
        {
          quantity: quantity ?? 1,
          unit: unit ?? 'trade_unit',
        },
      ).then((res) => parseResponse<EnhancedWatchlist>(res))
    const removeProduct = (watchlistId: string, productId: string) =>
      fetch(getDeleteProductFromWatchlistEndpoint(watchlistId, productId), {
        method: 'delete',
      }).then((res) => parseResponse<EnhancedWatchlist>(res))
    const updateProduct = (
      watchlistId: string,
      productId: string,
      quantity: number,
      unit: string,
    ) =>
      createRequestWithJsonPayload(
        getUpdateProductInWatchlistEndpoint(watchlistId, productId),
        {
          quantity,
          unit,
        },
      ).then((res) => parseResponse<EnhancedWatchlist>(res))
    const importWatchlist = (file: File) => {
      const formData = new FormData()

      formData.append('import_file', file)

      return fetch(getImportWatchListEndpoint(), {
        method: 'post',
        body: formData,
      }).then(onSuccess)
    }

    return [
      watchlist,
      {
        importWatchlist,
        getWatchlist,
        createWatchlist,
        deleteWatchlist,
        renameWatchlist,
        resetProductsQuantity,
        addProduct,
        removeProduct,
        updateProduct,
      },
    ]
  },
)

export default function useWatchlist(initialValue?: EnhancedWatchlist[]) {
  return readonly(getWatchListAPI(initialValue))
}
