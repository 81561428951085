import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "products-finder-result" }
const _hoisted_2 = {
  key: 0,
  class: "u-reset products-finder-result__list"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_product_list_item = _resolveComponent("product-list-item")!
  const _component_generic_inline_loader = _resolveComponent("generic-inline-loader")!
  const _component_empty_state = _resolveComponent("empty-state")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.products.length)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.products, (product, index) => {
            return (_openBlock(), _createElementBlock("li", {
              class: "products-finder-result__list-item",
              key: index
            }, [
              _createVNode(_component_product_list_item, {
                "is-counter-enabled": true,
                "show-counter": true,
                "on-quantity-updated": 
              (newQuantity) =>
                _ctx.updateProductAmount(product.itemNumber, newQuantity)
            ,
                product: product
              }, null, 8, ["on-quantity-updated", "product"])
            ]))
          }), 128))
        ]))
      : (_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_generic_inline_loader, { key: 1 }))
        : (_openBlock(), _createBlock(_component_empty_state, {
            key: 2,
            text: _ctx.translate('shop__products_finder__no_results')
          }, null, 8, ["text"]))
  ]))
}