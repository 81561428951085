import { getOr } from 'lodash/fp'
import globals from '../../globals'

export default function translate(
  path: string,
  data?: Record<string, string | number>,
) {
  const res = getOr(path, path, globals.translations)

  if (data) {
    // simple template rendering in case we have dynamic elements in the translations
    return res.replace(
      /{(\w+)}/gi,
      (_: string, key: string) => data?.[key.trim()] ?? '',
    )
  }

  return res
}
