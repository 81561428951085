<template>
  <div class="grid__row">
    <slot name="zip-code-filter" :filter="filter"></slot>
    <ul class="u-reset sales-team-member-list__member-list">
      <li
        class="sales-team-member-list__member-card"
        v-for="(item, itemIdx) in currentItems"
        :key="itemIdx"
      >
        <img
          class="sales-team-member-list__portrait"
          :srcset="`${item.image.urls.default[0]} 1x,
                            ${item.image.urls.default[1]} 2x`"
          :alt="item.image.alt"
          :title="item.full_name"
        />
        <h3 class="u-reset sales-team-member-list__member-name">
          <span>{{ item.full_name }}</span>
        </h3>
        <div class="sales-team-member-list__description">
          <p class="sales-team-member-list__job-description">
            {{ item.job_description }}
          </p>
          <p
            class="sales-team-member-list__sales_region"
            v-if="hasRegion(item.sales_region.own)"
          >
            {{ item.sales_region.own }}
          </p>
        </div>
        <a class="sales-team-member-list__link" :href="`mailto:${item.email}`">
          <icon name="mail" size="medium" />
          E-Mail
        </a>
        <a
          class="sales-team-member-list__link"
          :href="`tel:${item.phone_number?.replace(/\s/g, '')}`"
        >
          <icon name="phone" size="medium" />
          {{ item.phone_number }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
  import Icon from '../../common/icon/icon.vue'

  export default {
    components: {
      Icon,
    },
    props: {
      initialData: {
        type: String,
        required: true,
      },
    },
    setup() {
      const hasRegion = (region) => {
        return region !== 'None'
      }
      return {
        hasRegion,
      }
    },
    data() {
      const data = JSON.parse(this.initialData)
      return {
        items: data.items,
        currentItems: data.items,
        filter: {
          value: '',
        },
      }
    },
    methods: {
      filterItems(items, filter) {
        return !filter
          ? items
          : items.filter((item) =>
              item.sales_region.all.some((v) => {
                return String(v).startsWith(filter)
              }),
            )
      },
    },
    watch: {
      filter: {
        deep: true,
        immediate: false,
        handler(filter) {
          this.currentItems = this.filterItems(this.items, filter.value)
        },
      },
    },
  }
</script>
