import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "categories-menu__main" }
const _hoisted_2 = { class: "categories-menu__list u-reset" }
const _hoisted_3 = {
  key: 0,
  class: "categories-menu__footer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_category_item = _resolveComponent("category-item")!
  const _component_simple_button = _resolveComponent("simple-button")!

  return (_openBlock(), _createElementBlock("nav", {
    class: _normalizeClass(["categories-menu", _ctx.rootClasses]),
    id: "categories-menu"
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h2", {
        class: _normalizeClass(["categories-menu__title u-reset", _ctx.titleClasses]),
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.reset && _ctx.reset(...args)))
      }, _toDisplayString(_ctx.translate('shop__categories__all')), 3),
      _createElementVNode("ul", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (category, index) => {
          return (_openBlock(), _createBlock(_component_category_item, {
            category: category,
            key: index
          }, null, 8, ["category"]))
        }), 128))
      ])
    ]),
    (_ctx.hasFooter)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_simple_button, {
            facets: ['primary', 'round'],
            onClick: _ctx.close
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.translate('shop__categories__close')), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ]))
      : _createCommentVNode("", true)
  ], 2))
}