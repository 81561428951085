import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!
  const _component_simple_button = _resolveComponent("simple-button")!

  return (_openBlock(), _createBlock(_component_simple_button, {
    facets: ['inline', 'small-text'],
    class: "cart-toggle",
    onClick: _ctx.toggle
  }, {
    default: _withCtx(() => [
      _createVNode(_component_icon, {
        name: "cart",
        size: "medium"
      }),
      (_ctx.itemsAmount)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createTextVNode(" (" + _toDisplayString(_ctx.itemsAmount) + ") ", 1)
          ], 64))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["onClick"]))
}