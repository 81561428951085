
  import { computed, defineComponent, PropType, ref } from 'vue'
  import SimpleButton from '../../common/buttons/simple-button/simple-button.vue'
  import ProductCounter from '../../common/product-counter/product-counter.vue'
  import Icon from '../../common/icon/icon.vue'
  import { ProductImageIds } from '../../../constants/product-image-ids'
  import useCart from '../../../hooks/use-cart/use-cart'
  import useSnackbar, {
    SnackbarType,
  } from '../../../hooks/use-snackbar/use-snackbar'
  import Spinner from '../../common/spinner/spinner.vue'
  import translate from '../../../util/misc/translate'
  import { EnhancedOderItem } from '../../../hooks/use-cart/types'
  import getProductImageById from '../../../util/misc/get-product-image-by-id'
  import formatDateToLocaleDateString from '../../../util/strings/format-date-to-locale-date-string'

  export default defineComponent({
    components: { Spinner, Icon, ProductCounter, SimpleButton },
    props: {
      item: {
        type: Object as PropType<EnhancedOderItem>,
        required: true,
      },
      hasDeliveryIcon: {
        type: Boolean,
        default: false,
      },
      isDisabled: {
        type: Boolean,
        required: true,
      },
      date: {
        type: String,
        default: '',
      },
    },
    setup(props) {
      const [, { updateProductQuantity, deleteProduct }] = useCart()
      const [, { show }] = useSnackbar()
      const isUpdating = ref<boolean>(false)
      const isDeleting = ref<boolean>(false)
      const getProductImages = (
        product: EnhancedOderItem,
      ): [string, string] => {
        const imageSources = product?.image?.sources ?? []

        return [
          getProductImageById(ProductImageIds.LISTING, imageSources).url,
          getProductImageById(ProductImageIds.LISTING_X2, imageSources).url,
        ]
      }
      const guessedMinimumQuantity = computed(
        () =>
          props.item?.variant?.dreipolAttributes?.ordering_unit
            ?.minimumQuantity as number,
      )
      const formattedDate = computed(() => {
        if (!props.date) {
          return ''
        }
        return formatDateToLocaleDateString(new Date(props.date), {
          weekday: 'short',
        })
      })
      const onError = (error: Error) => {
        show({
          type: SnackbarType.ERROR,
          text: error.message,
          id: `product-item-error-${new Date()}`,
        })
      }

      return {
        translate,
        isDeleting,
        isUpdating,
        formattedDate,
        images: computed(() => getProductImages(props.item)),
        guessedMinimumQuantity,
        isGuessedMinimumQuantityVisible: computed(() => {
          return guessedMinimumQuantity.value > 1
        }),
        guessedQuantityIncrement: computed(
          () =>
            props.item?.variant?.dreipolAttributes?.ordering_unit
              ?.quantityIncrement ?? 1,
        ),
        onDelete() {
          const onDone = () => {
            isDeleting.value = false
          }

          isDeleting.value = true

          deleteProduct(props.item.id).catch(onError).finally(onDone)
        },
        onQuantityChange(newQuantity: number) {
          const onDone = () => {
            isUpdating.value = false
          }

          isUpdating.value = true

          updateProductQuantity(props.item.id, newQuantity, props.item.unit)
            .catch(onError)
            .finally(onDone)
        },
      }
    },
  })
