// We need to use loops here :(
/* eslint-disable fp/no-loops, fp/no-let, prefer-const, no-restricted-syntax */

type Tree<T> = {
  children?: Array<Tree<T>>
} & T

const containsItems = (result: unknown[]) => result.filter(Boolean).length > 1

export default function find<T>(
  item: Tree<T>,
  fn: (item: Tree<T>) => boolean,
): [Tree<T> | null, Tree<T> | null] {
  if (item.children) {
    for (let child of item.children) {
      if (fn(child)) return [child, item]
      if (child.children && child.children.length) {
        const res = find(child, fn)
        if (containsItems(res)) return res
      }
    }
  }

  return [null, null]
}
