import { mergeProps as _mergeProps, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "empty-state" }
const _hoisted_2 = { class: "empty-state__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", _mergeProps({ class: "empty-state__image" }, _ctx.image), null, 16),
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.text), 1)
  ]))
}