export enum ProductImageIds {
  LG = 'lg',
  LG_X2 = 'lg-2x',
  MD = 'md',
  MD_X2 = 'md-2x',
  SM = 'sm',
  SM_X2 = 'sm-2x',
  XS = 'xs',
  XS_X2 = 'xs-2x',
  LISTING = 'listing',
  LISTING_X2 = 'listing-2x',
}
