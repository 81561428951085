import { once } from 'lodash/fp'
import { ref, readonly, markRaw } from 'vue'
import { Snackbar, UseSnackbarAPI } from './types'

export enum SnackbarType {
  ERROR = 'error',
  WARNING = 'warning',
  SUCCESS = 'success',
}

const getSnackbarAPI = once((): UseSnackbarAPI => {
  const snackbars = ref<Snackbar[]>([])

  function show(snackbar: Snackbar) {
    snackbars.value = [...snackbars.value, markRaw(snackbar)]
  }

  function remove(snackbarId: string) {
    snackbars.value = snackbars.value.filter(({ id }) => id !== snackbarId)
  }

  return [
    snackbars,
    {
      show,
      remove,
    },
  ]
})

export default function useSnackbar() {
  return readonly(getSnackbarAPI())
}
