import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "snackbar__icon" }
const _hoisted_2 = { class: "snackbar__message u-reset" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!
  const _component_simple_button = _resolveComponent("simple-button")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["snackbar", _ctx.rootClasses])
  }, [
    _createElementVNode("span", _hoisted_1, [
      _createVNode(_component_icon, {
        name: "error-outline",
        size: "medium"
      })
    ]),
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.text), 1),
    _createVNode(_component_simple_button, {
      class: "snackbar__close",
      facets: ['inline', 'bubble'],
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.removeSnackbar({ id: _ctx.id })))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_icon, {
          name: "close-full",
          size: "small"
        })
      ]),
      _: 1
    })
  ], 2))
}