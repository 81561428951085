
  import { defineComponent, ref } from 'vue'
  import ProductWatchlistButton from '../product-watchlist-button/product-watchlist-button.vue'
  import ProductDetailCartButton from '../product-detail-cart-button/product-detail-cart-button.vue'
  import CustomSelect from '../custom-select/custom-select.vue'
  import translate from '../../../util/misc/translate'
  import useCart from '../../../hooks/use-cart/use-cart'
  import useSnackbar, {
    SnackbarType,
  } from '../../../hooks/use-snackbar/use-snackbar'

  export default defineComponent({
    components: {
      ProductWatchlistButton,
      ProductDetailCartButton,
      CustomSelect,
    },
    props: {
      productId: {
        type: String,
        required: true,
      },
      baseUnit: {
        type: String,
        required: true,
      },
      numberOfUnits: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      const [, { addProduct }] = useCart()
      const [, { show }] = useSnackbar()
      const quantity = ref<number>(1)
      const unit = ref<string>(props.baseUnit || 'trade_unit')
      const isLoading = ref<boolean>(false)
      const onDone = () => {
        isLoading.value = false
      }
      const onError = (error: Error) => {
        show({
          type: SnackbarType.ERROR,
          text: error.message,
          id: `product-button-error-${new Date()}`,
        })
      }

      return {
        translate,
        quantity,
        unit,
        isLoading,
        addToCart() {
          isLoading.value = true
          addProduct(props.productId, quantity.value, unit.value)
            .catch(onError)
            .finally(onDone)
        },
        onQuantityChange(newQuantity: number) {
          quantity.value = newQuantity
        },
        onUnitChange(event: Event) {
          const { value } = event.target as HTMLSelectElement
          if (!value) {
            return
          }
          unit.value = value
        },
      }
    },
  })
