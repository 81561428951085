import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "custom-select__wrap" }
const _hoisted_2 = {
  key: 0,
  class: "custom-select__label"
}
const _hoisted_3 = { class: "custom-select__field-wrap" }
const _hoisted_4 = { class: "custom-select__field" }
const _hoisted_5 = {
  key: 0,
  class: "custom-select__messages"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["custom-select", _ctx.rootClasses])
  }, [
    _createElementVNode("label", _hoisted_1, [
      (_ctx.label)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.label), 1))
        : _createCommentVNode("", true),
      _createElementVNode("span", _hoisted_3, [
        _createElementVNode("select", _hoisted_4, [
          (_ctx.placeholder)
            ? (_openBlock(), _createElementBlock("option", _mergeProps({
                key: 0,
                disabled: "",
                selected: "",
                value: ""
              }, _ctx.$attrs), _toDisplayString(_ctx.placeholder), 17))
            : _createCommentVNode("", true),
          _renderSlot(_ctx.$slots, "default")
        ]),
        _createVNode(_component_icon, {
          class: "custom-select__icon",
          name: "chevron-down",
          size: "medium"
        })
      ])
    ]),
    (_ctx.$slots.messages)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _renderSlot(_ctx.$slots, "messages")
        ]))
      : _createCommentVNode("", true)
  ], 2))
}