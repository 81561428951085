
  import { defineComponent, PropType } from 'vue'
  import staticPath from '../../../util/misc/static-path'

  export default defineComponent({
    props: {
      image: {
        type: Object as PropType<Record<string, string>>,
        default: () => ({
          src: staticPath('img/empty-states/no-products.svg'),
          alt: 'Empty Bag Illustration',
        }),
      },
      text: {
        type: String,
        required: true,
      },
    },
  })
