import { once } from 'lodash/fp'
import { reactive, readonly } from 'vue'
import { add as addEvent } from 'bianco.events'
import { ViewportSize } from './types'

const getWindowSize = (): ViewportSize => ({
  width: window.innerWidth,
  height: window.innerHeight,
})

// no need to watch the window resize more than once
const getReactiveSize = once(() => {
  const size = reactive<ViewportSize>(getWindowSize())
  const update = () => {
    const { width, height } = getWindowSize()

    size.width = width
    size.height = height
  }

  addEvent(window, 'resize orientationchange', update)

  return size
})

export default function useViewportSize() {
  // notice that this function will be run only once
  const size = getReactiveSize()

  // expose the window size
  return readonly(size)
}
