import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_spinner = _resolveComponent("spinner")!
  const _component_icon = _resolveComponent("icon")!
  const _component_simple_button = _resolveComponent("simple-button")!

  return (_openBlock(), _createBlock(_component_simple_button, {
    disabled: _ctx.isLoading,
    facets: _ctx.facets,
    onClick: _ctx.startDownload
  }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
      (_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_spinner, { key: 0 }))
        : (_openBlock(), _createBlock(_component_icon, {
            key: 1,
            name: "export",
            size: "medium"
          }))
    ]),
    _: 1
  }, 8, ["disabled", "facets", "onClick"]))
}