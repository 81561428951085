
  import {
    defineComponent,
    onMounted,
    onUnmounted,
    ref,
    watchEffect,
  } from 'vue'
  import useClickOutside from '../../../hooks/use-click-outside/use-click-outside'
  import usePanelsCloseQueue from '../../../hooks/use-panels-close-queue/use-panels-close-queue'

  export default defineComponent({
    props: {
      open: {
        type: Boolean,
        default: false,
      },
      hasClickOutside: {
        type: Boolean,
        default: true,
      },
      hasKeyboardBindings: {
        type: Boolean,
        default: true,
      },
    },
    setup(props) {
      const root = ref<unknown>(null)
      const isOpen = ref<boolean>(props.open)
      const panelsCloseQueue = usePanelsCloseQueue()
      const closeDropdown = () => {
        ;(root.value as HTMLElement).removeAttribute('open')
      }

      if (props.hasKeyboardBindings) {
        onUnmounted(() => panelsCloseQueue.unsubscribe(closeDropdown))

        watchEffect(() =>
          isOpen.value
            ? panelsCloseQueue.subscribe(closeDropdown)
            : panelsCloseQueue.unsubscribe(closeDropdown),
        )
      }

      if (props.hasClickOutside) {
        onMounted(() => {
          useClickOutside(root.value as HTMLElement, closeDropdown)
        })
      }

      return {
        isOpen,
        onToggle(event: Event) {
          const selectedItems = (
            event.target as HTMLDetailsElement
          ).parentNode?.parentNode?.querySelectorAll('.is-dropdown')

          if (selectedItems && isOpen.value !== true) {
            selectedItems.forEach((item) => {
              if (item !== (root.value as HTMLElement).parentNode) {
                const detailItem = item.querySelector('details')
                if (detailItem && detailItem.hasAttribute('open')) {
                  detailItem.removeAttribute('open')
                }
              }
            })
          }

          isOpen.value = (event.target as HTMLDetailsElement).open
        },
        root,
      }
    },
  })
