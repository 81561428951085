import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["aria-describedby"]
const _hoisted_2 = ["id", "innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    tabindex: "0",
    class: _normalizeClass(["tooltip", _ctx.rootClasses])
  }, [
    _createElementVNode("div", {
      class: "tooltip__trigger",
      "aria-describedby": _ctx.uuid
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 8, _hoisted_1),
    _createElementVNode("p", {
      class: "tooltip__content u-reset",
      role: "tooltip",
      id: _ctx.uuid,
      innerHTML: _ctx.text
    }, null, 8, _hoisted_2)
  ], 2))
}