
  import {
    computed,
    defineComponent,
    onUnmounted,
    PropType,
    ref,
    toRefs,
    watch,
    watchEffect,
  } from 'vue'
  import useMobileMenu from '../../../hooks/use-mobile-menu/use-mobile-menu'
  import find from '../../../util/tree-traversal/find'
  import Icon from '../../common/icon/icon.vue'
  import useViewportSize from '../../../hooks/use-viewport-size/use-viewport-size'
  import breakpoints from '../../../../design-tokens/breakpoints.json'
  import Dropdown from '../../common/dropdown/dropdown.vue'
  import SimpleButton from '../../common/buttons/simple-button/simple-button.vue'
  import useUser from '../../../hooks/use-user/use-user'
  import translate from '../../../util/misc/translate'
  import useSidePanel from '../../../hooks/use-side-panel/use-side-panel'
  import usePanelsCloseQueue from '../../../hooks/use-panels-close-queue/use-panels-close-queue'

  export interface MenuItem {
    label: string
    isRoot?: boolean
    isActive: boolean
    id: number
    link?: string
    children: MenuItem[]
  }

  export default defineComponent({
    components: { SimpleButton, Dropdown, Icon },
    props: {
      loginLink: {
        type: String,
        required: true,
      },
      logoutLink: {
        type: String,
        required: true,
      },
      userLinks: {
        type: Array as PropType<Partial<MenuItem>[]>,
        default: () => [],
      },
      languages: {
        type: Array as PropType<Partial<MenuItem>[]>,
        required: true,
      },
      items: {
        type: Array as PropType<MenuItem[]>,
        required: true,
      },
    },
    setup(props) {
      const [isOpen, toggleMobileMenu] = useMobileMenu()
      const [, sidePanel] = useSidePanel()
      const panelsCloseQueue = usePanelsCloseQueue()
      const user = useUser()
      const findParentAndSelectedItem = (fn: (item: MenuItem) => boolean) =>
        find(
          {
            label: '',
            id: -1,
            isRoot: true,
            isActive: false,
            children: props.items,
          },
          fn,
        )
      const [initialSelectedItem, initialParent] = findParentAndSelectedItem(
        (item) => item.isActive,
      )
      const { width } = toRefs(useViewportSize())
      const parent = ref(initialParent)
      const selectedItem = ref(initialSelectedItem)
      const isParentVisible = computed(() => {
        return (
          selectedItem?.value?.isRoot !== true &&
          selectedItem?.value?.children?.length
        )
      })
      const closeMobileMenu = () => toggleMobileMenu(false)

      onUnmounted(() => panelsCloseQueue.unsubscribe(closeMobileMenu))

      watchEffect(() =>
        isOpen.value
          ? panelsCloseQueue.subscribe(closeMobileMenu)
          : panelsCloseQueue.unsubscribe(closeMobileMenu),
      )

      watch(isOpen, () => {
        if (isOpen.value) sidePanel.close()
      })

      watch(width, () => {
        if (width.value >= breakpoints.md) {
          toggleMobileMenu(false)
        }
      })

      return {
        isOpen,
        parent,
        selectedItem,
        isParentVisible,
        user,
        translate,
        transitionName: computed(
          () => `trs-side-menu--${isParentVisible.value ? 'left' : 'right'}`,
        ),
        activateParent() {
          selectedItem.value = parent.value
        },
        selectItem(item: MenuItem) {
          const [newSelectedItem, newParent] = findParentAndSelectedItem(
            ({ id }) => id === item.id,
          )

          selectedItem.value = newSelectedItem
          parent.value = newParent
        },
        rootClasses: computed(() => {
          return {
            'side-menu--is-open': isOpen.value,
          }
        }),
        getMenuItemClasses(item: MenuItem) {
          return {
            'side-menu__item-link--is-active': item.isActive,
          }
        },
        activeItems: computed(() => {
          return selectedItem?.value?.children?.length
            ? selectedItem.value.children
            : props.items
        }),
      }
    },
  })
