
  import { defineComponent, PropType, ref } from 'vue'
  import SimpleButton from '../buttons/simple-button/simple-button.vue'
  import Icon from '../icon/icon.vue'
  import translate from '../../../util/misc/translate'
  import Spinner from '../spinner/spinner.vue'
  import parseResponse from '../../../util/misc/parse-response'
  import {
    getFileExportDownloadEndpoint,
    getFileExportStatusEndpoint,
  } from '../../../constants/api-endpoints'
  import { sleep } from '../../../util/misc/sleep'
  import useSnackbar, {
    SnackbarType,
  } from '../../../hooks/use-snackbar/use-snackbar'

  const POLLING_TIMEOUT = 3000

  export default defineComponent({
    components: { Spinner, Icon, SimpleButton },
    props: {
      label: {
        type: String,
        default: translate('shop__overview__export'),
      },
      exportUrl: {
        type: String,
        required: true,
      },
      facets: {
        type: Array as PropType<string[]>,
        default: () => ['inline', 'no-padding'],
      },
    },
    setup(props) {
      const [, { show }] = useSnackbar()
      const isLoading = ref<boolean>(false)

      const onError = (error: Error) => {
        show({
          id: `export-button-error-${new Date()}`,
          text: error.message,
          type: SnackbarType.ERROR,
        })
      }

      const downloadFile = (exportId: string) => {
        window.location.href = getFileExportDownloadEndpoint(exportId)
      }
      const waitForDownloadLink: (
        exportId: string,
      ) => Promise<unknown> = async (exportId) => {
        await sleep(POLLING_TIMEOUT)

        return fetch(getFileExportStatusEndpoint(exportId))
          .then((res) => parseResponse(res))
          .then(
            (data: { status: string }) =>
              data.status === 'finished'
                ? downloadFile(exportId)
                  : waitForDownloadLink(exportId), // eslint-disable-line
          )
      }

      return {
        isLoading,
        startDownload() {
          isLoading.value = true

          fetch(props.exportUrl)
            .then((res) => parseResponse<{ id: string }>(res))
            .then(({ id }) => waitForDownloadLink(id))
            .catch(onError)
            .finally(() => {
              isLoading.value = false
            })
        },
      }
    },
  })
