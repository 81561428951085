
  import {
    defineComponent,
    PropType,
    computed,
    onUnmounted,
    onMounted,
  } from 'vue'
  import useSnackbar, {
    SnackbarType,
  } from '../../../hooks/use-snackbar/use-snackbar'
  import Icon from '../icon/icon.vue'
  import SimpleButton from '../buttons/simple-button/simple-button.vue'
  import usePanelsCloseQueue from '../../../hooks/use-panels-close-queue/use-panels-close-queue'

  export default defineComponent({
    components: { SimpleButton, Icon },
    props: {
      id: {
        type: String,
        required: true,
      },
      type: {
        type: String as PropType<SnackbarType>,
        required: true,
      },
      text: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      const [, { remove }] = useSnackbar()
      const panelsCloseQueue = usePanelsCloseQueue()

      const removeSnackbar = () => {
        remove(props.id)
      }

      onMounted(() => {
        panelsCloseQueue.subscribe(removeSnackbar)
      })

      onUnmounted(() => {
        panelsCloseQueue.unsubscribe(removeSnackbar)
      })

      return {
        rootClasses: computed(() => `snackbar--${props.type}`),
        removeSnackbar,
      }
    },
  })
