import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "product-list-item__wrap"
}
const _hoisted_2 = ["href"]
const _hoisted_3 = { class: "product-list-item__graphic-wrap" }
const _hoisted_4 = ["src", "alt"]
const _hoisted_5 = { class: "product-list-item__figure" }
const _hoisted_6 = ["alt", "src"]
const _hoisted_7 = {
  key: 1,
  class: "product-list-item__badges u-reset"
}
const _hoisted_8 = ["src", "alt"]
const _hoisted_9 = { class: "product-list-item__info" }
const _hoisted_10 = { class: "product-list-item__title u-reset" }
const _hoisted_11 = { class: "product-list-item__meta" }
const _hoisted_12 = { class: "product-list-item__meta-text product-list-item__meta--margin-right" }
const _hoisted_13 = { class: "product-list-item__meta-text product-list-item__meta-text--description" }
const _hoisted_14 = { class: "product-list-item__meta-text product-list-item__meta--margin-right" }
const _hoisted_15 = { class: "product-list-item__meta-text product-list-item__meta-text--description" }
const _hoisted_16 = { class: "product-list-item__features u-reset" }
const _hoisted_17 = { key: 0 }
const _hoisted_18 = ["src", "alt"]
const _hoisted_19 = { class: "product-list-item__meta product-list-item__meta--bottom u-reset" }
const _hoisted_20 = { class: "product-list-item__meta-text" }
const _hoisted_21 = { class: "product-list-item__meta-text" }
const _hoisted_22 = {
  key: 0,
  class: "product-list-item__meta-text product-list-item__meta-text--total-price"
}
const _hoisted_23 = {
  key: 0,
  class: "product-list-item__buttons"
}
const _hoisted_24 = {
  key: 1,
  class: "product-list-item__loader"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!
  const _component_product_watchlist_button = _resolveComponent("product-watchlist-button")!
  const _component_product_counter = _resolveComponent("product-counter")!
  const _component_add_to_cart_button = _resolveComponent("add-to-cart-button")!

  return (_openBlock(), _createElementBlock("article", {
    class: _normalizeClass(["product-list-item", _ctx.rootClasses]),
    ref: "root"
  }, [
    (_ctx.isReady)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("a", {
            href: _ctx.product.url,
            class: "product-list-item__link u-reset"
          }, [
            _createElementVNode("div", _hoisted_3, [
              (!_ctx.product.master.availability.available)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    class: "product-list-item__delivery",
                    height: "30",
                    width: "30",
                    src: _ctx.staticPath('img/product-status/break.svg'),
                    alt: _ctx.translate('shop__product__delivery')
                  }, null, 8, _hoisted_4))
                : _createCommentVNode("", true),
              _createElementVNode("figure", _hoisted_5, [
                (_ctx.imageUrl)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      class: "product-list-item__image",
                      alt: _ctx.product.master.name,
                      src: _ctx.imageUrl
                    }, null, 8, _hoisted_6))
                  : _createCommentVNode("", true)
              ]),
              (_ctx.product.master.dreipolAttributes.badges.length)
                ? (_openBlock(), _createElementBlock("ul", _hoisted_7, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.product.master.dreipolAttributes.badges, (badge, index) => {
                      return (_openBlock(), _createElementBlock("li", {
                        class: "product-list-item__badge",
                        key: index
                      }, [
                        _createElementVNode("img", {
                          class: "product-list-item__badge-image",
                          src: _ctx.staticPath(`img/badges/${badge}.svg`),
                          alt: badge
                        }, null, 8, _hoisted_8)
                      ]))
                    }), 128))
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("h2", _hoisted_10, _toDisplayString(_ctx.product.master.name), 1),
              _createElementVNode("dl", _hoisted_11, [
                _createElementVNode("dt", _hoisted_12, _toDisplayString(_ctx.translate('shop__product__artnr')) + ": ", 1),
                _createElementVNode("dd", _hoisted_13, _toDisplayString(_ctx.product.master.sku), 1),
                _createElementVNode("dt", _hoisted_14, [
                  _createTextVNode(_toDisplayString(_ctx.product.master.dreipolAttributes.price.priceType), 1),
                  (!_ctx.user.isAuthenticated)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createTextVNode("*")
                      ], 64))
                    : _createCommentVNode("", true),
                  _createTextVNode(": ")
                ]),
                _createElementVNode("dd", _hoisted_15, _toDisplayString(_ctx.product.master.dreipolAttributes.price.currency) + " " + _toDisplayString(_ctx.product.master.dreipolAttributes.price.unitPrice) + " / " + _toDisplayString(_ctx.product.master.dreipolAttributes.price.unit), 1)
              ]),
              _createElementVNode("ul", _hoisted_16, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.features, (feature, index) => {
                  return (_openBlock(), _createElementBlock("li", {
                    class: "product-list-item__feature",
                    key: index
                  }, [
                    (feature.text)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString(feature.text), 1))
                      : (feature.icon)
                        ? (_openBlock(), _createBlock(_component_icon, {
                            key: 1,
                            name: feature.icon.name,
                            size: feature.icon.size
                          }, null, 8, ["name", "size"]))
                        : (_openBlock(), _createElementBlock("img", {
                            key: 2,
                            class: "product-list-item__feature-image",
                            src: feature.url,
                            alt: feature.title
                          }, null, 8, _hoisted_18))
                  ]))
                }), 128))
              ]),
              _createElementVNode("ul", _hoisted_19, [
                _createElementVNode("li", _hoisted_20, _toDisplayString(_ctx.product.master.attributes.tu_quantity_description?.value), 1),
                _createElementVNode("li", _hoisted_21, [
                  _createTextVNode(_toDisplayString(_ctx.product.master.dreipolAttributes.cu_price.priceType), 1),
                  (!_ctx.user.isAuthenticated)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createTextVNode("*")
                      ], 64))
                    : _createCommentVNode("", true),
                  _createTextVNode(": " + _toDisplayString(_ctx.product.master.dreipolAttributes.cu_price.currency) + " " + _toDisplayString(_ctx.product.master.dreipolAttributes.cu_price.unitPrice) + " / " + _toDisplayString(_ctx.product.master.dreipolAttributes.cu_display_unit), 1)
                ]),
                (_ctx.totalPrice)
                  ? (_openBlock(), _createElementBlock("li", _hoisted_22, _toDisplayString(_ctx.product.master.price?.currency) + " " + _toDisplayString(_ctx.totalPrice), 1))
                  : _createCommentVNode("", true)
              ])
            ])
          ], 8, _hoisted_2),
          (_ctx.user.isAuthenticated)
            ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                _createVNode(_component_product_watchlist_button, {
                  "product-id": _ctx.product.itemNumber,
                  unit: _ctx.product.master.baseOrderingUnit || 'trade_unit'
                }, null, 8, ["product-id", "unit"]),
                (_ctx.canBeAddedToTheCart)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      (_ctx.showCounter)
                        ? (_openBlock(), _createBlock(_component_product_counter, {
                            key: 0,
                            value: _ctx.product.amount,
                            "is-enabled": _ctx.isCounterEnabled,
                            onInput: _ctx.onQuantityUpdated
                          }, null, 8, ["value", "is-enabled", "onInput"]))
                        : (_openBlock(), _createBlock(_component_add_to_cart_button, {
                            key: 1,
                            "product-id": _ctx.product.itemNumber,
                            unit: _ctx.product.master.baseOrderingUnit || 'trade_unit'
                          }, null, 8, ["product-id", "unit"]))
                    ], 64))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_24))
  ], 2))
}