
  import { computed, defineComponent, PropType, ref, toRefs } from 'vue'
  import breakpoints from '../../../../design-tokens/breakpoints.json'
  import translate from '../../../util/misc/translate'
  import { DeliveryDetails } from '../../../types/paloma-api-customers'
  import ProductListItemLite from '../product-list/product-list-item/product-list-item-lite.vue'
  import formatDateToLocaleDateString from '../../../util/strings/format-date-to-locale-date-string'
  import SimpleButton from '../../common/buttons/simple-button/simple-button.vue'
  import useCart from '../../../hooks/use-cart/use-cart'
  import useSnackbar, {
    SnackbarType,
  } from '../../../hooks/use-snackbar/use-snackbar'
  import Spinner from '../../common/spinner/spinner.vue'
  import Icon from '../../common/icon/icon.vue'
  import useViewportSize from '../../../hooks/use-viewport-size/use-viewport-size'

  export default defineComponent({
    components: { Icon, Spinner, SimpleButton, ProductListItemLite },
    props: {
      delivery: {
        type: Object as PropType<DeliveryDetails>,
        required: true,
      },
    },
    setup(props) {
      const [, { show }] = useSnackbar()
      const [, { addMultipleProducts }] = useCart()
      const isAddingItemsToCart = ref<boolean>(false)
      const { width } = toRefs(useViewportSize())

      const onError = (error: Error) => {
        show({
          id: `delivery-detail-error-${new Date()}`,
          text: error.message,
          type: SnackbarType.ERROR,
        })
      }

      console.log('delivery', props.delivery)

      return {
        translate,
        formatDateToLocaleDateString,
        isAddingItemsToCart,
        productsAmount: computed(
          () =>
            props?.delivery?.items?.reduce<number>(
              (acc, item) => acc + (item.quantity as number),
              0,
            ) ?? 0,
        ),
        onAddItemsToCart() {
          // fix API inconsistencies, why should the delivery items be undefined?!
          if (!props.delivery.items) {
            return
          }

          const onDone = () => {
            isAddingItemsToCart.value = false
          }

          isAddingItemsToCart.value = true

          addMultipleProducts(
            props.delivery.items.map((item) => {
              const unit = item.quantityUnit || 'trade_unit'
              return {
                id: item.sku as string,
                quantity: item.quantity as number,
                unit,
              }
            }),
          )
            .catch(onError)
            .finally(onDone)
        },
        guessedUsername: computed(() => {
          return [
            props.delivery?.recipient?.name1,
            props.delivery?.recipient?.name2,
            props.delivery?.recipient?.name3,
          ]
            .filter(Boolean)
            .join(' ')
        }),
        buttonGroupClasses: computed(() =>
          width.value > breakpoints.md ? 'button-group--flex-end' : null,
        ),
        guessedOrderDate: computed(() => {
          return (
            formatDateToLocaleDateString(
              props.delivery?.orders?.[0]?.orderDate,
            ) || '-'
          )
        }),
        guessedOrderNumber: computed(() => {
          return props.delivery?.orders?.[0]?.orderNumber || '-'
        }),
      }
    },
  })
