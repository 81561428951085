<template>
  <div class="barcode">
    <suspense>
      <template #default>
        <generator :code="code" />
      </template>
      <template #fallback>
        <spinner />
      </template>
    </suspense>
    <p class="barcode__paragraph u-reset">{{ prefix }}{{ code }}</p>
  </div>
</template>

<script>
  // notice that typescript was disabled here because Vue.Suspense is not type safe
  import { defineComponent, defineAsyncComponent } from 'vue'
  import Spinner from '../spinner/spinner.vue'

  export default defineComponent({
    components: {
      Spinner,
      generator: defineAsyncComponent(() => import('./barcode-generator.vue')),
    },
    props: {
      code: {
        type: String,
        required: true,
      },
      prefix: {
        type: String,
        required: true,
      },
    },
  })
</script>
