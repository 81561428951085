
  import { computed, defineComponent, PropType, ref } from 'vue'
  import SimpleButton from '../../common/buttons/simple-button/simple-button.vue'
  import Icon from '../../common/icon/icon.vue'
  import EmptyState from '../../common/empty-state/empty-state.vue'
  import { DeliveryPage } from '../../../types/paloma-api-customers'
  import translate from '../../../util/misc/translate'
  import pluralize from '../../../util/strings/pluralize'
  import formatDateToLocaleDateString from '../../../util/strings/format-date-to-locale-date-string'
  import useSnackbar, {
    SnackbarType,
  } from '../../../hooks/use-snackbar/use-snackbar'
  import { getDeliveryListEndpoint } from '../../../constants/api-endpoints'
  import parseResponse from '../../../util/misc/parse-response'
  import Spinner from '../../common/spinner/spinner.vue'

  export default defineComponent({
    components: { Spinner, EmptyState, Icon, SimpleButton },
    props: {
      deliveryPage: {
        type: Object as PropType<DeliveryPage>,
        required: true,
      },
    },
    setup(props) {
      const page = ref<DeliveryPage>({ ...props.deliveryPage })
      const [, { show }] = useSnackbar()
      const deliveries = computed(() => page.value.content)
      const isLoadingMore = ref<boolean>(false)
      const currentPage = ref<number>(0)

      const onSuccess = (data: DeliveryPage) => {
        page.value.content.push(...data.content)
      }

      const onDone = () => {
        isLoadingMore.value = false
      }

      const onError = (error: Error) => {
        show({
          type: SnackbarType.ERROR,
          text: error.message,
          id: `delivery-list-error-${new Date()}`,
        })
      }

      return {
        deliveries,
        isLoadingMore,
        hasLoadMoreButton: computed(
          () => page.value.totalElements > deliveries.value.length,
        ),
        loadMore() {
          isLoadingMore.value = true
          currentPage.value += 1

          fetch(`${getDeliveryListEndpoint()}?p=${currentPage.value}`)
            .then((res) => parseResponse<DeliveryPage>(res))
            .then(onSuccess)
            .catch(onError)
            .finally(onDone)
        },
        pluralize,
        translate,
        formatDateToLocaleDateString,
        hasDeliveries: computed(() => deliveries.value.length),
      }
    },
  })
