import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "standard-overlay" }
const _hoisted_2 = { class: "standard-overlay__content" }
const _hoisted_3 = { class: "standard-overlay__header" }
const _hoisted_4 = { class: "standard-overlay__title u-reset" }
const _hoisted_5 = {
  key: 0,
  class: "standard-overlay__body"
}
const _hoisted_6 = {
  key: 1,
  class: "standard-overlay__footer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!
  const _component_simple_button = _resolveComponent("simple-button")!

  return (_openBlock(), _createElementBlock("aside", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.title), 1),
        _createVNode(_component_simple_button, {
          facets: ['bubble', 'no-padding'],
          onClick: _ctx.close
        }, {
          default: _withCtx(() => [
            _createVNode(_component_icon, {
              name: "close",
              size: "medium"
            })
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      (_ctx.$slots.default)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _renderSlot(_ctx.$slots, "default")
          ]))
        : _createCommentVNode("", true),
      (_ctx.$slots.footer)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _renderSlot(_ctx.$slots, "footer")
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}