
  import { defineComponent } from 'vue'
  import useOverlay from '../../../hooks/use-overlay/use-overlay'
  import OverlayWrapper from '../overlay-wrapper/overlay-wrapper.vue'

  export default defineComponent({
    components: { OverlayWrapper },
    setup() {
      const [overlays] = useOverlay()

      return {
        overlays,
      }
    },
  })
