import { renderSlot as _renderSlot, mergeProps as _mergeProps, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "toggle" }
const _hoisted_2 = { class: "toggle__graphic" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("label", _hoisted_1, [
    _renderSlot(_ctx.$slots, "default"),
    _createElementVNode("input", _mergeProps({ type: "checkbox" }, _ctx.$attrs, {
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args)))
    }), null, 16),
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.toggleLabel), 1)
  ]))
}