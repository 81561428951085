
  import {
    computed,
    defineComponent,
    onMounted,
    onUnmounted,
    PropType,
    ref,
    toRefs,
    watch,
    watchEffect,
  } from 'vue'
  import { debounce, noop } from 'lodash/fp'
  import Icon from '../../common/icon/icon.vue'
  import useProductList from '../../../hooks/use-product-list/use-product-list'
  import useProductsSearch from '../../../hooks/use-products-search/use-products-search'
  import useClickOutside from '../../../hooks/use-click-outside/use-click-outside'
  import Spinner from '../../common/spinner/spinner.vue'
  import useViewportSize from '../../../hooks/use-viewport-size/use-viewport-size'
  import breakpoints from '../../../../design-tokens/breakpoints.json'
  import translate from '../../../util/misc/translate'
  import globals from '../../../globals'
  import isCurrentPageProductList from '../../../util/misc/is-current-page-product-list'
  import ProductWatchlistButton from '../../common/product-watchlist-button/product-watchlist-button.vue'
  import AddToCartButton from '../../common/add-to-cart-button/add-to-cart-button.vue'
  import useUser from '../../../hooks/use-user/use-user'
  import usePanelsCloseQueue from '../../../hooks/use-panels-close-queue/use-panels-close-queue'
  import SimpleButton from '../../common/buttons/simple-button/simple-button.vue'

  const debouncedSearch = debounce(100)

  export default defineComponent({
    components: {
      SimpleButton,
      AddToCartButton,
      ProductWatchlistButton,
      Spinner,
      Icon,
    },
    props: {
      name: {
        type: String,
        required: true,
      },
      placeholder: {
        type: String,
        default: '',
      },
      facets: {
        type: Array as PropType<string[]>,
        default: () => [],
      },
      action: {
        type: String,
        required: true,
      },
    },
    setup(props, context) {
      const [productListState, { updateProducts }] = useProductList()
      const { isAuthenticated } = useUser()
      const { productPage } = toRefs(productListState)
      const panelsCloseQueue = usePanelsCloseQueue()
      const root = ref<HTMLElement>()
      const { width } = toRefs(useViewportSize())
      const isLoading = ref(false)
      const areSuggestionsOpened = ref(false)
      const inputValue = ref<string>((context.attrs.value as string) || '')
      const [productSearchState, { search, reset }] = useProductsSearch()
      const { results } = toRefs(productSearchState)
      const baseUrl = globals.routes.productList
      const hasSuggestions = computed(() =>
        Boolean(results.value.products?.length),
      )
      const areSuggestionsVisible = computed(
        () => areSuggestionsOpened.value && hasSuggestions.value,
      )
      const closeResults = () => {
        areSuggestionsOpened.value = false
      }
      const openResults = () => {
        areSuggestionsOpened.value = true
      }
      const getUrlParams = () => new URLSearchParams(window.location.search)
      const updateProductsListPage = () => {
        const params = getUrlParams()
        params.set(props.name, inputValue.value)

        if (baseUrl) {
          updateProducts(`${baseUrl}?${params.toString()}`)
        }
      }
      const fetchSuggestions = () => {
        // show the loader only if the search takes too long
        const timer = setTimeout(() => {
          isLoading.value = true
        }, 200)

        search(inputValue.value)
          .finally(() => {
            clearTimeout(timer)
            isLoading.value = false
          })
          // silence errors
          .catch(noop)
      }

      onUnmounted(() => panelsCloseQueue.unsubscribe(closeResults))

      watchEffect(() =>
        areSuggestionsOpened.value
          ? panelsCloseQueue.subscribe(closeResults)
          : panelsCloseQueue.unsubscribe(closeResults),
      )

      onMounted(() => {
        useClickOutside(root.value as HTMLElement, closeResults)
      })

      // make sure to cover edge cases where the search is persistent
      watch(productPage, () => {
        const params = getUrlParams()
        // refresh always the search param getting it from the url
        inputValue.value = params.get(props.name) ?? ''
      })

      return {
        root,
        results,
        isLoading,
        inputValue,
        areSuggestionsOpened,
        areSuggestionsVisible,
        isAuthenticated,
        translate,
        clearSearch() {
          inputValue.value = ''
          closeResults()
          reset()

          if (isCurrentPageProductList()) {
            updateProductsListPage()
          }
        },
        onInput(event: KeyboardEvent) {
          openResults()
          inputValue.value = (event.target as HTMLInputElement).value
        },
        onFocus() {
          openResults()
          if (!hasSuggestions.value) {
            fetchSuggestions()
          }
        },
        showAllProducts(event: Event) {
          if (!isCurrentPageProductList()) {
            return
          }

          event.preventDefault()
          updateProducts(results.value.allProducts)
          closeResults()
        },
        onKeyUp: debouncedSearch(fetchSuggestions),
        rootClasses: computed(() => [
          areSuggestionsVisible.value
            ? 'products-search--has-suggestions'
            : null,
          ...props.facets.map((facet) => `products-search--${facet}`),
        ]),
        buttonGroupClasses: computed(
          () =>
            `button-group--${
              width.value >= breakpoints.md ? 'horizontal' : 'vertical'
            }`,
        ),
        onSubmit(event: Event) {
          if (!isCurrentPageProductList()) {
            return
          }

          event.preventDefault()
          updateProductsListPage()
          closeResults()
        },
      }
    },
  })
