
  import { toRefs, defineComponent, computed } from 'vue'
  import useProductList from '../../../hooks/use-product-list/use-product-list'
  import Icon from '../../common/icon/icon.vue'
  import ProductListItem from './product-list-item/product-list-item.vue'
  import SimpleButton from '../../common/buttons/simple-button/simple-button.vue'
  import EmptyState from '../../common/empty-state/empty-state.vue'
  import GenericInfoMessage from '../../common/generic-info-message/generic-info-message.vue'
  import Spinner from '../../common/spinner/spinner.vue'
  import translate from '../../../util/misc/translate'
  import useUser from '../../../hooks/use-user/use-user'

  export default defineComponent({
    components: {
      Spinner,
      GenericInfoMessage,
      EmptyState,
      SimpleButton,
      ProductListItem,
      Icon,
    },
    setup() {
      const [productListState] = useProductList()
      const user = useUser()
      const {
        productPage,
        isLoading,
        loadMore,
        isLoadingMore,
        canLoadMore,
        rendering,
      } = toRefs(productListState)

      return {
        translate,
        productPage,
        isLoading,
        loadMore,
        taxInfo: computed(() =>
          translate(
            user.isAuthenticated ? 'shop__tax_info__logged' : 'shop__tax_info',
          ),
        ),
        isLoadingMore,
        canLoadMore,
        rootClasses: computed(() => [`product-list--${rendering.value}`]),
      }
    },
  })
