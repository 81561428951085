
  import { computed, defineComponent, PropType } from 'vue'
  import { noop } from 'lodash'
  import Dropdown from '../../common/dropdown/dropdown.vue'
  import Checkbox from '../../common/checkbox/checkbox.vue'
  import Icon from '../../common/icon/icon.vue'
  import SimpleButton from '../../common/buttons/simple-button/simple-button.vue'

  export default defineComponent({
    components: { SimpleButton, Icon, Checkbox, Dropdown },
    props: {
      title: {
        type: String,
        required: true,
      },
      facets: {
        type: Array as PropType<string[]>,
        default: () => ['two-cols'],
      },
      onChange: {
        type: Function as PropType<(filterUrl: string) => void>,
        default: noop,
      },
      items: {
        type: Array as PropType<
          Record<string, string | Record<string, string>>[]
        >,
        required: true,
      },
    },
    setup(props) {
      return {
        listClasses: computed(() =>
          props.facets.map((facet) => `product-filters__filter-list--${facet}`),
        ),
      }
    },
  })
