import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { className: "product-detail-main__actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_product_counter = _resolveComponent("product-counter")!
  const _component_spinner = _resolveComponent("spinner")!
  const _component_icon = _resolveComponent("icon")!
  const _component_simple_button = _resolveComponent("simple-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_product_counter, {
      "is-enabled": true,
      value: _ctx.quantity,
      facets: ['horizontal'],
      onInput: _ctx.onQuantityChange
    }, null, 8, ["value", "onInput"]),
    _createVNode(_component_simple_button, {
      facets: ['primary', 'round', 'medium'],
      disabled: _ctx.isLoading,
      onClick: _ctx.addToCart
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.translate('shop__product__add_to_cart_button')) + " ", 1),
        (_ctx.isLoading)
          ? (_openBlock(), _createBlock(_component_spinner, { key: 0 }))
          : (_openBlock(), _createBlock(_component_icon, {
              key: 1,
              name: "cart",
              size: "medium"
            }))
      ]),
      _: 1
    }, 8, ["disabled", "onClick"])
  ]))
}