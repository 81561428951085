import { once } from 'lodash/fp'
import { ref, readonly, onMounted, onUnmounted, Ref } from 'vue'

const subscriptions = new Set<(entry: IntersectionObserverEntry) => void>()

const getObserver = once((options?: IntersectionObserverInit) => {
  return new IntersectionObserver(
    (entries) =>
      entries.forEach((entry) => {
        subscriptions.forEach((fn) => fn(entry))
      }),
    options,
  )
})

export default function useIsVisible(
  root: Ref<HTMLElement | null>,
  mustUnobserveOnIntersecting = true,
) {
  const isVisible = ref(false)
  const observer = getObserver()
  const teardown = () => {
    // eslint-disable-next-line no-use-before-define
    subscriptions.delete(onIntersect)
    if (root.value) {
      observer.unobserve(root.value)
    }
  }
  const onIntersect = (entry: IntersectionObserverEntry) => {
    if (root.value && entry.target === root.value) {
      if (mustUnobserveOnIntersecting && entry.isIntersecting) {
        isVisible.value = true
        teardown()
      } else {
        isVisible.value = entry.isIntersecting
      }
    }
  }

  onMounted(() => {
    if (root.value) {
      observer.observe(root.value)
    }
    subscriptions.add(onIntersect)
  })
  onUnmounted(teardown)

  // expose the window size
  return readonly({
    isVisible,
  })
}
