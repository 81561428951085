import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  class: "cart-panel__info-block-calendar-wrap",
  ref: "root"
}
const _hoisted_2 = {
  key: 0,
  class: "cart-panel__info-block-calendar-box"
}
const _hoisted_3 = { class: "cart-panel__info-block-calendar-header" }
const _hoisted_4 = { class: "cart-panel__info-block-calendar-title u-reset" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_spinner = _resolveComponent("spinner")!
  const _component_icon = _resolveComponent("icon")!
  const _component_simple_button = _resolveComponent("simple-button")!
  const _component_date_picker = _resolveComponent("date-picker")!
  const _component_calendar = _resolveComponent("calendar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isLoadingShippingOptions)
      ? (_openBlock(), _createBlock(_component_spinner, { key: 0 }))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createVNode(_component_date_picker, {
            modelValue: _ctx.deliveryDate,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.deliveryDate) = $event))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_simple_button, {
                class: "cart-panel__info-block-button",
                facets: ['inline', 'no-padding'],
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleCalendar(!_ctx.isCalendarVisible)))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_icon, {
                    name: "calender",
                    size: "medium"
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["modelValue"]),
          (_ctx.isCalendarVisible)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.translate('shop__calendar__header__title')), 1),
                  _createVNode(_component_simple_button, {
                    facets: ['bubble', 'small', 'inline'],
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.toggleCalendar(false)))
                  }, {
                    default: _withCtx(() => [
                      (_ctx.isUpdatingDate)
                        ? (_openBlock(), _createBlock(_component_spinner, { key: 0 }))
                        : (_openBlock(), _createBlock(_component_icon, {
                            key: 1,
                            name: "close",
                            size: "medium"
                          }))
                    ]),
                    _: 1
                  })
                ]),
                _createVNode(_component_calendar, {
                  class: "cart-panel__info-block-calendar",
                  locale: _ctx.currentLanguage,
                  "is-expanded": "",
                  attributes: _ctx.calendarAttributes,
                  "available-dates": _ctx.availableDates,
                  ref: "calendar",
                  onDayclick: _ctx.onDayClick
                }, null, 8, ["locale", "attributes", "available-dates", "onDayclick"])
              ]))
            : _createCommentVNode("", true)
        ], 64))
  ], 512))
}