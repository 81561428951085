import { first, last, isNumber } from 'lodash/fp'

const PLURALIZATION_REGEX = /\|\|\|\|/m

/**
 * Pluralize the sentences if the `||||` separator get detected 4 example: "My singular |||| My plural |||| My super plural"
 * @param {string} translation - translation string
 * @param {number} amount - amount to manage the pluralization
 * @returns {string}
 */
export default function pluralize(translation: string, amount: number) {
  const isPluralized = isNumber(amount) && PLURALIZATION_REGEX.exec(translation)

  // no pluralization detected
  if (!isPluralized) return translation

  // take all the variations for the same vocabulary string
  const variants = translation.split(PLURALIZATION_REGEX)

  // singular handling "1 `Item` found"
  if (amount === 1) return first(variants)
  // plural handling "2 `Items` found"
  return variants[amount - 1] || last(variants)
}
