import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "u-reset" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["inline-notification", _ctx.rootClasses])
  }, [
    _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.message), 1),
    _createVNode(_component_icon, _normalizeProps(_guardReactiveProps(_ctx.icon)), null, 16)
  ], 2))
}