
  import { defineComponent, ref, onMounted } from 'vue'

  export default defineComponent({
    props: {
      code: {
        type: String,
        required: true,
      },
    },
    async setup(props) {
      const root = ref<SVGElement | null>()

      // async setup needs lifecycle hooks before the async calls
      onMounted(() => {
        if (root.value) {
          // eslint-disable-next-line no-use-before-define
          jsBarcode.default(root.value, props.code, {
            format: 'EAN13',
          })
        }
      })

      const jsBarcode = await import('jsbarcode')

      return {
        root,
      }
    },
  })
