import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!
  const _component_simple_button = _resolveComponent("simple-button")!
  const _component_checkbox = _resolveComponent("checkbox")!
  const _component_dropdown = _resolveComponent("dropdown")!

  return (_openBlock(), _createBlock(_component_dropdown, {
    "has-click-outside": false,
    "has-keyboard-bindings": false,
    class: "product-filters__dropdown"
  }, {
    trigger: _withCtx(({ isOpen }) => [
      _createElementVNode("h2", {
        class: _normalizeClass(["product-filters__title product-filters__title--dropdown u-reset", { 'product-filters__title--is-open': isOpen }])
      }, [
        _createVNode(_component_simple_button, {
          tag: "div",
          class: "product-filters__dropdown-toggle",
          facets: ['bubble', 'lite', 'small'],
          role: "button"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_icon, {
              size: "small",
              name: "chevron-right"
            })
          ]),
          _: 1
        }),
        _createTextVNode(" " + _toDisplayString(_ctx.title), 1)
      ], 2)
    ]),
    content: _withCtx(() => [
      _createElementVNode("ul", {
        class: _normalizeClass([_ctx.listClasses, "product-filters__filter-list u-reset"])
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
          return (_openBlock(), _createElementBlock("li", {
            class: "product-filters__filter-item product-filters__filter-item--checkbox",
            key: index
          }, [
            _createVNode(_component_checkbox, {
              checked: item.isActive,
              name: item.code,
              onChange: ($event: any) => (_ctx.onChange(item.url))
            }, {
              before: _withCtx(() => [
                _renderSlot(_ctx.$slots, "before-checkbox", _normalizeProps(_guardReactiveProps(item)))
              ]),
              default: _withCtx(() => [
                _createTextVNode(" " + _toDisplayString(item.label), 1)
              ]),
              _: 2
            }, 1032, ["checked", "name", "onChange"])
          ]))
        }), 128))
      ], 2)
    ]),
    _: 3
  }))
}