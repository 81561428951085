import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, TransitionGroup as _TransitionGroup } from "vue"

const _hoisted_1 = { class: "watchlist" }
const _hoisted_2 = { class: "watchlist__buttons button-group button-group--responsive-horizontal-gap button-group--horizontal" }
const _hoisted_3 = { class: "watchlist__import-button" }
const _hoisted_4 = { class: "watchlist__button-text" }
const _hoisted_5 = { class: "watchlist__button-text" }
const _hoisted_6 = { class: "watchlist__main-content" }
const _hoisted_7 = { class: "watchlist__list-item-main" }
const _hoisted_8 = { class: "watchlist__list-item-name u-reset" }
const _hoisted_9 = { class: "button-group button-group--horizontal button-group--flex-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!
  const _component_simple_button = _resolveComponent("simple-button")!
  const _component_spinner = _resolveComponent("spinner")!
  const _component_empty_state = _resolveComponent("empty-state")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_simple_button, {
        class: "watchlist__create-button",
        facets: ['primary', 'round'],
        onClick: _ctx.createWatchlist
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.translate('shop__watchlist__create__button')) + " ", 1),
          _createVNode(_component_icon, {
            name: "plus",
            size: "medium"
          })
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("input", {
          class: "watchlist__import-input",
          type: "file",
          id: "import-button",
          onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onWatchlistImported && _ctx.onWatchlistImported(...args)))
        }, null, 32),
        _createVNode(_component_simple_button, {
          tag: "label",
          for: "import-button",
          disabled: _ctx.isImportingWatchlist ? 'disabled' : null,
          facets: ['inline', 'no-padding']
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.translate('shop__watchlist__import__button')), 1),
            (_ctx.isImportingWatchlist)
              ? (_openBlock(), _createBlock(_component_spinner, { key: 0 }))
              : (_openBlock(), _createBlock(_component_icon, {
                  key: 1,
                  name: "import",
                  size: "medium"
                }))
          ]),
          _: 1
        }, 8, ["disabled"])
      ]),
      _createVNode(_component_simple_button, {
        tag: "a",
        href: _ctx.exportAllLink,
        class: "watchlist__import-all-button",
        facets: ['inline', 'no-padding']
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.translate('shop__watchlist__export_all__button')), 1),
          _createVNode(_component_icon, {
            name: "export",
            size: "medium"
          })
        ]),
        _: 1
      }, 8, ["href"])
    ]),
    _createElementVNode("div", _hoisted_6, [
      (_ctx.hasItems)
        ? (_openBlock(), _createBlock(_TransitionGroup, {
            key: 0,
            class: "watchlist__list u-reset",
            name: "trs-new-item-background",
            tag: "ul"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.watchlistList, (item) => {
                return (_openBlock(), _createElementBlock("li", {
                  class: "watchlist__list-item",
                  key: item.watchlistId
                }, [
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("h2", _hoisted_8, _toDisplayString(item.name), 1),
                    _createElementVNode("div", {
                      class: _normalizeClass(["button-group button-group--flex-start button-group--responsive-horizontal-gap", _ctx.itemButtonsClass])
                    }, [
                      _createVNode(_component_simple_button, {
                        tag: "a",
                        href: item.exportUrl,
                        facets: ['inline', 'no-padding']
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.translate('shop__watchlist__export__button')) + " ", 1),
                          _createVNode(_component_icon, {
                            name: "import",
                            size: "medium"
                          })
                        ]),
                        _: 2
                      }, 1032, ["href"]),
                      (_ctx.isWatchlistEditable(item))
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                            _createVNode(_component_simple_button, {
                              facets: ['inline', 'no-padding'],
                              onClick: ($event: any) => (_ctx.renameWatchlist(item))
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.translate('shop__watchlist__rename__button')) + " ", 1),
                                _createVNode(_component_icon, {
                                  name: "edit",
                                  size: "medium"
                                })
                              ]),
                              _: 2
                            }, 1032, ["onClick"]),
                            _createVNode(_component_simple_button, {
                              facets: ['inline', 'no-padding'],
                              onClick: ($event: any) => (_ctx.deleteWatchlist(item))
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.translate('shop__watchlist__delete__button')) + " ", 1),
                                _createVNode(_component_icon, {
                                  name: "trash",
                                  size: "medium"
                                })
                              ]),
                              _: 2
                            }, 1032, ["onClick"])
                          ], 64))
                        : _createCommentVNode("", true)
                    ], 2)
                  ]),
                  _createElementVNode("div", _hoisted_9, [
                    _createVNode(_component_simple_button, {
                      class: "watchlist__list-item-link",
                      tag: "a",
                      href: item.url,
                      facets: ['inline', 'no-padding']
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.getProductAmountLabel(item.itemCount)) + " ", 1),
                        _createVNode(_component_icon, {
                          name: "arrow-right",
                          size: "medium"
                        })
                      ]),
                      _: 2
                    }, 1032, ["href"])
                  ])
                ]))
              }), 128))
            ]),
            _: 1
          }))
        : (_openBlock(), _createBlock(_component_empty_state, {
            key: 1,
            text: _ctx.translate('shop__empty__watchlist')
          }, null, 8, ["text"]))
    ])
  ]))
}