import { ProductImageIds } from '../../constants/product-image-ids'
import { ProductImageSource } from '../../types/paloma-api-catalog'
import staticPath from './static-path'

export default function getProductImageById(
  id: ProductImageIds,
  imageSources: ProductImageSource[],
): { url: string } {
  const image = imageSources.find(({ size }) => size === id)

  if (image) return image

  return {
    url: staticPath('img/product-tile/image-placeholder.svg'),
  }
}
