
  import { computed, defineComponent, PropType, ref, toRefs } from 'vue'
  import { first } from 'lodash/fp'
  import useWatchlist from '../../../hooks/use-watchlist/use-watchlist'
  import breakpoints from '../../../../design-tokens/breakpoints.json'
  import EmptyState from '../../common/empty-state/empty-state.vue'
  import translate from '../../../util/misc/translate'
  import SimpleButton from '../../common/buttons/simple-button/simple-button.vue'
  import Icon from '../../common/icon/icon.vue'
  import pluralize from '../../../util/strings/pluralize'
  import useViewportSize from '../../../hooks/use-viewport-size/use-viewport-size'
  import useOverlay from '../../../hooks/use-overlay/use-overlay'
  import {
    creatDeleteWatchlistOverlay,
    createCreateWatchlistOverlay,
    createRenameWatchlistOverlay,
  } from '../../../util/misc/watchlist-overlay-factories'
  import { EnhancedWatchlist } from '../../../hooks/use-watchlist/types'
  import useSnackbar, {
    SnackbarType,
  } from '../../../hooks/use-snackbar/use-snackbar'
  import Spinner from '../../common/spinner/spinner.vue'
  import isWatchlistEditable from '../../../util/misc/is-watchlist-editable'

  export default defineComponent({
    components: {
      Spinner,
      Icon,
      SimpleButton,
      EmptyState,
    },
    props: {
      exportAllLink: {
        type: String,
        required: true,
      },
      watchlists: {
        type: Array as PropType<EnhancedWatchlist[]>,
        required: true,
      },
    },
    setup(props) {
      const [watchlistList, { importWatchlist }] = useWatchlist(
        props.watchlists,
      )
      const { width } = toRefs(useViewportSize())
      const [, { show }] = useSnackbar()
      const [, { open }] = useOverlay()
      const isImportingWatchlist = ref<boolean>(false)

      const onError = (error: Error) => {
        show({
          type: SnackbarType.ERROR,
          text: error.message,
          id: `watchlist-import-error-${new Date()}`,
        })
      }

      return {
        isImportingWatchlist,
        watchlistList,
        isWatchlistEditable,
        translate,
        hasItems: computed(
          () => watchlistList.value && watchlistList.value.length,
        ),
        onWatchlistImported(event: Event) {
          const firstFile = first(
            (event.target as HTMLInputElement).files,
          ) as File

          if (!firstFile) {
            return
          }

          isImportingWatchlist.value = true

          importWatchlist(firstFile)
            .catch(onError)
            .finally(() => {
              isImportingWatchlist.value = false
            })
        },
        getProductAmountLabel: (amount: number) =>
          `${amount} ${pluralize(
            `
                ${translate('shop__watchlist__product')}
                ||||
                ${translate('shop__watchlist__products')}`,
            amount,
          )}`,
        itemButtonsClass: computed(() =>
          width.value > breakpoints.md
            ? 'button-group--horizontal'
            : 'button-group--vertical',
        ),
        renameWatchlist: (watchlist: EnhancedWatchlist) =>
          open(createRenameWatchlistOverlay(watchlist)),
        deleteWatchlist: (watchlist: EnhancedWatchlist) =>
          open(creatDeleteWatchlistOverlay(watchlist)),
        createWatchlist: () => open(createCreateWatchlistOverlay()),
      }
    },
  })
