
  import { defineComponent, ref, PropType, toRefs, computed, watch } from 'vue'
  import useProductList from '../../../hooks/use-product-list/use-product-list'
  import { EnhancedCategory } from '../../../hooks/use-product-list/types'
  import Icon from '../icon/icon.vue'
  import SimpleButton from '../buttons/simple-button/simple-button.vue'
  import translate from '../../../util/misc/translate'

  export default defineComponent({
    name: 'CategoryItem',
    components: { SimpleButton, Icon },
    props: {
      category: {
        type: Object as PropType<EnhancedCategory>,
        required: true,
      },
    },
    setup(props) {
      const [, { updateProducts }] = useProductList()
      const hasSubcategories = () =>
        Boolean(
          props.category.subCategories && props.category.subCategories.length,
        )
      const hasActiveSubcategories = (
        rootCategory: EnhancedCategory,
      ): boolean =>
        rootCategory.subCategories
          ? rootCategory.subCategories.some(
              (category) =>
                category.isActive || hasActiveSubcategories(category),
            )
          : false
      const canBeAutomaticallyOpened = (): boolean =>
        Boolean(
          props.category.isActive || hasActiveSubcategories(props.category),
        )
      const isOpened = ref<boolean>(canBeAutomaticallyOpened())

      const toggle = () => {
        isOpened.value = !isOpened.value
      }

      // toggle the dropdown if this category becomes active
      watch(props, () => {
        isOpened.value = canBeAutomaticallyOpened()
      })

      return {
        ...toRefs(props),
        isOpened,
        hasSubcategories,
        translate,
        toggle,
        onArrowClick() {
          toggle()

          // reset the subcategories by closing this via dropdown toggle ¯\_(ツ)_/¯
          if (!isOpened.value && hasActiveSubcategories(props.category)) {
            updateProducts(props.category.collapseUrl)
          }
        },
        rootClasses: computed(() => ({
          'categories-menu__item--is-opened': isOpened.value,
          'categories-menu__item--is-leaf': !hasSubcategories(),
        })),
        onLinkClick(event: MouseEvent) {
          ;(event.target as HTMLElement).blur()
          event.preventDefault()

          updateProducts((event.target as HTMLLinkElement).href)
        },
      }
    },
  })
