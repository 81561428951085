
  import { defineComponent, watch } from 'vue'
  import useCart from '../../../hooks/use-cart/use-cart'
  import useSnackbar, {
    SnackbarType,
  } from '../../../hooks/use-snackbar/use-snackbar'

  const CART_MODIFICATIONS_WARNING_ID = 'cart-modifications-warning'

  export default defineComponent({
    setup() {
      const [cart] = useCart()
      const [, { show }] = useSnackbar()

      watch(cart, () => {
        if (cart.value?.modifications?.length) {
          show({
            type: SnackbarType.WARNING,
            text: cart.value?.modifications
              .map(({ label }) => label)
              .join(', '),
            id: CART_MODIFICATIONS_WARNING_ID,
          })
        }
      })
    },
    render: () => null,
  })
