
  import { defineComponent, PropType, ref } from 'vue'
  import StandardOverlay from '../standard-overlay/standard-overlay.vue'
  import translate from '../../../../util/misc/translate'
  import { OverlayIds } from '../../../../constants/overlay-ids'
  import InputField from '../../input-fields/input-field.vue'
  import SimpleButton from '../../buttons/simple-button/simple-button.vue'
  import useOverlay from '../../../../hooks/use-overlay/use-overlay'
  import useWatchlist from '../../../../hooks/use-watchlist/use-watchlist'
  import useSnackbar, {
    SnackbarType,
  } from '../../../../hooks/use-snackbar/use-snackbar'
  import { Watchlist } from '../../../../types/paloma-api-customers'

  export default defineComponent({
    components: { SimpleButton, InputField, StandardOverlay },
    props: {
      watchlist: {
        type: Object as PropType<Watchlist>,
        required: true,
      },
    },
    setup(props) {
      const [, overlayMethods] = useOverlay()
      const isLoading = ref<boolean>(false)
      const form = ref<HTMLFormElement | null>(null)
      const [, { renameWatchlist }] = useWatchlist()
      const close = () => overlayMethods.close(OverlayIds.RENAME_WATCHLIST)
      const [, { show }] = useSnackbar()
      const onError = (error: Error) => {
        close()

        show({
          id: OverlayIds.RENAME_WATCHLIST,
          text: error.message,
          type: SnackbarType.ERROR,
        })
      }

      return {
        OverlayIds,
        translate,
        close,
        form,
        isLoading,
        renameWatchlist() {
          const formData = new FormData(form.value as HTMLFormElement)
          const name = formData.get('name') as string

          if (!name) return

          isLoading.value = true
          renameWatchlist(props.watchlist.watchlistId, { name })
            .then(close)
            .catch(onError)
        },
      }
    },
  })
