
  import { defineComponent } from 'vue'
  import Snackbar from '../snackbar/snackbar.vue'
  import useSnackbar, {
    SnackbarType,
  } from '../../../hooks/use-snackbar/use-snackbar'

  export default defineComponent({
    components: { Snackbar },
    setup() {
      const [snackbars, { show }] = useSnackbar()

      return {
        snackbars,
        showRandomSnackbar() {
          show({
            type: SnackbarType.WARNING,
            id: `${Math.random()}`,
            text: 'Lorem ipsum',
          })
        },
      }
    },
  })
