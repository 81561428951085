import { computed, readonly, ref } from 'vue'
import { once } from 'lodash/fp'
import { add as addEvent } from 'bianco.events'
import globals from '../../globals'

import {
  EnhancedCategory,
  EnhancedProductPage,
  ProductOverviewAPIPayload,
  UseProductListAPI,
} from './types'
import { ProductListRenderingTypes } from '../../constants/product-list-rendering-types'
import {
  createLoadMoreCallback,
  createUpdateProductsCallback,
  PRODUCT_LIST_RENDERING_PARAM,
} from './utils'

const getProductListAPI = once((): UseProductListAPI => {
  const productPage = ref<EnhancedProductPage>(
    globals.initialShopData.productPage,
  )
  const categories = ref<EnhancedCategory[]>(globals.initialShopData.categories)
  const apiError = ref<Error | null>(null)
  const currentPage = ref(0)
  const abortController = ref<AbortController | null>(null)
  const isLoading = ref<boolean>(false)
  const isLoadingMore = ref<boolean>(false)
  const canLoadMore = computed(
    () => productPage.value?.content.length < productPage.value?.totalElements,
  )
  const rendering = computed(() => {
    const search = new URLSearchParams(window.location.search)
    const renderingType = search.get(PRODUCT_LIST_RENDERING_PARAM)

    return productPage.value && renderingType === ProductListRenderingTypes.GRID
      ? ProductListRenderingTypes.GRID
      : ProductListRenderingTypes.LIST
  })
  const resetCurrentPage = () => {
    currentPage.value = 0
  }
  const onSuccess = (data: ProductOverviewAPIPayload) => {
    productPage.value = data.productPage
    categories.value = data.categories
  }
  const onError = (error: Error) => {
    apiError.value = error
  }
  const onDone = () => {
    resetCurrentPage()
    isLoading.value = false
  }
  const onBeforeFetch = () => {
    const oldAbortController = abortController.value

    if (oldAbortController) {
      oldAbortController.abort()
    }

    isLoading.value = true
    apiError.value = null
  }
  const updateProducts = createUpdateProductsCallback({
    onBeforeFetch,
    onSuccess,
    onError,
    onDone,
    abortController,
  })
  const loadMore = createLoadMoreCallback({
    currentPage,
    productPage,
    isLoadingMore,
  })

  // update the product state anytime the page history changes
  addEvent(window, 'popstate', () => updateProducts())

  return [
    {
      isLoadingMore,
      loadMore,
      canLoadMore,
      productPage,
      categories,
      currentPage,
      isLoading,
      apiError,
      rendering,
    },
    {
      updateProducts,
      resetCurrentPage,
    },
  ]
})

export default function useProductList() {
  return readonly(getProductListAPI())
}
