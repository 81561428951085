import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Transition as _Transition, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["action"]
const _hoisted_2 = {
  hidden: "",
  for: "search"
}
const _hoisted_3 = ["name", "placeholder", "value"]
const _hoisted_4 = {
  key: 0,
  class: "products-search__loader"
}
const _hoisted_5 = {
  key: 0,
  class: "products-search__results-panel u-reset"
}
const _hoisted_6 = { class: "products-search__results u-reset" }
const _hoisted_7 = ["href"]
const _hoisted_8 = { class: "products-search__result-name" }
const _hoisted_9 = { class: "products-search__result-number" }
const _hoisted_10 = { class: "products-search__result products-search__result--centered" }
const _hoisted_11 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!
  const _component_simple_button = _resolveComponent("simple-button")!
  const _component_spinner = _resolveComponent("spinner")!
  const _component_product_watchlist_button = _resolveComponent("product-watchlist-button")!
  const _component_add_to_cart_button = _resolveComponent("add-to-cart-button")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["products-search", _ctx.rootClasses]),
    id: "products-search",
    ref: "root"
  }, [
    _createElementVNode("form", {
      class: "products-search__form",
      action: _ctx.action,
      autocomplete: "off",
      onSubmit: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)))
    }, [
      _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.translate('shop__search__label')), 1),
      _createVNode(_component_simple_button, {
        facets: ['bubble'],
        type: "submit",
        class: "products-search__icon",
        "aria-label": _ctx.translate('shop__search__action')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_icon, {
            name: "search",
            size: "medium"
          })
        ]),
        _: 1
      }, 8, ["aria-label"]),
      _createElementVNode("input", {
        class: "products-search__input",
        name: _ctx.name,
        type: "search",
        placeholder: _ctx.placeholder,
        value: _ctx.inputValue,
        id: "search",
        onFocus: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onFocus && _ctx.onFocus(...args))),
        onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args))),
        onKeyup: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onKeyUp && _ctx.onKeyUp(...args)))
      }, null, 40, _hoisted_3),
      (_ctx.inputValue)
        ? (_openBlock(), _createBlock(_component_simple_button, {
            key: 0,
            facets: ['bubble'],
            type: "button",
            class: "products-search__icon products-search__icon--clear",
            "aria-label": _ctx.translate('shop__search__action'),
            onClick: _ctx.clearSearch
          }, {
            default: _withCtx(() => [
              _createVNode(_component_icon, {
                name: "close",
                size: "medium"
              })
            ]),
            _: 1
          }, 8, ["aria-label", "onClick"]))
        : _createCommentVNode("", true),
      _createVNode(_Transition, { name: "trs-simple-fade" }, {
        default: _withCtx(() => [
          (_ctx.isLoading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_spinner)
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ], 40, _hoisted_1),
    (_ctx.areSuggestionsVisible)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("ul", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.results.products, (product, index) => {
              return (_openBlock(), _createElementBlock("li", {
                class: "products-search__result",
                key: index
              }, [
                _createElementVNode("a", {
                  href: product.url,
                  class: "products-search__result-product-info u-reset"
                }, [
                  _createElementVNode("span", _hoisted_8, _toDisplayString(product.name), 1),
                  _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.translate('shop__product__artnr')) + " " + _toDisplayString(product.itemNumber), 1)
                ], 8, _hoisted_7),
                (_ctx.isAuthenticated)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: _normalizeClass(["button-group button-group--flex-end", _ctx.buttonGroupClasses])
                    }, [
                      _createVNode(_component_product_watchlist_button, {
                        "product-id": product.itemNumber,
                        unit: product.baseOrderingUnit || 'trade_unit',
                        facets: ['bubble', 'inline', 'small']
                      }, null, 8, ["product-id", "unit"]),
                      _createVNode(_component_add_to_cart_button, {
                        "product-id": product.itemNumber,
                        facets: ['bubble', 'inline', 'small']
                      }, null, 8, ["product-id"])
                    ], 2))
                  : _createCommentVNode("", true)
              ]))
            }), 128)),
            _createElementVNode("li", _hoisted_10, [
              _createElementVNode("a", {
                class: "products-search__result-product-info u-reset",
                href: _ctx.results.allProducts,
                onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.showAllProducts && _ctx.showAllProducts(...args)))
              }, _toDisplayString(_ctx.translate('shop__show_all_products')), 9, _hoisted_11)
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ], 2))
}