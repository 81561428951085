
  import { computed, defineComponent, onBeforeMount, ref } from 'vue'

  import StandardOverlay from '../standard-overlay/standard-overlay.vue'
  import translate from '../../../../util/misc/translate'
  import { OverlayIds } from '../../../../constants/overlay-ids'
  import useWatchlist from '../../../../hooks/use-watchlist/use-watchlist'
  import Spinner from '../../spinner/spinner.vue'
  import InputField from '../../input-fields/input-field.vue'
  import { Watchlist } from '../../../../types/paloma-api-customers'
  import SimpleButton from '../../buttons/simple-button/simple-button.vue'
  import Checkbox from '../../checkbox/checkbox.vue'
  import useSnackbar, {
    SnackbarType,
  } from '../../../../hooks/use-snackbar/use-snackbar'
  import useOverlay from '../../../../hooks/use-overlay/use-overlay'
  import isWatchlistEditable from '../../../../util/misc/is-watchlist-editable'
  import { EnhancedWatchlist } from '../../../../hooks/use-watchlist/types'

  export default defineComponent({
    components: {
      Checkbox,
      SimpleButton,
      InputField,
      Spinner,
      StandardOverlay,
    },
    props: {
      productId: {
        type: String,
        required: true,
      },
      unit: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      const [watchlist, { getWatchlist, createWatchlist, addProduct }] =
        useWatchlist()
      const [, overlayMethods] = useOverlay()
      const [, snackbarMethods] = useSnackbar()
      const form = ref<HTMLFormElement | null>(null)
      const isLoading = ref<boolean>(true)
      const isSubmitting = ref<boolean>(false)
      const close = () =>
        overlayMethods.close(OverlayIds.ADD_PRODUCT_TO_WATCHLIST)
      const onLoadingDone = () => {
        isLoading.value = false
      }
      const onError = (error: Error) => {
        close()

        snackbarMethods.show({
          id: OverlayIds.ADD_PRODUCT_TO_WATCHLIST,
          text: error.message,
          type: SnackbarType.ERROR,
        })
      }
      const hasWatchLists = computed(() => watchlist.value.length)
      const addProductIntoWatchLists = (watchlistIds: string[]) => {
        const promises = watchlistIds
          .filter(Boolean)
          .map((watchlistId) =>
            addProduct(watchlistId, props.productId, 1, props.unit),
          )

        isSubmitting.value = true

        Promise.all(promises).then(close).catch(onError)
      }
      const createANewWatchlistAndAddProductToIt = (name: string) => {
        if (!name) return

        isSubmitting.value = true

        createWatchlist({ name })
          .then((data: Watchlist[]) =>
            addProductIntoWatchLists([data?.[0]?.watchlistId]),
          )
          .catch(onError)
      }

      onBeforeMount(() => {
        getWatchlist().catch(onError).finally(onLoadingDone)
      })

      return {
        close,
        isSubmitting,
        isLoading,
        OverlayIds,
        translate,
        form,
        hasWatchLists,
        enabledWatchlist: computed(() =>
          watchlist.value.filter((item) =>
            isWatchlistEditable(item as EnhancedWatchlist),
          ),
        ),
        isProductInWatchlist() {
          return false
        },
        addProduct() {
          const formData = new FormData(form.value as HTMLFormElement)

          if (hasWatchLists.value) {
            addProductIntoWatchLists(
              formData.getAll('watchlist-id') as string[],
            )
          } else {
            createANewWatchlistAndAddProductToIt(formData.get('name') as string)
          }
        },
      }
    },
  })
