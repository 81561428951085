import { once } from 'lodash/fp'
import { readonly, ref } from 'vue'

import parseResponse from '../../util/misc/parse-response'
import {
  CartItemLite,
  CartItemsDeletePayload,
  EnhancedOrder,
  UseCartAPI,
} from './types'
import { ShippingOptions } from '../../types/paloma-api-checkout'
import {
  getAddCustomerOrderNumberEndpoint,
  getAddMultipleProductsToCartEndpoint,
  getAddProductToCartEndpoint,
  getApplyDeliverySchemeEndpoint,
  getCartDeleteItemsEndpoint,
  getCartEndpoint,
  getClearCartEndpoint,
  getDeleteProductInCartEndpoint,
  getPurchaseCartEndpoint,
  getShippingOptionsEndpoint,
  getUpdateProductInCartEndpoint,
} from '../../constants/api-endpoints'
import createRequestWithJsonPayload from '../../util/misc/create-request-with-json-payload'

const getCartAPI = once((): UseCartAPI => {
  const cart = ref<EnhancedOrder | null>(null)
  const refreshCart = (data: EnhancedOrder) => {
    cart.value = data

    return data
  }
  // each update to the watchlist should return us the complete watchlist with the updates applied to it
  const onSuccess = (res: Response) =>
    parseResponse<EnhancedOrder>(res).then(refreshCart)
  const getCart = () => fetch(getCartEndpoint()).then(onSuccess)
  const clearCart = () =>
    fetch(getClearCartEndpoint(), {
      method: 'delete',
    }).then(onSuccess)
  const addProduct = (productId: string, quantity: number, unit: string) =>
    createRequestWithJsonPayload(getAddProductToCartEndpoint(productId), {
      quantity,
      unit,
    }).then(onSuccess)
  const addMultipleProducts = (items: CartItemLite[]) =>
    createRequestWithJsonPayload(getAddMultipleProductsToCartEndpoint(), {
      orderItems: items,
    }).then(onSuccess)
  const deleteProduct = (productId: string) =>
    fetch(getDeleteProductInCartEndpoint(productId), {
      method: 'delete',
    }).then(onSuccess)
  const deleteMultipleProducts = (payload: CartItemsDeletePayload) =>
    createRequestWithJsonPayload(getCartDeleteItemsEndpoint(), payload).then(
      onSuccess,
    )
  const updateProductQuantity = (
    productId: string,
    quantity: number,
    unit: string,
  ) =>
    createRequestWithJsonPayload(getUpdateProductInCartEndpoint(productId), {
      quantity,
      unit,
    }).then(onSuccess)

  const purchase = () => fetch(getPurchaseCartEndpoint()).then(onSuccess)

  const getShippingOptions = () =>
    fetch(getShippingOptionsEndpoint()).then((res: Response) =>
      parseResponse<ShippingOptions>(res),
    )

  const applyDeliverySchemes = (name: string) =>
    createRequestWithJsonPayload(getApplyDeliverySchemeEndpoint(), {
      name,
    }).then(onSuccess)

  const updateCustomerOrderNumber = (customerOrderNumber: string | null) =>
    createRequestWithJsonPayload(getAddCustomerOrderNumberEndpoint(), {
      customerOrderNumber,
    }).then(onSuccess)

  return [
    cart,
    {
      getCart,
      clearCart,
      purchase,
      updateProductQuantity,
      deleteProduct,
      deleteMultipleProducts,
      getShippingOptions,
      applyDeliverySchemes,
      addProduct,
      addMultipleProducts,
      updateCustomerOrderNumber,
    },
  ]
})

export default function useCart() {
  return readonly(getCartAPI())
}
