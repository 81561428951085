
  import { computed, defineComponent, onBeforeMount } from 'vue'
  import Icon from '../icon/icon.vue'
  import SimpleButton from '../buttons/simple-button/simple-button.vue'
  import CartPanel from '../../scaffolding/cart-panel/cart-panel.vue'
  import useSidePanel, {
    SidePanelPosition,
  } from '../../../hooks/use-side-panel/use-side-panel'
  import useCart from '../../../hooks/use-cart/use-cart'

  export default defineComponent({
    components: { SimpleButton, Icon },
    setup() {
      const [cart, { getCart }] = useCart()
      const [sidePanelState, { close, open }] = useSidePanel()

      onBeforeMount(() => {
        // fetch the cart to show its amount
        getCart()
      })

      return {
        itemsAmount: computed(() => cart.value?.items?.length ?? 0),
        toggle() {
          if (
            sidePanelState.panel &&
            sidePanelState.panel.component === CartPanel
          ) {
            close()
          } else {
            open(
              {
                component: CartPanel,
                props: {
                  facets: ['overlay'],
                },
              },
              SidePanelPosition.RIGHT,
            )
          }
        },
      }
    },
  })
