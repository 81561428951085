
  import {
    computed,
    PropType,
    defineComponent,
    h,
    Text,
    Slots,
    Comment,
    VNode,
  } from 'vue'

  /**
   * Wrap a vnode in a div with class `btn--text` or `btn--icon`
   * @param {function} createElement - The creation method
   * @param {object} child - A vnode
   * @return {object} A new dom element
   */

  const createButtonChild = (child: VNode) => {
    if (child.type === Comment) {
      return child
    }

    const props = {
      class: `button__${child.type === Text ? 'text' : 'graphic'}`,
    }

    return h('div', props, child)
  }
  /**
   * Wrap slot children into a enclosing div
   * @example `<div class='btn--text'>{child}</div>`
   * @return {object} A new dom element
   */
  const createButtonContent = (slots: Slots) => {
    const children = slots.default ? slots.default().map(createButtonChild) : []
    const props = {
      class: `button__wrap`,
    }

    return h('div', props, children)
  }

  export default defineComponent({
    props: {
      tag: {
        type: String,
        default: 'button',
      },
      facets: {
        type: Array as PropType<string[]>,
        default: () => [],
      },
    },
    setup(props, context) {
      const rootClasses = computed(() => [
        ...props.facets.map((facet) => `button--${facet}`),
        'button',
      ])
      return () => {
        const children = [createButtonContent(context.slots)]

        return h(
          props.tag,
          {
            ...context.attrs,
            class: rootClasses.value,
          },
          children,
        )
      }
    },
  })
