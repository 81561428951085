import globals from './globals'

/**
 * Set the webpack public path (for bundles) dynamically
 * The static path comes from the server
 * @type {string}
 * @private
 * @link https://github.com/webpack/docs/wiki/configuration#outputpublicpath
 */
__webpack_public_path__ = `${globals.static}js/` // eslint-disable-line camelcase, no-undef
