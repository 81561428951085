import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "product-detail-main__actions" }
const _hoisted_2 = { class: "product-detail-main__like-button" }
const _hoisted_3 = { class: "product-detail-main__cart-actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_product_watchlist_button = _resolveComponent("product-watchlist-button")!
  const _component_custom_select = _resolveComponent("custom-select")!
  const _component_product_detail_cart_button = _resolveComponent("product-detail-cart-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_product_watchlist_button, {
        "product-id": _ctx.productId,
        unit: _ctx.unit
      }, null, 8, ["product-id", "unit"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      (Number(_ctx.numberOfUnits) > 1)
        ? (_openBlock(), _createBlock(_component_custom_select, {
            key: 0,
            facets: ['horizontal'],
            label: ``,
            placeholder: ``,
            onChange: _ctx.onUnitChange
          }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "default")
            ]),
            _: 3
          }, 8, ["onChange"]))
        : _createCommentVNode("", true),
      _createVNode(_component_product_detail_cart_button, {
        "product-id": _ctx.productId,
        unit: _ctx.unit
      }, null, 8, ["product-id", "unit"])
    ])
  ]))
}