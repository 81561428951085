import globals from '../../globals'

export default function formatDateToLocaleDateString(
  date?: string | Date,
  options?: Intl.DateTimeFormatOptions,
) {
  const defaultOptions: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    ...options,
  }

  return date
    ? new Date(date).toLocaleDateString(globals.currentLanguage, defaultOptions)
    : ''
}
