
  import { uniqueId } from 'lodash/fp'
  import { computed, defineComponent, PropType } from 'vue'

  export default defineComponent({
    props: {
      text: {
        type: String,
        required: true,
      },
      facets: {
        type: Array as PropType<string[]>,
        default: () => ['top', 'left'],
      },
    },
    setup(props) {
      return {
        uuid: uniqueId('uuid-'),
        rootClasses: computed(() =>
          props.facets.map((facet) => `tooltip--${facet}`),
        ),
      }
    },
  })
