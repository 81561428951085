
  import { computed, defineComponent, PropType, ref } from 'vue'
  import { orderBy } from 'lodash/fp'
  import { getUnitCodeFromItem } from '../../../util/misc/get-unit-code-from-item'
  import { WatchlistItem } from '../../../types/paloma-api-customers'
  import SimpleButton from '../../common/buttons/simple-button/simple-button.vue'
  import Icon from '../../common/icon/icon.vue'
  import translate from '../../../util/misc/translate'
  import CustomSelect from '../../common/custom-select/custom-select.vue'
  import EmptyState from '../../common/empty-state/empty-state.vue'
  import ProductListItemLite from '../product-list/product-list-item/product-list-item-lite.vue'
  import useWatchlist from '../../../hooks/use-watchlist/use-watchlist'
  import useSnackbar, {
    SnackbarType,
  } from '../../../hooks/use-snackbar/use-snackbar'
  import Spinner from '../../common/spinner/spinner.vue'
  import useCart from '../../../hooks/use-cart/use-cart'
  import { EnhancedWatchlist } from '../../../hooks/use-watchlist/types'
  import isWatchlistEditable from '../../../util/misc/is-watchlist-editable'

  enum SortingTypes {
    ALPHA_ASC = 'alpha-asc',
    ALPHA_DESC = 'alpha-desc',
  }

  const sortByName = orderBy(['title'])

  export default defineComponent({
    components: {
      Spinner,
      ProductListItemLite,
      EmptyState,
      CustomSelect,
      Icon,
      SimpleButton,
    },
    props: {
      watchlist: {
        type: Object as PropType<EnhancedWatchlist>,
        required: true,
      },
      sortingItems: {
        type: Array as PropType<
          { label: string; value: keyof typeof SortingTypes }[]
        >,
        default: () => [
          {
            label: translate('shop__watchlist__sorting_select__alpha_asc'),
            value: SortingTypes.ALPHA_ASC,
          },
          {
            label: translate('shop__watchlist__sorting_select__alpha_desc'),
            value: SortingTypes.ALPHA_DESC,
          },
        ],
      },
    },
    setup(props) {
      const activeSorting = ref<unknown>(null)
      const [, { addMultipleProducts }] = useCart()
      const [, { removeProduct, updateProduct, resetProductsQuantity }] =
        useWatchlist()
      const [, { show }] = useSnackbar()
      const isResettingWatchlistQuantities = ref<boolean>(false)
      const isAddingItemsToCart = ref<boolean>(false)
      const deletingItemId = ref<string | null>(null)
      const updatingItemId = ref<string | null>(null)
      const watchlistItems = ref<WatchlistItem[]>(props.watchlist.items)

      const updateWatchlistItems = (data: EnhancedWatchlist) => {
        watchlistItems.value = data.items
      }

      const onError = (error: Error) => {
        show({
          id: `watchlist-detail-error-${new Date()}`,
          text: error.message,
          type: SnackbarType.ERROR,
        })
      }

      const items = computed(() => {
        const { value } = watchlistItems

        switch (activeSorting.value) {
          case SortingTypes.ALPHA_ASC:
            return sortByName(['asc'], value)
          case SortingTypes.ALPHA_DESC:
            return sortByName(['desc'], value)
          default:
            return value
        }
      })

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const getQuantityDescription = (item: any) => {
        return item.unit === 'CU'
          ? item?.variant?.attributes?.cu_quantity_description?.value
          : item?.variant?.attributes?.tu_quantity_description?.value
      }

      return {
        translate,
        isWatchlistEditable,
        getUnitCodeFromItem,
        getQuantityDescription,
        items,
        deletingItemId,
        updatingItemId,
        isResettingWatchlistQuantities,
        isAddingItemsToCart,
        productsAmount: computed(() =>
          watchlistItems.value.reduce<number>(
            (acc, product) => acc + product.quantity,
            0,
          ),
        ),
        onAddItemsToCart() {
          const onDone = () => {
            isAddingItemsToCart.value = false
          }

          isAddingItemsToCart.value = true

          addMultipleProducts(
            watchlistItems.value.map((item) => {
              return {
                id: item.sku,
                quantity: item.quantity,
                unit: getUnitCodeFromItem(item),
              }
            }),
          )
            .catch(onError)
            .finally(onDone)
        },
        onResetItemsQuantity() {
          const onDone = () => {
            isResettingWatchlistQuantities.value = false
          }

          isResettingWatchlistQuantities.value = true

          resetProductsQuantity(props.watchlist.watchlistId)
            .then(updateWatchlistItems)
            .catch(onError)
            .finally(onDone)
        },
        onQuantityUpdated(quantity: number, productId: string, unit: string) {
          const onDone = () => {
            updatingItemId.value = null
          }

          updatingItemId.value = productId

          updateProduct(props.watchlist.watchlistId, productId, quantity, unit)
            .then(updateWatchlistItems)
            .catch(onError)
            .finally(onDone)
        },
        onDeleteItem(productId: string) {
          const onDone = () => {
            deletingItemId.value = null
          }

          deletingItemId.value = productId

          removeProduct(props.watchlist.watchlistId, productId)
            .then(updateWatchlistItems)
            .catch(onError)
            .finally(onDone)
        },
        onSortingChange(event: Event) {
          activeSorting.value = (event.target as HTMLSelectElement).value
        },
        hasProducts: computed(() => watchlistItems.value.length),
      }
    },
  })
