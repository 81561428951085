import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "product-list__list u-reset"
}
const _hoisted_2 = {
  key: 0,
  class: "product-list__loader"
}
const _hoisted_3 = {
  key: 2,
  class: "product-list__load-more"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_product_list_item = _resolveComponent("product-list-item")!
  const _component_empty_state = _resolveComponent("empty-state")!
  const _component_spinner = _resolveComponent("spinner")!
  const _component_icon = _resolveComponent("icon")!
  const _component_simple_button = _resolveComponent("simple-button")!
  const _component_generic_info_message = _resolveComponent("generic-info-message")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["product-list", _ctx.rootClasses]),
    id: "product-list"
  }, [
    (_ctx.productPage.content.length)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.productPage.content, (product) => {
            return (_openBlock(), _createElementBlock("li", {
              class: "product-list__item",
              key: product.itemNumber
            }, [
              _createVNode(_component_product_list_item, { product: product }, null, 8, ["product"])
            ]))
          }), 128))
        ]))
      : (_openBlock(), _createBlock(_component_empty_state, {
          key: 1,
          text: _ctx.translate('shop__no_products_found')
        }, null, 8, ["text"])),
    _createVNode(_Transition, { name: "trs-simple-fade" }, {
      default: _withCtx(() => [
        (_ctx.isLoading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_spinner)
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    (_ctx.canLoadMore)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_simple_button, {
            disabled: _ctx.isLoadingMore,
            facets: ['medium', 'inline', 'small-text'],
            onClick: _ctx.loadMore
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.translate('shop__load_more')) + " ", 1),
              (_ctx.isLoadingMore)
                ? (_openBlock(), _createBlock(_component_spinner, { key: 0 }))
                : (_openBlock(), _createBlock(_component_icon, {
                    key: 1,
                    name: "chevron-down",
                    size: "small"
                  }))
            ]),
            _: 1
          }, 8, ["disabled", "onClick"])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_generic_info_message, { class: "product-list__tax-info" }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.taxInfo), 1)
      ]),
      _: 1
    })
  ], 2))
}