
  import { defineComponent, PropType } from 'vue'
  import StandardOverlay from '../standard-overlay/standard-overlay.vue'
  import { OverlayIds } from '../../../../constants/overlay-ids'
  import translate from '../../../../util/misc/translate'
  import SimpleButton from '../../buttons/simple-button/simple-button.vue'

  export default defineComponent({
    components: { SimpleButton, StandardOverlay },
    props: {
      title: {
        type: String,
        required: true,
      },
      description: {
        type: String,
        required: true,
      },
      image: {
        type: Object as PropType<
          | {
              urls: { default: string[] }
              alt: string
            }
          | undefined
        >,
        default: () => undefined,
      },
      link: {
        type: String,
        default: '',
      },
    },
    setup() {
      return {
        OverlayIds,
        translate,
      }
    },
  })
