/* eslint-disable no-underscore-dangle, @typescript-eslint/no-explicit-any */
import { ProductOverviewAPIPayload } from './hooks/use-product-list/types'
import { UserData } from './hooks/use-user/types'

export type MarketingOverlay = {
  title: string
  description: string
  uuid: string
  link: string
  image:
    | {
        urls: {
          default: string[]
        }
        alt: string
      }
    | undefined
  onClose?: () => void
}

export type WindowGlobalsBridge = {
  static: string
  csrf: string
  debug: boolean
  gitCommitHash: string
  sentryDSN: string
  cmsEditMode: boolean
  apiBase: string
  currentLanguage: string
  routes: Record<string, string>
  translations: Record<string, unknown>
  initialShopData: ProductOverviewAPIPayload
  userData: UserData
  standardOverlay?: MarketingOverlay
  authenticatedOverlay?: MarketingOverlay
}

const fallback: WindowGlobalsBridge = {
  // eslint-disable-next-line
  static: __webpack_public_path__,
  csrf: '',
  debug: false,
  gitCommitHash: '',
  sentryDSN: '',
  apiBase: '',
  currentLanguage: '',
  cmsEditMode: false,
  routes: {},
  userData: {
    username: '',
    isAuthenticated: false,
  },
  translations: {},
  initialShopData: {
    productPage: {
      aggregates: [],
      filters: {
        buttonFilters: [],
        iconFilters: [],
        ecoCheckboxes: [],
        labels: [],
        brands: [],
        preparation: [],
        fermentation: [],
        storageConditions: [],
        allergens: [],
      },
      content: [],
      hasActiveFilters: false,
      categoryResetUrl: '',
      filterResetUrl: '',
      size: 0,
      number: 0,
      totalElements: 0,
      totalPages: 0,
      last: false,
      first: false,
    },
    categories: [],
  },
}

export default (window as any)?.__js_bridge ?? (fallback as WindowGlobalsBridge)
