
  import { defineComponent, ref, watch } from 'vue'
  import Dropdown from '../../common/dropdown/dropdown.vue'

  const apiEndpoint = '/__story_test_cases/get/'

  export type Story = {
    components: string[]
    description: string
  }

  export default defineComponent({
    components: { Dropdown },
    setup() {
      const { body } = document
      const [, hash] = window.location.hash.split('#')
      const story = ref<Story | undefined>()
      const [, uuid] = hash ? hash.split('uuid-') : [null, null]
      const toggleBlur = (mustBlur: boolean) => {
        story.value?.components.forEach((id) => {
          const el = document.getElementById(id)

          if (el) {
            el.classList.toggle('u-blur', mustBlur)
          }
        })
      }

      if (uuid) {
        fetch(`${apiEndpoint}?uuid=${uuid}`)
          .then<Story>((response) => response.json())
          .then((data) => {
            story.value = data
          })
      }

      watch(story, () => toggleBlur(true))

      return {
        story,
        showIDS(event: InputEvent) {
          const { checked } = event.target as HTMLInputElement

          body.classList.toggle('show-ids', checked)
          toggleBlur(!checked)
        },
      }
    },
  })
