
  import { computed, defineComponent, PropType, ref, toRefs, watch } from 'vue'
  import { loadImage } from 'bianco.images-loader'
  import { noop } from 'lodash/fp'
  import { EnhancedProduct } from '../../../../hooks/use-product-list/types'
  import useIsVisible from '../../../../hooks/use-is-visible/use-is-visible'
  import staticPath from '../../../../util/misc/static-path'
  import Icon from '../../../common/icon/icon.vue'
  import useProductList from '../../../../hooks/use-product-list/use-product-list'
  import ProductCounter from '../../../common/product-counter/product-counter.vue'
  import { ProductListRenderingTypes } from '../../../../constants/product-list-rendering-types'
  import breakpoints from '../../../../../design-tokens/breakpoints.json'
  import useViewportSize from '../../../../hooks/use-viewport-size/use-viewport-size'
  import translate from '../../../../util/misc/translate'
  import { ProductImageIds } from '../../../../constants/product-image-ids'
  import getDevicePixelRatio from '../../../../util/misc/get-device-pixel-ratio'
  import useUser from '../../../../hooks/use-user/use-user'
  import ProductWatchlistButton from '../../../common/product-watchlist-button/product-watchlist-button.vue'
  import AddToCartButton from '../../../common/add-to-cart-button/add-to-cart-button.vue'
  import getProductImageById from '../../../../util/misc/get-product-image-by-id'

  export default defineComponent({
    components: {
      AddToCartButton,
      ProductWatchlistButton,
      ProductCounter,
      Icon,
    },
    props: {
      product: {
        type: Object as PropType<EnhancedProduct>,
        required: true,
      },
      showCounter: {
        type: Boolean,
        default: false,
      },
      isCounterEnabled: {
        type: Boolean,
        default: false,
      },
      onQuantityUpdated: {
        type: Function as PropType<(quantity: number) => void>,
        default: noop,
      },
      totalPrice: {
        type: Number,
        default: null,
      },
    },
    setup(props) {
      const root = ref(null)
      const isImageLoaded = ref(false)
      const { width } = toRefs(useViewportSize())
      const [productListState] = useProductList()
      const { rendering } = toRefs(productListState)
      const { isVisible } = toRefs(useIsVisible(root))
      const userState = useUser()
      const getListingImages = (): [string, string] => {
        const imageSources = props?.product?.master?.images?.[0]?.sources ?? []

        return [
          getProductImageById(ProductImageIds.LISTING, imageSources).url,
          getProductImageById(ProductImageIds.LISTING_X2, imageSources).url,
        ]
      }
      const imageUrl = computed<string>(() => {
        const [standard, retina] = getListingImages()

        return getDevicePixelRatio() > 1 ? retina : standard
      })
      const loadProductImage = () => {
        loadImage(imageUrl.value).then(() => {
          isImageLoaded.value = true
        })
      }

      watch(isVisible, loadProductImage)

      return {
        root,
        translate,
        staticPath,
        user: userState,
        features: computed(
          () =>
            [
              {
                url: props?.product?.master?.dreipolAttributes?.brand?.url,
                alt: props?.product?.master?.dreipolAttributes?.brand?.value,
              },
              {
                icon: {
                  name: props?.product?.master?.dreipolAttributes.storage_advice
                    ?.code,
                  size: 'medium',
                },
              },
              {
                text: props?.product?.master?.dreipolAttributes.storage_advice
                  ?.value,
              },
            ].filter(
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              ({ text, icon, url }) => !text || !(icon as any)?.name || !url,
            ), // remove the falsy values
        ),
        canBeAddedToTheCart: computed(() => props.product.master.price),
        hasCounter: computed(
          () =>
            props.showCounter &&
            (rendering.value === ProductListRenderingTypes.LIST ||
              width.value < breakpoints.lg) &&
            Math.random() > 0.5,
        ),
        isReady: computed(() => {
          return isVisible.value && isImageLoaded.value
        }),
        rootClasses: computed(() => [`product-list-item--${rendering.value}`]),
        imageUrl,
      }
    },
  })
