
  import { defineComponent, ref } from 'vue'
  import Icon from '../icon/icon.vue'
  import ProductCounter from '../product-counter/product-counter.vue'
  import SimpleButton from '../buttons/simple-button/simple-button.vue'
  import translate from '../../../util/misc/translate'
  import useCart from '../../../hooks/use-cart/use-cart'
  import useSnackbar, {
    SnackbarType,
  } from '../../../hooks/use-snackbar/use-snackbar'
  import Spinner from '../spinner/spinner.vue'

  export default defineComponent({
    components: {
      Spinner,
      SimpleButton,
      ProductCounter,
      Icon,
    },
    props: {
      productId: {
        type: String,
        required: true,
      },
      unit: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      const [, { addProduct }] = useCart()
      const [, { show }] = useSnackbar()
      const quantity = ref<number>(1)
      const isLoading = ref<boolean>(false)
      const onDone = () => {
        isLoading.value = false
      }
      const onError = (error: Error) => {
        show({
          type: SnackbarType.ERROR,
          text: error.message,
          id: `product-button-error-${new Date()}`,
        })
      }

      return {
        translate,
        quantity,
        isLoading,
        addToCart() {
          isLoading.value = true
          addProduct(props.productId, quantity.value, props.unit)
            .catch(onError)
            .finally(onDone)
        },
        onQuantityChange(newQuantity: number) {
          quantity.value = newQuantity
        },
      }
    },
  })
