import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, vShow as _vShow, normalizeStyle as _normalizeStyle, withDirectives as _withDirectives, TransitionGroup as _TransitionGroup } from "vue"

const _hoisted_1 = { class: "categories-menu__triggers-wrap" }
const _hoisted_2 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!
  const _component_simple_button = _resolveComponent("simple-button")!
  const _component_category_item = _resolveComponent("category-item", true)!

  return (_openBlock(), _createElementBlock("li", {
    class: _normalizeClass(["categories-menu__item", _ctx.rootClasses])
  }, [
    _createElementVNode("span", _hoisted_1, [
      (_ctx.hasSubcategories())
        ? (_openBlock(), _createBlock(_component_simple_button, {
            key: 0,
            class: "categories-menu__toggle",
            facets: ['lite', 'bubble'],
            "aria-label": _ctx.translate('shop__categories__toggle'),
            onClick: _ctx.onArrowClick
          }, {
            default: _withCtx(() => [
              _createVNode(_component_icon, {
                size: "small",
                name: "chevron-right"
              })
            ]),
            _: 1
          }, 8, ["aria-label", "onClick"]))
        : _createCommentVNode("", true),
      _createElementVNode("a", {
        href: _ctx.category.url,
        class: _normalizeClass(["categories-menu__link u-reset", { 'categories-menu__link--is-active': _ctx.category.isActive }]),
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onLinkClick && _ctx.onLinkClick(...args)))
      }, _toDisplayString(_ctx.category.name) + " [" + _toDisplayString(_ctx.category.filterAggregate?.count) + "] ", 11, _hoisted_2)
    ]),
    (_ctx.hasSubcategories())
      ? (_openBlock(), _createBlock(_TransitionGroup, {
          key: 0,
          tag: "ul",
          style: _normalizeStyle({ '--total': _ctx.category.subCategories.length }),
          name: "trs-category-menu-items",
          class: "categories-menu__sub-menu u-reset"
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.category.subCategories, (subCategory, index) => {
              return _withDirectives((_openBlock(), _createBlock(_component_category_item, {
                style: _normalizeStyle({ '--index': index }),
                category: subCategory,
                key: index
              }, null, 8, ["style", "category"])), [
                [_vShow, _ctx.isOpened]
              ])
            }), 128))
          ]),
          _: 1
        }, 8, ["style"]))
      : _createCommentVNode("", true)
  ], 2))
}