
  import { defineComponent, ref } from 'vue'
  import useSnackbar, {
    SnackbarType,
  } from '../../../hooks/use-snackbar/use-snackbar'
  import parseResponse from '../../../util/misc/parse-response'

  export default defineComponent({
    setup() {
      const root = ref()
      const isLoading = ref(false)
      const [, { show }] = useSnackbar()
      const error = ref<unknown>()
      const getFormElement = () =>
        (root.value as HTMLElement).querySelector('form')
      const hideErrors = () => {
        error.value = null
      }
      const hideLoader = () => {
        isLoading.value = false
      }
      const showLoader = () => {
        isLoading.value = true
      }
      const onBeforeSubmit = () => {
        hideErrors()
        showLoader()
      }
      const onError = (apiError: unknown) => {
        hideLoader()
        error.value = apiError
      }
      const onSuccess = (data: { redirectUrl?: string; message?: string }) => {
        const form = getFormElement()
        if (data.message) {
          show({
            id: 'password-reset-success',
            type: SnackbarType.SUCCESS,
            text: data.message,
          })

          if (form) {
            form.reset()
          }

          hideLoader()
        } else if (data.redirectUrl) {
          window.location.href = data.redirectUrl
        } else {
          hideLoader()
          console.warn(
            'No redirectUrl or message was provided, nothing will happen',
          )
        }
      }

      const submitForm = (
        url: string | null,
        method: string,
        formData: FormData,
      ) => {
        if (!url) {
          throw new Error('No url for the form submission was found')
        }

        onBeforeSubmit()

        return fetch(url, {
          method,
          body: formData,
        })
          .then(parseResponse)
          .then(onSuccess)
          .catch(onError)
      }

      return {
        root,
        isLoading,
        error,
        onSubmit() {
          const form = getFormElement()
          if (!form) {
            throw new Error(
              'It was not possible to find the form element to submit',
            )
          }

          const formData = new FormData(form)

          submitForm(
            form.getAttribute('action'),
            form?.getAttribute('method') ?? 'post',
            formData,
          )
        },
      }
    },
  })
