import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createSlots as _createSlots, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["alt", "src", "srcset"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "button-group button-group--horizontal button-group--flex-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_simple_button = _resolveComponent("simple-button")!
  const _component_standard_overlay = _resolveComponent("standard-overlay")!

  return (_openBlock(), _createBlock(_component_standard_overlay, {
    title: _ctx.title,
    class: "marketing-overlay",
    id: _ctx.OverlayIds.MARKETING_OVERLAY
  }, _createSlots({
    default: _withCtx(() => [
      (_ctx.image)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            class: "marketing-overlay__image",
            alt: _ctx.image.alt,
            src: _ctx.image.urls.default[0],
            srcset: `${_ctx.image.urls.default[0]} 1x, ${_ctx.image.urls.default[1]} 2x`
          }, null, 8, _hoisted_1))
        : _createCommentVNode("", true),
      _createElementVNode("p", {
        class: "marketing-overlay__description u-reset",
        innerHTML: _ctx.description
      }, null, 8, _hoisted_2)
    ]),
    _: 2
  }, [
    (_ctx.link)
      ? {
          name: "footer",
          fn: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_simple_button, {
                tag: "a",
                href: _ctx.link,
                facets: ['primary', 'round']
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.translate('read_more')), 1)
                ]),
                _: 1
              }, 8, ["href"])
            ])
          ]),
          key: "0"
        }
      : undefined
  ]), 1032, ["title", "id"]))
}