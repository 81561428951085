
  import { computed, defineComponent, PropType, ref, watch } from 'vue'
  import { noop, isNumber } from 'lodash/fp'
  import SimpleButton from '../buttons/simple-button/simple-button.vue'
  import Icon from '../icon/icon.vue'
  import translate from '../../../util/misc/translate'
  import Spinner from '../spinner/spinner.vue'

  export default defineComponent({
    components: { Spinner, Icon, SimpleButton },
    emits: ['input'],
    props: {
      isEnabled: {
        type: Boolean,
        default: false,
      },
      isLoading: {
        type: Boolean,
        default: false,
      },
      quantityIncrement: {
        type: Number,
        default: 1,
      },
      facets: {
        type: Array as PropType<string[]>,
        default: () => [],
      },
      value: {
        type: Number,
        default: 1,
      },
      onChange: {
        type: Function as PropType<(value: number) => void>,
        default: noop,
      },
    },
    setup(props, context) {
      const currentValue = ref<number>(props.value)

      watch(props, () => {
        currentValue.value = props.value
      })

      const updateCurrentValue = (newValue: number) => {
        currentValue.value = newValue

        props.onChange(currentValue.value)
        context.emit('input', currentValue.value)
      }

      return {
        currentValue,
        translate,
        onInputBlur(event: Event) {
          const target = event.target as HTMLInputElement
          const newValue = Number(target.value)

          if (!isNumber(newValue) || Number.isNaN(newValue)) return

          updateCurrentValue(newValue)
        },
        changeCurrentValue(newValue: number) {
          updateCurrentValue(newValue)
        },
        rootClasses: computed(() =>
          props.facets.map((facet) => `product-counter--${facet}`),
        ),
      }
    },
  })
