
  import { computed, defineComponent, ref } from 'vue'
  import translate from '../../../util/misc/translate'

  export default defineComponent({
    props: {
      onLabel: {
        type: String,
        default: translate('ui__toggle_on'),
      },
      offLabel: {
        type: String,
        default: translate('ui__toggle_off'),
      },
    },
    setup(props, { attrs }) {
      const isChecked = ref<boolean>(!!attrs.checked)

      return {
        toggleLabel: computed(() =>
          isChecked.value ? props.onLabel : props.offLabel,
        ),
        onChange(event: InputEvent) {
          isChecked.value = (event.target as HTMLInputElement).checked
        },
      }
    },
  })
