import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createVNode as _createVNode, createElementVNode as _createElementVNode, TransitionGroup as _TransitionGroup, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "snackbar-list grid grid--base" }
const _hoisted_2 = { class: "grid__container" }
const _hoisted_3 = { class: "snackbar-list__list-item-row grid__row" }
const _hoisted_4 = { class: "snackbar-list__list-item-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_snackbar = _resolveComponent("snackbar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_TransitionGroup, {
        class: "snackbar-list__list u-reset",
        name: "trs-snackbar-list",
        tag: "ul"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.snackbars, (snackbar) => {
            return (_openBlock(), _createElementBlock("li", {
              class: "snackbar-list__list-item",
              key: snackbar.id
            }, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_snackbar, _normalizeProps(_guardReactiveProps(snackbar)), null, 16)
                ])
              ])
            ]))
          }), 128))
        ]),
        _: 1
      })
    ])
  ]))
}