
  import { defineComponent, PropType, ref } from 'vue'
  import SimpleButton from '../buttons/simple-button/simple-button.vue'
  import Icon from '../icon/icon.vue'
  import useCart from '../../../hooks/use-cart/use-cart'
  import Spinner from '../spinner/spinner.vue'
  import useSnackbar, {
    SnackbarType,
  } from '../../../hooks/use-snackbar/use-snackbar'
  import translate from '../../../util/misc/translate'

  export default defineComponent({
    components: { Spinner, Icon, SimpleButton },
    props: {
      facets: {
        type: Array as PropType<string[]>,
        default: () => ['bubble', 'medium', 'primary'],
      },
      productId: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      const [, { addProduct }] = useCart()
      const [, { show }] = useSnackbar()
      const isLoading = ref<boolean>(false)
      const onDone = () => {
        isLoading.value = false
      }
      const onError = (error: Error) => {
        show({
          type: SnackbarType.ERROR,
          text: error.message,
          id: `product-to-cart-error-${new Date()}`,
        })
      }

      return {
        isLoading,
        translate,
        add() {
          isLoading.value = true

          addProduct(props.productId, 1, 'trade_unit')
            .catch(onError)
            .finally(onDone)
        },
      }
    },
  })
