
  import { computed, defineComponent, toRefs } from 'vue'
  import useProductList from '../../../../hooks/use-product-list/use-product-list'
  import pluralize from '../../../../util/strings/pluralize'
  import ProductFilters from '../../../scaffolding/product-filters/product-filters.vue'
  import CategoriesMenu from '../../../common/categories-menu/categories-menu.vue'
  import Icon from '../../../common/icon/icon.vue'
  import useOverlay from '../../../../hooks/use-overlay/use-overlay'
  import { OverlayIds } from '../../../../constants/overlay-ids'
  import useSidePanel, {
    SidePanelPosition,
  } from '../../../../hooks/use-side-panel/use-side-panel'
  import { PRODUCT_LIST_RENDERING_PARAM } from '../../../../hooks/use-product-list/utils'
  import { ProductListRenderingTypes } from '../../../../constants/product-list-rendering-types'
  import SimpleButton from '../../../common/buttons/simple-button/simple-button.vue'
  import translate from '../../../../util/misc/translate'
  import PollingExportButton from '../../../common/polling-export-button/polling-export-button.vue'
  import { getProductListExportEndpoint } from '../../../../constants/api-endpoints'

  export default defineComponent({
    components: { PollingExportButton, SimpleButton, Icon },
    setup() {
      const [, overlayMethods] = useOverlay()
      const [, sidePanelMethods] = useSidePanel()
      const [productListState, { updateProducts }] = useProductList()
      const { productPage, rendering } = toRefs(productListState)

      return {
        productPage,
        translate,
        exportUrl: computed(
          () =>
            // the 'productListState.productPage' is needed to re-compute the url anytime the productPage will get new filters
            productListState.productPage &&
            `${getProductListExportEndpoint()}${window.location.search}`,
        ),
        renderingButtonLabel: computed(() =>
          rendering.value === ProductListRenderingTypes.LIST
            ? translate('shop__overview__grid')
            : translate('shop__overview__list'),
        ),
        renderingButtonIcon: computed(() =>
          rendering.value === ProductListRenderingTypes.LIST
            ? `kachel-view`
            : `list-view`,
        ),
        showCategoriesMenu() {
          sidePanelMethods.open(
            {
              component: CategoriesMenu,
              props: {
                facets: ['side-panel'],
                hasFooter: true,
              },
            },
            SidePanelPosition.LEFT,
          )
        },
        productAmountLabel: computed(
          () =>
            `${productPage.value.totalElements} ${pluralize(
              `
                ${translate('shop__show_product')}
                ||||
                ${translate('shop__show_products')}`,
              productPage.value.totalElements,
            )}`,
        ),
        resetFilters() {
          updateProducts(productPage.value.filterResetUrl)
        },
        showFilters() {
          overlayMethods.open({
            id: OverlayIds.PRODUCT_FILTERS,
            component: ProductFilters,
            props: {},
          })
        },
        updateListRendering() {
          const params = new URLSearchParams(window.location.search)
          const baseUrl = window.location.href.split('?')[0]

          params.set(
            PRODUCT_LIST_RENDERING_PARAM,
            rendering.value === ProductListRenderingTypes.LIST
              ? ProductListRenderingTypes.GRID
              : ProductListRenderingTypes.LIST,
          )

          updateProducts(`${baseUrl}?${params.toString()}`)
        },
        pluralize,
      }
    },
  })
