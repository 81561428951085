
  import { computed, defineComponent, PropType } from 'vue'
  import Icon from '../icon/icon.vue'

  export default defineComponent({
    components: { Icon },
    props: {
      label: {
        type: String,
        default: '',
      },
      placeholder: {
        type: String,
        default: '',
      },
      hasErrors: {
        type: Boolean,
        default: false,
      },
      tag: {
        type: String,
        default: 'input',
      },
      facets: {
        type: Array as PropType<string[]>,
        default: () => ['vertical'],
      },
    },
    setup(props) {
      return {
        rootClasses: computed(() => [
          ...props.facets.map((facet) => `custom-select--${facet}`),
          props.hasErrors ? 'custom-select--has-errors' : null,
        ]),
      }
    },
  })
