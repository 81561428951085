import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "product-counter__input-wrapper" }
const _hoisted_2 = { class: "product-counter__hidden-label" }
const _hoisted_3 = {
  key: 0,
  class: "product-counter__spinner"
}
const _hoisted_4 = ["disabled", "value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!
  const _component_simple_button = _resolveComponent("simple-button")!
  const _component_spinner = _resolveComponent("spinner")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["product-counter", _ctx.rootClasses])
  }, [
    (_ctx.isEnabled)
      ? (_openBlock(), _createBlock(_component_simple_button, {
          key: 0,
          "aria-label": _ctx.translate('shop__product__add_cart'),
          facets: ['primary', 'bubble', 'small'],
          disabled: _ctx.isLoading,
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeCurrentValue(_ctx.currentValue + _ctx.quantityIncrement)))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_icon, {
              size: "small",
              name: "plus"
            })
          ]),
          _: 1
        }, 8, ["aria-label", "disabled"]))
      : _createCommentVNode("", true),
    _createElementVNode("label", _hoisted_1, [
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.translate('shop__product__cart_amount')), 1),
      (_ctx.isLoading)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
            _createVNode(_component_spinner)
          ]))
        : (_openBlock(), _createElementBlock("input", {
            key: 1,
            class: "product-counter__input",
            disabled: !_ctx.isEnabled,
            value: _ctx.currentValue,
            type: "number",
            onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onInputBlur && _ctx.onInputBlur(...args)))
          }, null, 40, _hoisted_4))
    ]),
    (_ctx.isEnabled)
      ? (_openBlock(), _createBlock(_component_simple_button, {
          key: 1,
          "aria-label": _ctx.translate('shop__product__remove_cart'),
          disabled: _ctx.value <= 0 || _ctx.isLoading,
          facets: ['primary', 'bubble', 'small'],
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.changeCurrentValue(_ctx.currentValue - _ctx.quantityIncrement)))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_icon, {
              size: "small",
              name: "minus"
            })
          ]),
          _: 1
        }, 8, ["aria-label", "disabled"]))
      : _createCommentVNode("", true)
  ], 2))
}