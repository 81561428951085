
  import { defineComponent } from 'vue'
  import Dropdown from '../dropdown/dropdown.vue'
  import SimpleButton from '../buttons/simple-button/simple-button.vue'
  import Icon from '../icon/icon.vue'
  import translate from '../../../util/misc/translate'

  export default defineComponent({
    components: { Icon, SimpleButton, Dropdown },
    setup() {
      return {
        translate,
      }
    },
  })
