<template>
  <section class="distribution-partners">
    <div class="grid grid--cms grid__container">
      <section class="grid__row">
        <slot name="zip-code-filter" :filter="filter"></slot>
      </section>
    </div>
    <div class="distribution-partners__result-section">
      <div class="grid grid--cms grid__container grid__container">
        <div class="grid__row">
          <ul class="distribution-partners__card-list u-reset">
            <li
              class="distribution-partners__card"
              v-for="(item, itemIdx) in currentItems"
              :key="itemIdx"
            >
              <h3 class="distribution-partners__card-title u-reset">
                {{ item.name }}
              </h3>
              <address class="distribution-partners__address">
                {{ item.street }}<br />
                {{ item.plz }} {{ item.locality }}<br />
                <a :href="'mailto:' + item.email">{{ item.email }}</a
                ><br />
                <a :href="'tel:' + item.phone">{{ item.phone }}</a
                ><br />
                <br />
                <a
                  :href="item.website"
                  target="_blank"
                  rel="nofollow noopener"
                  v-if="item.website"
                  >{{ item.website.replace(/http(s):\/\//, '') }}</a
                >
              </address>
              <div
                class="distribution-partners__assortment"
                v-if="item.categories.length"
              >
                <h4 class="distribution-partners__assortment-title u-reset">
                  <slot name="assortment-label"></slot>
                </h4>
                <ul class="distribution-partners__assortment-list u-reset">
                  <li
                    v-for="(category, categoryIdx) in item.categories"
                    :key="categoryIdx"
                  >
                    {{ category.category }}
                  </li>
                </ul>
              </div>
            </li>
          </ul>
          <div class="distribution-partners__actions">
            <slot
              name="load-more-button"
              :load-more="loadMore"
              v-if="currentPage < numPages"
            ></slot>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  // interface APIResponse {
  //   items: {
  //     name: string
  //     street: string
  //     locality: string
  //     plz: number
  //     email: string
  //     website: string
  //     phone: string
  //     categories: { category: string }[]
  //   }[]
  //   // eslint-disable-next-line
  //   initial_page: number
  //   // eslint-disable-next-line
  //   number_of_pages: number
  // }

  // declare global {
  //   // eslint-disable-next-line
  //   const __js_bridge: Record<string, unknown>
  // }

  export default {
    props: {
      initialData: {
        type: String,
        required: true,
      },
    },
    data() {
      const data = JSON.parse(this.initialData)
      return {
        items: data.items,
        currentItems: data.items,
        currentPage: data.initial_page,
        numPages: data.number_of_pages,
        filter: {
          value: '',
        },
      }
    },
    methods: {
      async loadMore() {
        // eslint-disable-next-line
        const lang = __js_bridge.currentLanguage
        const url = `/${lang}/content/api/distribution-partners?page=${
          this.currentPage + 1
        }`
        try {
          const res = await fetch(url)
          const { items } = await res.json()
          this.items.push(...items)
          this.currentItems = this.filterItems(this.items, this.filter.value)
          this.currentPage += 1
        } catch (e) {
          console.log(e)
        }
      },
      filterItems(items, filter) {
        return !filter
          ? items
          : items.filter((item) =>
              item.distribution_localities.some((v) => {
                return String(v).startsWith(filter)
              }),
            )
      },
    },
    watch: {
      filter: {
        deep: true,
        immediate: false,
        handler(filter) {
          this.currentItems = this.filterItems(this.items, filter.value)
        },
      },
    },
  }
</script>
