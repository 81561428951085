
  import {
    computed,
    defineComponent,
    nextTick,
    toRefs,
    watch,
    ref,
    watchEffect,
    onUnmounted,
  } from 'vue'
  import useSidePanel from '../../../hooks/use-side-panel/use-side-panel'
  import useScrollLock from '../../../hooks/use-scroll-lock/use-scroll-lock'
  import breakpoints from '../../../../design-tokens/breakpoints.json'
  import useViewportSize from '../../../hooks/use-viewport-size/use-viewport-size'
  import useMobileMenu from '../../../hooks/use-mobile-menu/use-mobile-menu'
  import usePanelsCloseQueue from '../../../hooks/use-panels-close-queue/use-panels-close-queue'

  export default defineComponent({
    setup() {
      const [sidePanelState, { close }] = useSidePanel()
      const [, toggleMobileMenu] = useMobileMenu()
      const panelsCloseQueue = usePanelsCloseQueue()
      const { panel, position } = toRefs(sidePanelState)
      const [, { lock, unlock }] = useScrollLock()
      const { width } = toRefs(useViewportSize())
      const isPanelVisible = ref(false)

      onUnmounted(() => panelsCloseQueue.unsubscribe(close))

      watchEffect(() =>
        panel.value
          ? panelsCloseQueue.subscribe(close)
          : panelsCloseQueue.unsubscribe(close),
      )

      watch(panel, () => {
        isPanelVisible.value = false

        nextTick(() => {
          const isSidePanelOpen = Boolean(panel.value)

          if (isSidePanelOpen) {
            lock()
            isPanelVisible.value = true
            toggleMobileMenu(false)
          } else {
            unlock()
          }
        })
      })

      watch(width, () => {
        if (width.value >= breakpoints.lg) {
          close()
        }
      })

      return {
        panel,
        close,
        isPanelVisible,
        transition: computed(() => `trs-side-panel-${position.value}`),
        rootClasses: computed(() => [
          `side-panel--${position.value}`,
          {
            'side-panel--is-active': panel.value,
          },
        ]),
      }
    },
  })
