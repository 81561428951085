import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, Suspense as _Suspense, renderList as _renderList, Fragment as _Fragment, TransitionGroup as _TransitionGroup, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "cart-panel__header"
}
const _hoisted_2 = { class: "cart-panel__header-price" }
const _hoisted_3 = { class: "cart-panel__title u-reset" }
const _hoisted_4 = { class: "cart-panel__total-price" }
const _hoisted_5 = {
  key: 0,
  class: "cart-panel__error"
}
const _hoisted_6 = { class: "cart-panel__header-buttons button-group button-group--vertical" }
const _hoisted_7 = {
  key: 1,
  class: "cart-panel__info-block"
}
const _hoisted_8 = { class: "cart-panel__info-block-text" }
const _hoisted_9 = { class: "cart-panel__info-block-title u-reset" }
const _hoisted_10 = { class: "cart-panel__info-block-description" }
const _hoisted_11 = {
  key: 2,
  class: "cart-panel__info-block cart-panel__info-block--full-width"
}
const _hoisted_12 = { class: "cart-panel__info-block-text" }
const _hoisted_13 = { class: "cart-panel__info-block-title u-reset" }
const _hoisted_14 = { class: "cart-panel__info-block-input" }
const _hoisted_15 = { class: "cart-panel__info-block" }
const _hoisted_16 = { class: "cart-panel__info-block-text" }
const _hoisted_17 = { class: "cart-panel__info-block-title u-reset" }
const _hoisted_18 = { class: "cart-panel__info-block cart-panel__info-block--error" }
const _hoisted_19 = { class: "cart-panel__info-block-text" }
const _hoisted_20 = { class: "cart-panel__info-block-title u-reset" }
const _hoisted_21 = { class: "cart-panel__info-block-description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_generic_inline_loader = _resolveComponent("generic-inline-loader")!
  const _component_spinner = _resolveComponent("spinner")!
  const _component_icon = _resolveComponent("icon")!
  const _component_simple_button = _resolveComponent("simple-button")!
  const _component_cart_calendar = _resolveComponent("cart-calendar")!
  const _component_input_field = _resolveComponent("input-field")!
  const _component_cart_item = _resolveComponent("cart-item")!
  const _component_empty_state = _resolveComponent("empty-state")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["cart-panel", _ctx.rootClasses])
  }, [
    (_ctx.isLoadingCart)
      ? (_openBlock(), _createBlock(_component_generic_inline_loader, { key: 0 }))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_ctx.isCartEnabled)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                (_ctx.isUpdatingCartStatus)
                  ? (_openBlock(), _createBlock(_component_spinner, {
                      key: 0,
                      class: "cart-panel__status-spinner"
                    }))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.cart.items.length) + " " + _toDisplayString(_ctx.pluralize(_ctx.translate('shop__cart__products'), _ctx.cart.items.length)), 1),
                  _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.cart.orderPricing.currency) + " " + _toDisplayString(_ctx.cart.orderPricing.netPriceFormatted), 1),
                  (_ctx.hasDisabledDeliveries)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_5, [
                        _createVNode(_component_icon, {
                          size: "medium",
                          name: "error-outline"
                        }),
                        _createTextVNode(" " + _toDisplayString(_ctx.translate('shop__cart__error')), 1)
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_simple_button, {
                    facets: ['primary', 'round'],
                    disabled: 
              _ctx.isUpdatingCartStatus ||
              (!_ctx.validExpectedDelivery && !_ctx.dropshippingDeliveries)
            ,
                    onClick: _ctx.onCheckoutClick
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.translate('shop__cart__purchase')), 1)
                    ]),
                    _: 1
                  }, 8, ["disabled", "onClick"]),
                  _createVNode(_component_simple_button, {
                    facets: ['primary', 'round', 'outlined'],
                    disabled: 
              _ctx.isUpdatingCartStatus ||
              (!_ctx.validExpectedDelivery && !_ctx.dropshippingDeliveries)
            ,
                    onClick: _ctx.onClearCartClick
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.translate('shop__cart__empty_cart')), 1)
                    ]),
                    _: 1
                  }, 8, ["disabled", "onClick"])
                ])
              ]))
            : _createCommentVNode("", true),
          (_ctx.isCartEnabled)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createVNode(_component_icon, {
                  class: "cart-panel__info-block-icon cart-panel__info-block-icon--small",
                  size: "small",
                  name: "delivery"
                }),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("h3", _hoisted_9, _toDisplayString(_ctx.guessExpectedDeliveryDate(_ctx.validExpectedDelivery)), 1),
                  _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.guessExpectedDeliveryItemsAmount(_ctx.validExpectedDelivery)) + " " + _toDisplayString(_ctx.pluralize(
                _ctx.translate('shop__cart__products'),
                _ctx.guessExpectedDeliveryItemsAmount(_ctx.validExpectedDelivery),
              )) + ", " + _toDisplayString(_ctx.guessExpectedDeliveryTuQuantity(_ctx.validExpectedDelivery)) + " TUs, " + _toDisplayString(_ctx.guessExpectedDeliveryCuQuantity(_ctx.validExpectedDelivery)) + " CUs ", 1)
                ]),
                (_openBlock(), _createBlock(_Suspense, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_cart_calendar, { "valid-expected-delivery": _ctx.validExpectedDelivery }, null, 8, ["valid-expected-delivery"])
                  ]),
                  fallback: _withCtx(() => [
                    _createVNode(_component_spinner)
                  ]),
                  _: 1
                }))
              ]))
            : _createCommentVNode("", true),
          (_ctx.isCartEnabled)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                _createVNode(_component_icon, {
                  class: "cart-panel__info-block-icon cart-panel__info-block-icon--small",
                  size: "small",
                  name: "add-document-done"
                }),
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("h3", _hoisted_13, _toDisplayString(_ctx.translate('shop__cart__customer_order_number')), 1),
                  _createElementVNode("div", _hoisted_14, [
                    _createVNode(_component_input_field, {
                      required: "",
                      name: "customerOrderNumber",
                      value: _ctx.customerOrderNumber,
                      facets: ['can-grow'],
                      onInput: _ctx.onCustomerOrderNumberChange
                    }, null, 8, ["value", "onInput"]),
                    _createVNode(_component_simple_button, {
                      class: "cart-panel__info-block-button",
                      facets: ['inline', 'no-padding'],
                      onClick: _ctx.onCustomerOrderNumberSave
                    }, {
                      default: _withCtx(() => [
                        (_ctx.isUpdatingCustomerOrderNumber)
                          ? (_openBlock(), _createBlock(_component_spinner, { key: 0 }))
                          : (_openBlock(), _createBlock(_component_icon, {
                              key: 1,
                              name: "save",
                              size: "medium"
                            }))
                      ]),
                      _: 1
                    }, 8, ["onClick"]),
                    _createVNode(_component_simple_button, {
                      class: "cart-panel__info-block-button",
                      facets: ['inline', 'no-padding'],
                      onClick: _ctx.onClearCustomerOrderNumberClick
                    }, {
                      default: _withCtx(() => [
                        (_ctx.isDeletingCustomerOrderNumber)
                          ? (_openBlock(), _createBlock(_component_spinner, { key: 0 }))
                          : (_openBlock(), _createBlock(_component_icon, {
                              key: 1,
                              name: "trash",
                              size: "medium"
                            }))
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ])
                ])
              ]))
            : _createCommentVNode("", true),
          (_ctx.hasProducts)
            ? (_openBlock(), _createBlock(_TransitionGroup, {
                key: 3,
                class: "cart-panel__product-list u-reset",
                name: "trs-new-item-background",
                tag: "ul"
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getCartItemsByExpectedDelivery(_ctx.validExpectedDelivery), (item) => {
                    return (_openBlock(), _createBlock(_component_cart_item, {
                      item: item,
                      "is-disabled": false,
                      class: "cart-panel__product",
                      key: item.itemNumber
                    }, null, 8, ["item"]))
                  }), 128))
                ]),
                _: 1
              }))
            : (_openBlock(), _createBlock(_component_empty_state, {
                key: 4,
                text: _ctx.translate('shop__cart__empty-message')
              }, null, 8, ["text"])),
          (_ctx.dropshippingDeliveries.length > 0)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 5 }, [
                _createElementVNode("div", _hoisted_15, [
                  _createVNode(_component_icon, {
                    class: "cart-panel__info-block-icon cart-panel__info-block-icon--small",
                    size: "small",
                    name: "delivery"
                  }),
                  _createElementVNode("div", _hoisted_16, [
                    _createElementVNode("h3", _hoisted_17, _toDisplayString(_ctx.translate('shop__cart__dropshipping_delivery')), 1)
                  ])
                ]),
                (_ctx.hasProducts)
                  ? (_openBlock(), _createBlock(_TransitionGroup, {
                      key: 0,
                      class: "cart-panel__product-list u-reset",
                      name: "trs-new-item-background",
                      tag: "ul"
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dropshippingDeliveries, (dropshippingDelivery, index) => {
                          return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getCartItemsByExpectedDelivery(
                dropshippingDelivery,
              ), (item) => {
                              return (_openBlock(), _createBlock(_component_cart_item, {
                                item: item,
                                "is-disabled": false,
                                date: dropshippingDelivery.deliveryArrangement.targetDate ?? '',
                                class: "cart-panel__product",
                                key: item.itemNumber
                              }, null, 8, ["item", "date"]))
                            }), 128))
                          ], 64))
                        }), 128))
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ], 64))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.disabledDeliveries, (disabledDelivery, index) => {
            return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
              _createElementVNode("div", _hoisted_18, [
                _createVNode(_component_icon, {
                  class: "cart-panel__info-block-icon",
                  size: "medium",
                  name: "error-outline"
                }),
                _createElementVNode("div", _hoisted_19, [
                  _createElementVNode("h3", _hoisted_20, _toDisplayString(_ctx.translate('shop__cart__disabled_delivery')) + " " + _toDisplayString(_ctx.showInvalidDeliveryDate(_ctx.validExpectedDelivery)), 1),
                  _createElementVNode("p", _hoisted_21, _toDisplayString(_ctx.guessExpectedDeliveryItemsAmount(disabledDelivery)) + " " + _toDisplayString(_ctx.pluralize(
                  _ctx.translate('shop__cart__products'),
                  _ctx.guessExpectedDeliveryItemsAmount(disabledDelivery),
                )) + ", " + _toDisplayString(_ctx.guessExpectedDeliveryTuQuantity(disabledDelivery)) + " TUs, " + _toDisplayString(_ctx.guessExpectedDeliveryCuQuantity(disabledDelivery)) + " CUs, " + _toDisplayString(_ctx.guessExpectedDeliveryCurrency(disabledDelivery)) + " " + _toDisplayString(_ctx.guessExpectedDeliveryPrice(disabledDelivery)), 1)
                ]),
                _createVNode(_component_simple_button, {
                  class: "cart-panel__info-block-button",
                  facets: ['no-padding', 'error'],
                  diabled: _ctx.isDeletingProducts,
                  onClick: ($event: any) => (
              _ctx.deleteProducts(_ctx.getCartItemsByExpectedDelivery(disabledDelivery))
            )
                }, {
                  default: _withCtx(() => [
                    (_ctx.isDeletingProducts)
                      ? (_openBlock(), _createBlock(_component_spinner, { key: 0 }))
                      : (_openBlock(), _createBlock(_component_icon, {
                          key: 1,
                          name: "trash",
                          size: "medium"
                        }))
                  ]),
                  _: 2
                }, 1032, ["diabled", "onClick"])
              ]),
              (_ctx.hasProducts)
                ? (_openBlock(), _createBlock(_TransitionGroup, {
                    key: 0,
                    class: "cart-panel__product-list u-reset",
                    name: "trs-new-item-background",
                    tag: "ul"
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getCartItemsByExpectedDelivery(disabledDelivery), (item) => {
                        return (_openBlock(), _createBlock(_component_cart_item, {
                          item: item,
                          "is-disabled": false,
                          "has-delivery-icon": true,
                          class: "cart-panel__product cart-panel__product--error",
                          key: item.itemNumber
                        }, null, 8, ["item"]))
                      }), 128))
                    ]),
                    _: 2
                  }, 1024))
                : _createCommentVNode("", true)
            ], 64))
          }), 128))
        ], 64))
  ], 2))
}