
  import { computed, defineComponent, PropType, ref } from 'vue'
  import SimpleButton from '../../common/buttons/simple-button/simple-button.vue'
  import Icon from '../../common/icon/icon.vue'
  import { OrderPage } from '../../../types/paloma-api-customers'
  import EmptyState from '../../common/empty-state/empty-state.vue'
  import translate from '../../../util/misc/translate'
  import getPluralizedProductTranslation from '../../../util/strings/get-pluralized-product-translation'
  import formatDateToLocaleDateString from '../../../util/strings/format-date-to-locale-date-string'
  import Spinner from '../../common/spinner/spinner.vue'
  import { getOrderListEndpoint } from '../../../constants/api-endpoints'
  import parseResponse from '../../../util/misc/parse-response'
  import useSnackbar, {
    SnackbarType,
  } from '../../../hooks/use-snackbar/use-snackbar'

  export default defineComponent({
    components: {
      Spinner,
      EmptyState,
      Icon,
      SimpleButton,
    },
    props: {
      orderPage: {
        type: Object as PropType<OrderPage>,
        required: true,
      },
    },
    setup(props) {
      const page = ref<OrderPage>({ ...props.orderPage })
      const [, { show }] = useSnackbar()
      const orders = computed(() => page.value.content)
      const isLoadingMore = ref<boolean>(false)
      const currentPage = ref<number>(0)

      const onSuccess = (data: OrderPage) => {
        page.value.content.push(...data.content)
      }

      const onDone = () => {
        isLoadingMore.value = false
      }

      const onError = (error: Error) => {
        show({
          type: SnackbarType.ERROR,
          text: error.message,
          id: `order-list-error-${new Date()}`,
        })
      }

      return {
        orders,
        isLoadingMore,
        hasLoadMoreButton: computed(
          () => page.value.totalElements > orders.value.length,
        ),
        loadMore() {
          isLoadingMore.value = true
          currentPage.value += 1

          fetch(`${getOrderListEndpoint()}?p=${currentPage.value}`)
            .then((res) => parseResponse<OrderPage>(res))
            .then(onSuccess)
            .catch(onError)
            .finally(onDone)
        },
        translate,
        formatDateToLocaleDateString,
        getPluralizedProductTranslation,
        hasOrders: computed(() => orders.value.length),
      }
    },
  })
