
  import cookie from 'js-cookie'
  import { isObject } from 'lodash/fp'
  import { defineComponent, onMounted } from 'vue'
  import useUser from '../../../hooks/use-user/use-user'
  import globals, { MarketingOverlay } from '../../../globals'
  import useOverlay from '../../../hooks/use-overlay/use-overlay'
  import MarketingOverlayComponent from '../overlay-types/marketing-overlay/marketing-overlay.vue'
  import { OverlayIds } from '../../../constants/overlay-ids'

  export enum MarketingOverlayCookies {
    AUTHENTICATED_MARKETING_OVERLAY = 'authenticated-marketing-overlay',
    STANDARD_MARKETING_OVERLAY = 'standard-marketing-overlay',
  }

  export type MarketingOverlayListItem = {
    validation: () => boolean
    props: MarketingOverlay
    cookieName: MarketingOverlayCookies
  }

  export default defineComponent({
    setup() {
      const user = useUser()
      const [, { open }] = useOverlay()
      const canOpenOverlay = (overlay: MarketingOverlay, name: string) => {
        const cookieValue = cookie.get(name)

        return (
          isObject(overlay) && (!cookieValue || cookieValue !== overlay.uuid)
        )
      }
      // open an overlay
      const openOverlay = (cookieName: string, props: MarketingOverlay) => {
        cookie.set(cookieName, props.uuid, { secure: true, sameSite: 'Lax' })

        open({
          component: MarketingOverlayComponent,
          id: OverlayIds.MARKETING_OVERLAY,
          props,
        })
      }
      // figure out which overlays should be opened right the way when the site loads
      const overlaysToOpen: MarketingOverlayListItem[] = [
        {
          validation: () =>
            user.isAuthenticated &&
            canOpenOverlay(
              globals.authenticatedOverlay,
              MarketingOverlayCookies.AUTHENTICATED_MARKETING_OVERLAY,
            ),
          props: globals.authenticatedOverlay,
          cookieName: MarketingOverlayCookies.AUTHENTICATED_MARKETING_OVERLAY,
        },
        {
          validation: () =>
            canOpenOverlay(
              globals.standardOverlay,
              MarketingOverlayCookies.STANDARD_MARKETING_OVERLAY,
            ),
          props: globals.standardOverlay,
          cookieName: MarketingOverlayCookies.STANDARD_MARKETING_OVERLAY,
        },
      ].filter(({ validation }) => validation())

      const iterateTheOverlaySeries = (
        index: number,
        currentOverlayItem?: MarketingOverlayListItem,
      ) => {
        // if the series is over stop the recursion
        if (!currentOverlayItem) return

        const { props, cookieName } = currentOverlayItem
        // on overlay close we will open the next one
        props.onClose = () => {
          const nextIndex = index + 1
          iterateTheOverlaySeries(nextIndex, overlaysToOpen[nextIndex])
        }

        openOverlay(cookieName, props)
      }

      onMounted(() => {
        // open recursively all the overlays to open on page load
        iterateTheOverlaySeries(0, overlaysToOpen[0])
      })
    },
    render() {
      return null
    },
  })
