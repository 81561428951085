import { once } from 'lodash/fp'
import { reactive, readonly } from 'vue'
import globals from '../../globals'
import { UserData } from './types'

const getUserState = once(() => {
  return reactive<UserData>(globals.userData)
})

export default function useUser() {
  return readonly(getUserState())
}
