
  import { defineComponent } from 'vue'
  import useMobileMenu from '../../../hooks/use-mobile-menu/use-mobile-menu'

  export default defineComponent({
    setup() {
      const [isOpen, setMenuState] = useMobileMenu()

      return {
        isOpen,
        toggleMenu() {
          setMenuState(!isOpen.value)
        },
      }
    },
  })
