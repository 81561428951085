import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "order-detail" }
const _hoisted_2 = { class: "order-detail__buttons button-group button-group--horizontal button-group--space-between" }
const _hoisted_3 = { class: "order-detail__order-total" }
const _hoisted_4 = { class: "u-reset__h6 u-reset" }
const _hoisted_5 = { class: "order-detail__header" }
const _hoisted_6 = { class: "u-reset__h6 u-reset" }
const _hoisted_7 = ["datetime"]
const _hoisted_8 = { class: "order-detail__info" }
const _hoisted_9 = { class: "order-detail__header-light-text" }
const _hoisted_10 = {
  key: 0,
  class: "order-detail__comment u-reset"
}
const _hoisted_11 = { class: "order-detail__list u-reset" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!
  const _component_simple_button = _resolveComponent("simple-button")!
  const _component_spinner = _resolveComponent("spinner")!
  const _component_product_list_item_lite = _resolveComponent("product-list-item-lite")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_simple_button, {
        tag: "a",
        href: _ctx.order.exportUrl,
        facets: ['inline', 'no-padding']
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.translate('shop__order__export_list__button')) + " ", 1),
          _createVNode(_component_icon, {
            name: "export",
            size: "medium"
          })
        ]),
        _: 1
      }, 8, ["href"]),
      _createVNode(_component_simple_button, {
        facets: ['primary', 'round'],
        disabled: _ctx.isAddingItemsToCart,
        onClick: _ctx.onAddItemsToCart
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.translate('shop__order__add_products_to_cart__button', {
            amount: _ctx.productsAmount,
          })) + " ", 1),
          (_ctx.isAddingItemsToCart)
            ? (_openBlock(), _createBlock(_component_spinner, { key: 0 }))
            : (_openBlock(), _createBlock(_component_icon, {
                key: 1,
                name: "cart",
                size: "medium"
              }))
        ]),
        _: 1
      }, 8, ["disabled", "onClick"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.translate('shop__order__total')) + ": " + _toDisplayString(_ctx.order.currency) + " " + _toDisplayString(_ctx.order.totals.orderTotal), 1)
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.deliveryBatches, (deliveryBatch, index) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
        _createElementVNode("header", _hoisted_5, [
          _createElementVNode("h2", _hoisted_6, _toDisplayString(_ctx.translate('shop__order__delivered__title')), 1),
          _createElementVNode("time", {
            class: "order-detail__header-light-text",
            datetime: deliveryBatch.targetDate
          }, _toDisplayString(_ctx.translate('shop__order__delivery__date')) + ": " + _toDisplayString(_ctx.formatDateToLocaleDateString(deliveryBatch.targetDate)), 9, _hoisted_7),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("p", _hoisted_9, _toDisplayString(deliveryBatch.items.length) + " " + _toDisplayString(_ctx.pluralize(
                _ctx.translate('shop__cart__products'),
                deliveryBatch.items.length,
              )), 1)
          ])
        ]),
        (_ctx.order.comment)
          ? (_openBlock(), _createElementBlock("p", _hoisted_10, _toDisplayString(_ctx.order.comment), 1))
          : _createCommentVNode("", true),
        _createElementVNode("ul", _hoisted_11, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(deliveryBatch.items, (item) => {
            return (_openBlock(), _createElementBlock("li", {
              class: "order-detail__item",
              key: item.sku
            }, [
              _createVNode(_component_product_list_item_lite, {
                quantity: item.quantity,
                title: item.name,
                features: [
              {
                url: item?.dreipolAttributes?.brand?.url ?? '',
                title: item?.dreipolAttributes?.brand?.value ?? '',
              },
              {
                icon: {
                  name: item?.dreipolAttributes?.storage_advice?.code ?? '',
                  size: 'medium',
                },
              },
              {
                text: item?.dreipolAttributes.storage_advice?.value ?? '',
              },
            ],
                "quantity-description": _ctx.getQuantityDescription(item),
                "product-number": item?.sku,
                "product-id": item.sku,
                "total-price": item.grossItemTotal,
                "price-suffix": item.unit ? item.unit : 'TU',
                "show-counter": true,
                "is-counter-enabled": false,
                "image-sources": item.image?.sources,
                currency: _ctx.order.currency,
                unit: item.unit,
                "unit-code": item.unitCode
              }, null, 8, ["quantity", "title", "features", "quantity-description", "product-number", "product-id", "total-price", "price-suffix", "image-sources", "currency", "unit", "unit-code"])
            ]))
          }), 128))
        ])
      ], 64))
    }), 128))
  ]))
}