import { once } from 'lodash/fp'
import { ref, readonly, markRaw } from 'vue'
import { Overlay, UseOverlayAPI } from './types'

const getOverlayAPI = once((): UseOverlayAPI => {
  const overlays = ref<Overlay[]>([])

  function open<T extends Record<string, unknown>>(overlay: Overlay<T>) {
    overlays.value = [...overlays.value, markRaw(overlay)]
  }

  function close(overlayId: string) {
    const overlayToRemove = overlays.value.find(({ id }) => id === overlayId)

    if (overlayToRemove) {
      overlays.value = overlays.value.filter(
        ({ id }) => id !== overlayToRemove.id,
      )

      // trigger a custom onClose event if it was set
      if (overlayToRemove.props && overlayToRemove.props.onClose) {
        overlayToRemove.props.onClose()
      }
    }
  }

  return [overlays, { open, close }]
})

export default function useOverlay() {
  return readonly(getOverlayAPI())
}
