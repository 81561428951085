import { Component } from 'vue'
import { SwiperSlide } from 'swiper/vue'

import basename from '../util/misc/basename'

const globalComponentsContext = require.context(
  './',
  true,
  /[a-zA-Z0-9-]+\.vue$/,
)
const getComponentNameByPath = (path: string) => basename(path, '.vue')

export default function getGlobalComponents() {
  return globalComponentsContext.keys().reduce<Record<string, Component>>(
    (acc, path) => {
      const component = globalComponentsContext(path).default

      return {
        ...acc,
        [getComponentNameByPath(path)]: component,
      }
    },
    {
      'swiper-slide': SwiperSlide,
    },
  )
}
