
  import { defineComponent, PropType } from 'vue'
  import InlineNotification from '../inline-notification/inline-notification.vue'
  import { InlineNotificationType } from '../inline-notification/types'

  export default defineComponent({
    components: { InlineNotification },
    props: {
      notifications: {
        type: Array as PropType<
          {
            message: string
            type: InlineNotificationType
          }[]
        >,
        required: true,
      },
    },
  })
