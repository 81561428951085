import { readonly, ref } from 'vue'
import { once } from 'lodash/fp'
import {
  EnhancedProductFinderProduct,
  ProductFinderEndpointPayload,
  UseProductFinderAPI,
} from './types'
import { getProductsFinderEndpoint } from '../../constants/api-endpoints'
import parseResponse from '../../util/misc/parse-response'

const getUseProductFinderAPI: () => UseProductFinderAPI = once(() => {
  const products = ref<EnhancedProductFinderProduct[]>([])
  const matched = ref<string>('')
  const unmatched = ref<string>('')
  const isLoading = ref<boolean>(false)
  const onBeforeFetch = () => {
    isLoading.value = true
  }
  const onDone = () => {
    isLoading.value = false
  }
  const reset = () => {
    products.value = []
    matched.value = ''
    unmatched.value = ''
  }
  const onSuccess = (data: ProductFinderEndpointPayload) => {
    products.value = data.products
    matched.value = data.matched
    unmatched.value = data.unmatched

    return data
  }
  const updateProductAmount = (itemNumber: string, newAmount: number) => {
    products.value = products.value.map((product) =>
      product.itemNumber === itemNumber
        ? { ...product, amount: newAmount }
        : product,
    )

    return products
  }

  const find = (form: FormData) => {
    onBeforeFetch()

    return fetch(getProductsFinderEndpoint(), {
      method: 'post',
      body: form,
    })
      .then((res) => parseResponse<ProductFinderEndpointPayload>(res))
      .then(onSuccess)
      .finally(onDone)
  }

  return [
    {
      products,
      matched,
      unmatched,
      isLoading,
    },
    {
      updateProductAmount,
      reset,
      find,
    },
  ]
})

export default function useProductFinder() {
  return readonly(getUseProductFinderAPI())
}
