import { once, last, isFunction } from 'lodash/fp'
import { readonly } from 'vue'
import { Key } from 'w3c-keys'
import useKeyPress from '../use-key-press/use-key-press'

const subscriptions = new Set()

/**
 * This hook will be used to queue the panels/overlays that will be displayed on the ui
 * It's combined with the useKeyPress hook to hide the panels in the order they will appear in the viewport
 */
const getPanelsCloseQueueApi = once(() => {
  // add a new close callback to the queue
  const subscribe = (closeCallback: unknown) => {
    subscriptions.add(closeCallback)
  }
  // remove the close callback from the queue (in case of useUnmount)
  const unsubscribe = (closeCallback: unknown) => {
    subscriptions.delete(closeCallback)
  }
  const dequeue = () => {
    const lastCloseCallback = last(Array.from(subscriptions))

    if (lastCloseCallback) {
      if (isFunction(lastCloseCallback)) lastCloseCallback()

      unsubscribe(lastCloseCallback)
    }
  }

  // dequeue the panel close events on esc press
  useKeyPress(Key.Escape, dequeue, true)

  return {
    subscribe,
    unsubscribe,
    dequeue,
  }
})

export default function usePanelsCloseQueue() {
  return readonly(getPanelsCloseQueueApi())
}
