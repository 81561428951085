import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "side-menu__nav" }
const _hoisted_2 = {
  key: 0,
  class: "side-menu__item side-menu__item--parent"
}
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["href"]
const _hoisted_5 = { class: "side-menu__footer grid__container grid__container--fluid" }
const _hoisted_6 = { class: "side-menu__nav-dropdown side-menu__nav-dropdown--right-aligned u-reset" }
const _hoisted_7 = ["href"]
const _hoisted_8 = { class: "side-menu__nav-dropdown-item" }
const _hoisted_9 = ["href"]
const _hoisted_10 = { class: "side-menu__lang-menu u-reset" }
const _hoisted_11 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!
  const _component_simple_button = _resolveComponent("simple-button")!
  const _component_dropdown = _resolveComponent("dropdown")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["side-menu grid grid--base", _ctx.rootClasses])
  }, [
    _createElementVNode("nav", _hoisted_1, [
      _createVNode(_Transition, { name: _ctx.transitionName }, {
        default: _withCtx(() => [
          (_openBlock(), _createElementBlock("ul", {
            class: "side-menu__list u-reset",
            key: _ctx.selectedItem
          }, [
            (_ctx.isParentVisible)
              ? (_openBlock(), _createElementBlock("li", _hoisted_2, [
                  _createElementVNode("span", {
                    class: "side-menu__item-link grid__container grid__container--fluid",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.activateParent()))
                  }, [
                    _createVNode(_component_icon, {
                      class: "side-menu__item-link-icon",
                      name: "arrow-right",
                      size: "medium"
                    }),
                    _createTextVNode(" " + _toDisplayString(_ctx.selectedItem.label), 1)
                  ])
                ]))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activeItems, (item) => {
              return (_openBlock(), _createElementBlock("li", {
                class: "side-menu__item",
                key: item.id
              }, [
                (item.children)
                  ? (_openBlock(), _createElementBlock("span", {
                      key: 0,
                      class: _normalizeClass(["side-menu__item-link grid__container grid__container--fluid", _ctx.getMenuItemClasses(item)]),
                      onClick: ($event: any) => (_ctx.selectItem(item))
                    }, [
                      _createTextVNode(_toDisplayString(item.label) + " ", 1),
                      _createVNode(_component_icon, {
                        class: "side-menu__item-link-icon",
                        name: "arrow-right",
                        size: "medium"
                      })
                    ], 10, _hoisted_3))
                  : (_openBlock(), _createElementBlock("a", {
                      key: 1,
                      class: _normalizeClass(["side-menu__item-link u-reset grid__container grid__container--fluid", _ctx.getMenuItemClasses(item)]),
                      href: item.link
                    }, _toDisplayString(item.label), 11, _hoisted_4))
              ]))
            }), 128))
          ]))
        ]),
        _: 1
      }, 8, ["name"])
    ]),
    _createElementVNode("footer", _hoisted_5, [
      (_ctx.user.isAuthenticated)
        ? (_openBlock(), _createBlock(_component_dropdown, {
            key: 0,
            class: "side-menu__dropdown"
          }, {
            trigger: _withCtx(() => [
              _createVNode(_component_simple_button, {
                facets: ['link', 'no-padding'],
                tag: "div"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_icon, {
                    size: "medium",
                    name: "login"
                  }),
                  _createTextVNode(" " + _toDisplayString(_ctx.user.username), 1)
                ]),
                _: 1
              })
            ]),
            content: _withCtx(() => [
              _createElementVNode("ul", _hoisted_6, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.userLinks, (userLink, index) => {
                  return (_openBlock(), _createElementBlock("li", {
                    class: "side-menu__nav-dropdown-item",
                    key: index
                  }, [
                    _createElementVNode("a", {
                      class: "side-menu__nav-dropdown-link u-reset",
                      href: userLink.link
                    }, _toDisplayString(userLink.label), 9, _hoisted_7)
                  ]))
                }), 128)),
                _createElementVNode("li", _hoisted_8, [
                  _createElementVNode("a", {
                    class: "side-menu__nav-dropdown-link u-reset",
                    href: _ctx.logoutLink
                  }, _toDisplayString(_ctx.translate('header__logout')), 9, _hoisted_9),
                  _createVNode(_component_icon, {
                    name: "logout",
                    size: "medium"
                  })
                ])
              ])
            ]),
            _: 1
          }))
        : (_openBlock(), _createBlock(_component_simple_button, {
            key: 1,
            href: _ctx.loginLink,
            facets: ['link', 'no-padding'],
            tag: "a"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_icon, {
                size: "medium",
                name: "login"
              }),
              _createTextVNode(" " + _toDisplayString(_ctx.translate('header__login')), 1)
            ]),
            _: 1
          }, 8, ["href"])),
      _createElementVNode("ul", _hoisted_10, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.languages, (language, index) => {
          return (_openBlock(), _createElementBlock("li", {
            class: "side-menu__lang-menu-item",
            key: index
          }, [
            _createElementVNode("a", {
              class: _normalizeClass(["side-menu__lang-menu-link u-reset", {
              'side-menu__lang-menu-link--is-active': language.isActive,
            }]),
              href: language.link
            }, _toDisplayString(language.label), 11, _hoisted_11)
          ]))
        }), 128))
      ])
    ])
  ], 2))
}