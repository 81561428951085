import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "product-list-toolbar",
  id: "product-list-toolbar"
}
const _hoisted_2 = { class: "product-list__filters" }
const _hoisted_3 = { class: "button-group button-group--horizontal button-group--space-between" }
const _hoisted_4 = { class: "button-group button-group--horizontal" }
const _hoisted_5 = { class: "product-list-toolbar__misc u-reset" }
const _hoisted_6 = { class: "product-list-toolbar__misc-item product-list-toolbar__misc-item--amount" }
const _hoisted_7 = { class: "product-list-toolbar__misc-item" }
const _hoisted_8 = { class: "product-list-toolbar__misc-item product-list-toolbar__misc-item--rendering-toggle" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_simple_button = _resolveComponent("simple-button")!
  const _component_icon = _resolveComponent("icon")!
  const _component_polling_export_button = _resolveComponent("polling-export-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_simple_button, {
          facets: ['primary', 'round', 'outlined'],
          class: "product-list-toolbar__categories-toggle-button",
          onClick: _ctx.showCategoriesMenu
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.translate('shop__overview__categories')), 1)
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_simple_button, {
            facets: [
              'primary',
              'round',
              'outlined',
              _ctx.productPage.hasActiveFilters ? 'is-active' : '',
            ],
            onClick: _ctx.showFilters
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.translate('shop__overview__filters')), 1)
            ]),
            _: 1
          }, 8, ["facets", "onClick"]),
          (_ctx.productPage.hasActiveFilters)
            ? (_openBlock(), _createBlock(_component_simple_button, {
                key: 0,
                facets: ['primary', 'round', 'outlined'],
                onClick: _ctx.resetFilters
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.translate('shop__overview__filters__reset')) + " ", 1),
                  _createVNode(_component_icon, {
                    name: "close",
                    size: "medium"
                  })
                ]),
                _: 1
              }, 8, ["onClick"]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _createElementVNode("ul", _hoisted_5, [
      _createElementVNode("li", _hoisted_6, _toDisplayString(_ctx.productAmountLabel), 1),
      _createElementVNode("li", _hoisted_7, [
        _createVNode(_component_polling_export_button, {
          "export-url": _ctx.exportUrl,
          facets: ['medium', 'inline', 'no-padding', 'small-text'],
          label: _ctx.translate('shop__overview__export')
        }, null, 8, ["export-url", "label"])
      ]),
      _createElementVNode("li", _hoisted_8, [
        _createVNode(_component_simple_button, {
          facets: ['medium', 'inline', 'no-padding', 'small-text'],
          onClick: _ctx.updateListRendering
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.renderingButtonLabel) + " ", 1),
            _createVNode(_component_icon, {
              size: "medium",
              name: _ctx.renderingButtonIcon
            }, null, 8, ["name"])
          ]),
          _: 1
        }, 8, ["onClick"])
      ])
    ])
  ]))
}