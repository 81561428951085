import { add, remove } from 'bianco.events'
import { onUnmounted } from 'vue'
import { Key } from 'w3c-keys'

export default function useKeyPress(
  key: keyof typeof Key,
  callback: (event?: KeyboardEvent) => void,
  isPersistent = false,
) {
  const onKeyPress = (event: KeyboardEvent) => {
    if (event.key === key) {
      callback(event)
    }
  }

  add(document, 'keydown', onKeyPress)

  // if this hook must be persistent, it will never be unsubscribed
  if (!isPersistent) {
    onUnmounted(() => {
      remove(document, 'keydown', onKeyPress)
    })
  }
}
