import { createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, openBlock as _openBlock, createBlock as _createBlock, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["overlay-wrapper", _ctx.rootClasses])
  }, [
    _createElementVNode("div", {
      class: "overlay-wrapper__backdrop",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeOverlay && _ctx.closeOverlay(...args)))
    }),
    _createElementVNode("div", {
      class: "overlay-wrapper__content",
      onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
    }, [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.component), _normalizeProps(_guardReactiveProps(_ctx.props)), null, 16))
    ])
  ], 2))
}