<template>
  <swiper
    :navigation="navigation"
    :pagination="pagination"
    tag="section"
    class="carousel carousel__container"
  >
    <slot name="slides"></slot>
    <div class="carousel__nav grid__row">
      <button
        type="button"
        class="carousel__nav-button carousel__nav-button--prev"
      >
        <icon size="medium" name="arrow-left" />
      </button>
      <button
        type="button"
        class="carousel__nav-button carousel__nav-button--next"
      >
        <icon size="medium" name="arrow-right" />
      </button>
    </div>
    <ul class="carousel__pagination u-reset" />
  </swiper>
</template>
<script>
  import { Swiper } from 'swiper/vue'
  import SwiperCore, { Navigation, Pagination } from 'swiper/core'
  import Icon from '../icon/icon.vue'

  SwiperCore.use([Navigation, Pagination])

  export default {
    components: {
      Icon,
      Swiper,
    },
    data() {
      return {
        navigation: {
          prevEl: '.carousel__nav-button--prev',
          nextEl: '.carousel__nav-button--next',
        },
        pagination: {
          el: '.carousel__pagination',
          type: 'bullets',
          clickable: true,
          bulletClass: 'carousel__pagination-bullet',
          bulletActiveClass: 'carousel__pagination-bullet--active',
          renderBullet: (index, className) => {
            return `<li class="${className}">${index}</li>`
          },
        },
      }
    },
  }
</script>
