
  import { computed, defineComponent, toRefs } from 'vue'
  import { getOr } from 'lodash/fp'
  import useProductList from '../../../hooks/use-product-list/use-product-list'
  import Icon from '../../common/icon/icon.vue'
  import useOverlay from '../../../hooks/use-overlay/use-overlay'
  import { OverlayIds } from '../../../constants/overlay-ids'
  import { EnhancedItem } from '../../../hooks/use-product-list/types'
  import pluralize from '../../../util/strings/pluralize'
  import SimpleButton from '../../common/buttons/simple-button/simple-button.vue'
  import Checkbox from '../../common/checkbox/checkbox.vue'
  import staticPath from '../../../util/misc/static-path'
  import DropdownFilter from './dropdown-filter.vue'
  import Tooltip from '../../common/tooltip/tooltip.vue'
  import translate from '../../../util/misc/translate'
  import labelAssetsMap from '../../../../design-tokens/label-assets-by-code.json'
  import brandAssetsMap from '../../../../design-tokens/brand-assets-by-code.json'
  import preparationAssetsMap from '../../../../design-tokens/preparation-assets-by-code.json'
  import storageConditionAssetsMap from '../../../../design-tokens/storage-condition-assets-by-code.json'
  import allergenAssetsMap from '../../../../design-tokens/allergen-assets-by-code.json'
  import { ProductFilterWithGraphic } from './type'

  export default defineComponent({
    components: {
      Tooltip,
      DropdownFilter,
      Checkbox,
      SimpleButton,
      Icon,
    },
    setup() {
      const [productListState, { updateProducts }] = useProductList()
      const { productPage, isLoading } = toRefs(productListState)

      const [, { close }] = useOverlay()
      const closeOverlay = () => {
        close(OverlayIds.PRODUCT_FILTERS)
      }

      return {
        productPage,
        isLoading,
        labelAssetsMap,
        brandAssetsMap,
        preparationAssetsMap,
        storageConditionAssetsMap,
        allergenAssetsMap,
        pluralize,
        translate,
        staticPath,
        getImageAssetByCode(code: string, map: Record<string, string>) {
          return `${getOr('placeholder', code, map)}.svg`
        },
        getIconAssetByCode(code: string, map: Record<string, string>) {
          return getOr('close', code, map)
        },
        productAmountLabel: computed(
          () =>
            `${productPage.value.totalElements} ${pluralize(
              `
                ${translate('shop__show_product')}
                ||||
                ${translate('shop__show_products')}`,
              productPage.value.totalElements,
            )}`,
        ),
        resetFilters() {
          updateProducts(productPage.value.filterResetUrl)
          closeOverlay()
        },
        getEcoboxClass(ecobox: ProductFilterWithGraphic) {
          return `product-filters__eco-box--eco-${ecobox.label[0].toLowerCase()}`
        },
        toggleFilter(url: string) {
          updateProducts(url)
        },
        getProductFilterItemClasses(filterItem: EnhancedItem) {
          return {
            'product-filters__filter-item--is-active': filterItem.isActive,
          }
        },
        closeOverlay,
      }
    },
  })
