/* eslint-disable @typescript-eslint/no-explicit-any */

export default function parseResponse<T = any>(
  response: Response,
  parsingMethod?: 'json' | 'text',
) {
  return response[parsingMethod ?? 'json']().then((data: T) =>
    response.ok ? data : Promise.reject(data),
  )
}
