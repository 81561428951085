
  import { computed, defineComponent, ref, toRefs } from 'vue'
  import { first } from 'lodash/fp'
  import InputField from '../../common/input-fields/input-field.vue'
  import SimpleButton from '../../common/buttons/simple-button/simple-button.vue'
  import translate from '../../../util/misc/translate'
  import Icon from '../../common/icon/icon.vue'
  import { InlineNotificationType } from '../../common/inline-notification/types'
  import InlineNotificationList from '../../common/inline-notification-list/inline-notification-list.vue'
  import useProductFinder from '../../../hooks/use-product-finder/use-product-finder'
  import Spinner from '../../common/spinner/spinner.vue'
  import useSnackbar, {
    SnackbarType,
  } from '../../../hooks/use-snackbar/use-snackbar'
  import useCart from '../../../hooks/use-cart/use-cart'
  import PollingExportButton from '../../common/polling-export-button/polling-export-button.vue'
  import { getProductFinderExportEndpoint } from '../../../constants/api-endpoints'

  const ALLOWED_FILES = [
    'text/csv',
    'text/plain',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ]

  export default defineComponent({
    components: {
      PollingExportButton,
      Spinner,
      InlineNotificationList,
      Icon,
      SimpleButton,
      InputField,
    },
    setup() {
      const uploadedFileName = ref('')
      const [, { show }] = useSnackbar()
      const [productFinderState, { find, reset }] = useProductFinder()
      const form = ref<HTMLFormElement>()
      const isAddingProductsToTheCart = ref<boolean>(false)
      const [, { addMultipleProducts }] = useCart()
      const textAreaValue = ref<string>('')
      const isFileDraggingActive = ref<boolean>(false)
      const { isLoading, matched, unmatched, products } =
        toRefs(productFinderState)

      const onError = (error: { error: string } & Error) => {
        show({
          id: `product-finder-error-${new Date()}`,
          text: error.error ?? error.message,
          type: SnackbarType.ERROR,
        })
      }

      return {
        uploadedFileName,
        translate,
        isLoading,
        form,
        isAddingProductsToTheCart,
        products,
        textAreaValue,
        allowedFiles: ALLOWED_FILES.join(','),
        isToolbarVisible: computed(() => products.value.length),
        fileExportUrl: getProductFinderExportEndpoint(),
        hasValuesToParse: computed(
          () => uploadedFileName.value || textAreaValue.value,
        ),
        dropzoneClasses: computed(() => ({
          'products-finder__dropzone--active': isFileDraggingActive.value,
        })),
        totalProductsAmount: computed(() =>
          products.value.reduce((acc, product) => acc + product.amount, 0),
        ),
        notifications: computed(() =>
          [
            {
              message: matched.value,
              type: InlineNotificationType.SUCCESS,
            },
            {
              message: unmatched.value,
              type: InlineNotificationType.ERROR,
            },
          ].filter(({ message }) => Boolean(message)),
        ),
        onFileDrop(event: DragEvent) {
          const firstFile = first(event?.dataTransfer?.files)
          const fileType = firstFile?.type ?? ''

          // disable the drop of files not allowed
          if (!ALLOWED_FILES.includes(fileType)) {
            event.preventDefault()

            show({
              id: `product-finder-drop-error-${new Date()}`,
              text: translate('shop__file_not_allowed'),
              type: SnackbarType.ERROR,
            })
          }

          isFileDraggingActive.value = false
        },
        onDragEnter() {
          isFileDraggingActive.value = true
        },
        onDragLeave() {
          isFileDraggingActive.value = false
        },
        onTextareaValue(event: Event) {
          textAreaValue.value = (event.target as HTMLTextAreaElement).value
        },
        onReset() {
          reset()
          uploadedFileName.value = ''
          textAreaValue.value = ''
          form.value?.reset()
        },
        addProductsToCart() {
          isAddingProductsToTheCart.value = true

          addMultipleProducts(
            products.value.map((product) => ({
              id: product.itemNumber,
              quantity: product.amount,
              unit: product.master.baseOrderingUnit ?? 'trade_unit',
            })),
          )
            .catch(onError)
            .finally(() => {
              isAddingProductsToTheCart.value = false
            })
        },
        onSubmit(event: Event) {
          reset()
          find(new FormData(event.target as HTMLFormElement)).catch(onError)
        },
        onInputFileChange(event: Event) {
          const firstFile = first((event.target as HTMLInputElement).files)

          uploadedFileName.value = firstFile?.name ?? ''
        },
      }
    },
  })
