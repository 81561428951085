
  import { computed, defineComponent, PropType, toRefs } from 'vue'
  import CategoryItem from './category-item.vue'
  import useProductList from '../../../hooks/use-product-list/use-product-list'
  import useSidePanel from '../../../hooks/use-side-panel/use-side-panel'
  import SimpleButton from '../buttons/simple-button/simple-button.vue'
  import translate from '../../../util/misc/translate'

  export default defineComponent({
    components: { SimpleButton, CategoryItem },
    props: {
      facets: {
        type: Array as PropType<string[]>,
        default: () => [],
      },
      hasFooter: {
        type: Boolean,
        default: false,
      },
    },
    setup(props) {
      const [productList, { updateProducts }] = useProductList()
      const { productPage, categories } = toRefs(productList)
      const [, { close }] = useSidePanel()
      const reset = () => updateProducts(window.location.href.split('?')[0])
      return {
        productPage,
        categories,
        close,
        translate,
        rootClasses: computed(() =>
          props.facets.map((facet) => `categories-menu--${facet}`),
        ),
        titleClasses: computed(() => ({
          'categories-menu__title--is-active':
            !productPage.value.hasActiveFilters,
        })),
        resetAndClose() {
          reset()
          close()
        },
        reset,
      }
    },
  })
