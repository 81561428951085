// Helper method send an ajax request using a json payload
export default function createRequestWithJsonPayload(
  endpoint: string,
  payload: unknown,
  method?: string,
): Promise<Response> {
  return fetch(endpoint, {
    method: method ?? 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  })
}
