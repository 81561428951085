import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, createTextVNode as _createTextVNode, createSlots as _createSlots } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "add-product-to-watchlist-overlay__checklist u-reset"
}
const _hoisted_2 = { class: "add-product-to-watchlist-overlay--checkbox-label" }
const _hoisted_3 = { class: "button-group button-group--space-between button-group--horizontal" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_spinner = _resolveComponent("spinner")!
  const _component_checkbox = _resolveComponent("checkbox")!
  const _component_input_field = _resolveComponent("input-field")!
  const _component_simple_button = _resolveComponent("simple-button")!
  const _component_standard_overlay = _resolveComponent("standard-overlay")!

  return (_openBlock(), _createBlock(_component_standard_overlay, {
    class: "add-product-to-watchlist-overlay",
    title: _ctx.translate('shop__select__watchlist__overlay'),
    id: _ctx.OverlayIds.ADD_PRODUCT_TO_WATCHLIST
  }, _createSlots({
    default: _withCtx(() => [
      (_ctx.isLoading || _ctx.isSubmitting)
        ? (_openBlock(), _createBlock(_component_spinner, {
            key: 0,
            class: "add-product-to-watchlist-overlay__loader"
          }))
        : (_openBlock(), _createElementBlock("form", {
            key: 1,
            ref: "form",
            onSubmit: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.addProduct && _ctx.addProduct(...args)), ["prevent"]))
          }, [
            (_ctx.hasWatchLists)
              ? (_openBlock(), _createElementBlock("ul", _hoisted_1, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.enabledWatchlist, (item) => {
                    return (_openBlock(), _createElementBlock("li", {
                      class: "add-product-to-watchlist-overlay__checklist-item",
                      key: item.watchlistId
                    }, [
                      _createVNode(_component_checkbox, {
                        checked: _ctx.isProductInWatchlist(),
                        class: "add-product-to-watchlist-overlay__checkbox",
                        name: "watchlist-id",
                        value: item.watchlistId
                      }, {
                        before: _withCtx(() => [
                          _createElementVNode("span", _hoisted_2, _toDisplayString(item.name), 1)
                        ]),
                        _: 2
                      }, 1032, ["checked", "value"])
                    ]))
                  }), 128))
                ]))
              : (_openBlock(), _createBlock(_component_input_field, {
                  key: 1,
                  required: "",
                  name: "name",
                  ref: "input"
                }, null, 512))
          ], 544))
    ]),
    _: 2
  }, [
    (!_ctx.isLoading)
      ? {
          name: "footer",
          fn: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_simple_button, {
                facets: ['inline', 'no-padding'],
                onClick: _ctx.close
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.translate('shop__abort__button')), 1)
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_simple_button, {
                facets: ['primary', 'round'],
                disabled: _ctx.isSubmitting,
                onClick: _ctx.addProduct
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.translate('shop__add_product__button')), 1)
                ]),
                _: 1
              }, 8, ["disabled", "onClick"])
            ])
          ]),
          key: "0"
        }
      : undefined
  ]), 1032, ["title", "id"]))
}