import { once } from 'lodash/fp'
import { readonly, ref } from 'vue'
import { UseScrollLockAPI } from './types'

const getScrollLockAPI = once((): UseScrollLockAPI => {
  const isLocked = ref(false)
  const lock = () => {
    isLocked.value = true
  }
  const unlock = () => {
    isLocked.value = false
  }

  return [isLocked, { unlock, lock }]
})

export default function useScrollLock() {
  return readonly(getScrollLockAPI())
}
