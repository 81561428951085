
  import { defineComponent, toRefs } from 'vue'
  import EmptyState from '../../../common/empty-state/empty-state.vue'
  import translate from '../../../../util/misc/translate'
  import useProductFinder from '../../../../hooks/use-product-finder/use-product-finder'
  import ProductListItem from '../../product-list/product-list-item/product-list-item.vue'
  import GenericInlineLoader from '../../../common/generic-inline-loader/generic-inline-loader.vue'

  export default defineComponent({
    components: { GenericInlineLoader, ProductListItem, EmptyState },
    setup() {
      const [productFinderState, { updateProductAmount }] = useProductFinder()
      const { products, isLoading } = toRefs(productFinderState)

      return {
        translate,
        isLoading,
        updateProductAmount,
        products,
      }
    },
  })
