import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "delivery-list" }
const _hoisted_2 = { class: "delivery-list__main-content" }
const _hoisted_3 = {
  key: 0,
  class: "delivery-list__list u-reset"
}
const _hoisted_4 = ["href"]
const _hoisted_5 = { class: "delivery-list__title u-reset" }
const _hoisted_6 = { class: "delivery-list__description u-reset" }
const _hoisted_7 = { class: "delivery-list__cta" }
const _hoisted_8 = {
  key: 2,
  class: "delivery-list__load-more"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!
  const _component_simple_button = _resolveComponent("simple-button")!
  const _component_empty_state = _resolveComponent("empty-state")!
  const _component_spinner = _resolveComponent("spinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.hasDeliveries)
        ? (_openBlock(), _createElementBlock("ul", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.deliveries, (delivery) => {
              return (_openBlock(), _createElementBlock("li", {
                key: delivery.id
              }, [
                _createElementVNode("a", {
                  href: delivery.url,
                  class: "delivery-list__link u-reset"
                }, [
                  _createElementVNode("h2", _hoisted_5, _toDisplayString(delivery.deliveryNumber), 1),
                  _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.formatDateToLocaleDateString(delivery.targetDate)), 1),
                  _createElementVNode("div", _hoisted_7, [
                    _createVNode(_component_simple_button, { facets: ['no-padding', 'inline'] }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(delivery.items.length) + " " + _toDisplayString(_ctx.pluralize(_ctx.translate('shop__products'), delivery.items.length)) + " ", 1),
                        _createVNode(_component_icon, {
                          size: "medium",
                          name: "arrow-right"
                        })
                      ]),
                      _: 2
                    }, 1024)
                  ])
                ], 8, _hoisted_4)
              ]))
            }), 128))
          ]))
        : (_openBlock(), _createBlock(_component_empty_state, {
            key: 1,
            text: _ctx.translate('shop__empty__delivery_list')
          }, null, 8, ["text"])),
      (_ctx.hasLoadMoreButton)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createVNode(_component_simple_button, {
              disabled: _ctx.isLoadingMore,
              facets: ['medium', 'inline', 'small-text'],
              onClick: _ctx.loadMore
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.translate('shop__load_more')) + " ", 1),
                (_ctx.isLoadingMore)
                  ? (_openBlock(), _createBlock(_component_spinner, { key: 0 }))
                  : (_openBlock(), _createBlock(_component_icon, {
                      key: 1,
                      name: "chevron-down",
                      size: "small"
                    }))
              ]),
              _: 1
            }, 8, ["disabled", "onClick"])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}