
  import {
    computed,
    defineComponent,
    onMounted,
    onUnmounted,
    PropType,
    ref,
    watch,
    watchEffect,
  } from 'vue'
  import { Calendar, DatePicker } from 'v-calendar'
  import SimpleButton from '../../common/buttons/simple-button/simple-button.vue'
  import useClickOutside from '../../../hooks/use-click-outside/use-click-outside'
  import Icon from '../../common/icon/icon.vue'
  import translate from '../../../util/misc/translate'
  import useCart from '../../../hooks/use-cart/use-cart'
  import {
    DeliveryDayOptions,
    ExpectedDelivery,
    ShippingOptions,
  } from '../../../types/paloma-api-checkout'
  import useSnackbar, {
    SnackbarType,
  } from '../../../hooks/use-snackbar/use-snackbar'
  import Spinner from '../../common/spinner/spinner.vue'
  import formatDateToSimpleIsoString from '../../../util/strings/format-date-to-simple-iso-string'
  import usePanelsCloseQueue from '../../../hooks/use-panels-close-queue/use-panels-close-queue'
  import globals from '../../../globals'

  export default defineComponent({
    components: {
      Spinner,
      Icon,
      SimpleButton,
      Calendar,
      DatePicker,
    },
    props: {
      validExpectedDelivery: {
        type: Object as PropType<ExpectedDelivery>,
        required: true,
      },
    },
    setup(props) {
      const [, { getShippingOptions, applyDeliverySchemes }] = useCart()
      const [, { show }] = useSnackbar()
      const panelsCloseQueue = usePanelsCloseQueue()
      const root = ref<HTMLElement>()
      const isCalendarVisible = ref<boolean>(false)
      const isLoadingShippingOptions = ref<boolean>(false)
      const isUpdatingDate = ref<boolean>(false)
      const shippingOptions = ref<ShippingOptions>()
      const calendar = ref<{
        focusDate: (
          date: Date,
          options: { transition: string },
        ) => Promise<unknown>
      }>()
      const onError = (error: Error) => {
        show({
          type: SnackbarType.ERROR,
          text: error.message,
          id: `calendar-error-${new Date()}`,
        })
      }
      const currentCartDeliveryDateAsString = computed(
        () => props.validExpectedDelivery?.deliveryArrangement?.targetDate,
      )
      const deliveryDate = computed(() =>
        currentCartDeliveryDateAsString.value
          ? new Date(currentCartDeliveryDateAsString.value)
          : new Date(),
      )
      const onShippingOptionsFetched = (data: ShippingOptions) => {
        shippingOptions.value = data

        return data
      }
      const onShippingOptionsFetchingDone = () => {
        isLoadingShippingOptions.value = false

        // focus on the active date
        const unsubscribe = watch(calendar, () => {
          if (calendar.value) {
            calendar.value.focusDate(deliveryDate.value, { transition: 'none' })
          }
          unsubscribe()
        })
      }
      const fetchShippingOptions = () => {
        isLoadingShippingOptions.value = true

        getShippingOptions()
          .then(onShippingOptionsFetched)
          .catch(onError)
          .finally(onShippingOptionsFetchingDone)
      }
      const mapDeliverableDaysOptions = (day: DeliveryDayOptions) => {
        const deliverableDate = new Date(day.targetDate as string)

        return {
          ...day,
          start: deliverableDate,
          end: deliverableDate,
        }
      }
      const filterDeliverableDaysOptions = (day: DeliveryDayOptions) =>
        day.deliverable === 'DELIVERY_SCHEMES_AVAILABLE'
      const availableDates = computed(
        () =>
          shippingOptions.value?.deliveryDayOptions
            ?.filter(filterDeliverableDaysOptions)
            .map(mapDeliverableDaysOptions) ?? [],
      )

      const toggleCalendar = (isVisible: boolean) => {
        isCalendarVisible.value = isVisible
      }
      const closeCalendar = () => toggleCalendar(false)

      watchEffect(() =>
        isCalendarVisible.value
          ? panelsCloseQueue.subscribe(closeCalendar)
          : panelsCloseQueue.unsubscribe(closeCalendar),
      )

      onUnmounted(() => panelsCloseQueue.unsubscribe(closeCalendar))

      onMounted(() => {
        useClickOutside(root.value as HTMLElement, () => toggleCalendar(false))
      })

      watchEffect(() => {
        if (isCalendarVisible.value) {
          fetchShippingOptions()
        }
      })

      return {
        root,
        isCalendarVisible,
        isUpdatingDate,
        isLoadingShippingOptions,
        availableDates,
        translate,
        deliveryDate,
        calendar,
        currentLanguage: globals.currentLanguage || 'de',
        calendarAttributes: computed(() => [
          {
            key: 'today',
            highlight: true,
            dates: deliveryDate.value,
          },
        ]),

        toggleCalendar,
        onDayClick(value: { date: Date }) {
          const deliveryDaySelected = availableDates.value.find(
            ({ end }) =>
              formatDateToSimpleIsoString(end) ===
              formatDateToSimpleIsoString(value.date),
          )
          const name = deliveryDaySelected?.deliverySchemes?.[0]?.name ?? ''

          if (!name) {
            console.error(
              `No date was found for ${formatDateToSimpleIsoString(
                value.date,
              )}`,
            )
            return
          }

          isUpdatingDate.value = true

          applyDeliverySchemes(name)
            .catch(onError)
            .finally(() => {
              isUpdatingDate.value = false
            })
        },
      }
    },
  })
