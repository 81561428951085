import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "product-filters" }
const _hoisted_2 = { class: "product-filters__header" }
const _hoisted_3 = { class: "product-filters__main-title u-reset" }
const _hoisted_4 = { class: "product-filters__body" }
const _hoisted_5 = { class: "product-filters__block product-filters__block--horizontal" }
const _hoisted_6 = { class: "product-filters__title u-reset" }
const _hoisted_7 = { class: "product-filters__filter-list product-filters__filter-list--horizontal u-reset" }
const _hoisted_8 = { class: "product-filters__block product-filters__block--horizontal" }
const _hoisted_9 = { class: "product-filters__title u-reset" }
const _hoisted_10 = { class: "product-filters__filter-list product-filters__filter-list--horizontal u-reset" }
const _hoisted_11 = { class: "product-filters__block product-filters__block--dropdown" }
const _hoisted_12 = { class: "product-filters__graphic-wrapper product-filters__graphic-wrapper--image" }
const _hoisted_13 = ["src", "alt"]
const _hoisted_14 = { class: "product-filters__block product-filters__block--dropdown" }
const _hoisted_15 = { class: "product-filters__graphic-wrapper product-filters__graphic-wrapper--image" }
const _hoisted_16 = ["src", "alt"]
const _hoisted_17 = { class: "product-filters__block product-filters__block--dropdown" }
const _hoisted_18 = { class: "product-filters__graphic-wrapper product-filters__graphic-wrapper--icon" }
const _hoisted_19 = { class: "product-filters__block product-filters__block--dropdown" }
const _hoisted_20 = { class: "product-filters__block product-filters__block--dropdown" }
const _hoisted_21 = { class: "product-filters__graphic-wrapper product-filters__graphic-wrapper--icon" }
const _hoisted_22 = { class: "product-filters__block product-filters__block--dropdown" }
const _hoisted_23 = { class: "product-filters__graphic-wrapper product-filters__graphic-wrapper--icon" }
const _hoisted_24 = { class: "product-filters__footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!
  const _component_simple_button = _resolveComponent("simple-button")!
  const _component_tooltip = _resolveComponent("tooltip")!
  const _component_checkbox = _resolveComponent("checkbox")!
  const _component_dropdown_filter = _resolveComponent("dropdown-filter")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("header", _hoisted_2, [
      _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.translate('shop__filters__show_more')), 1),
      _createVNode(_component_simple_button, {
        facets: ['bubble', 'medium', 'inline'],
        class: "product-filters__close",
        onClick: _ctx.closeOverlay
      }, {
        default: _withCtx(() => [
          _createVNode(_component_icon, {
            size: "medium",
            name: "close"
          })
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("h2", _hoisted_6, _toDisplayString(_ctx.translate('shop__filters__fresh__title')), 1),
        _createElementVNode("ul", _hoisted_7, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.productPage.filters.buttonFilters, (buttonFilter, index) => {
            return (_openBlock(), _createElementBlock("li", {
              class: "product-filters__filter-item",
              key: index
            }, [
              _createVNode(_component_simple_button, {
                facets: [
                'round',
                'primary',
                'outlined',
                buttonFilter.isActive ? 'is-active' : null,
              ],
                onClick: ($event: any) => (_ctx.toggleFilter(buttonFilter.url))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(buttonFilter.label), 1)
                ]),
                _: 2
              }, 1032, ["facets", "onClick"])
            ]))
          }), 128)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.productPage.filters.iconFilters, (iconFilter, index) => {
            return (_openBlock(), _createElementBlock("li", {
              class: "product-filters__filter-item",
              key: index
            }, [
              _createVNode(_component_simple_button, {
                title: iconFilter.label,
                facets: [
                'bubble',
                'primary',
                'outlined',
                'medium',
                iconFilter.isActive ? 'is-active' : null,
                iconFilter.code,
              ],
                onClick: ($event: any) => (_ctx.toggleFilter(iconFilter.url))
              }, {
                default: _withCtx(() => [
                  (iconFilter.code === 'badge_red')
                    ? (_openBlock(), _createBlock(_component_icon, {
                        key: 0,
                        name: "filter-no-delivery",
                        size: "medium"
                      }))
                    : _createCommentVNode("", true),
                  (iconFilter.code === 'badge_green')
                    ? (_openBlock(), _createBlock(_component_icon, {
                        key: 1,
                        name: "filter-delivery",
                        size: "medium"
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1032, ["title", "facets", "onClick"])
            ]))
          }), 128))
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("h2", _hoisted_9, [
          _createTextVNode(_toDisplayString(_ctx.translate('shop__filters__eco_score__title')) + " ", 1),
          _createVNode(_component_tooltip, {
            text: _ctx.translate('shop__filters__eco_score__tooltip'),
            class: "product-filters__title-icon"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_simple_button, { facets: ['bubble', 'tertiary', 'medium'] }, {
                default: _withCtx(() => [
                  _createVNode(_component_icon, {
                    name: "info-plus",
                    size: "small"
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["text"])
        ]),
        _createElementVNode("ul", _hoisted_10, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.productPage.filters.ecoCheckboxes, (ecoCheckbox, index) => {
            return (_openBlock(), _createElementBlock("li", {
              class: "product-filters__filter-item product-filters__filter-item--checkbox",
              key: index
            }, [
              _createVNode(_component_checkbox, {
                checked: ecoCheckbox.isActive,
                onChange: ($event: any) => (_ctx.toggleFilter(ecoCheckbox.url))
              }, {
                before: _withCtx(() => [
                  _createElementVNode("span", {
                    class: _normalizeClass(["product-filters__eco-box", _ctx.getEcoboxClass(ecoCheckbox)])
                  }, null, 2)
                ]),
                default: _withCtx(() => [
                  _createTextVNode(" " + _toDisplayString(ecoCheckbox.label), 1)
                ]),
                _: 2
              }, 1032, ["checked", "onChange"])
            ]))
          }), 128))
        ])
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createVNode(_component_dropdown_filter, {
          items: _ctx.productPage.filters.labels,
          title: _ctx.translate('shop__filters__labels__title'),
          onChange: _ctx.toggleFilter
        }, {
          "before-checkbox": _withCtx(({ code, label }) => [
            _createElementVNode("figure", _hoisted_12, [
              _createElementVNode("img", {
                class: "product-filters__graphic",
                src: 
                  _ctx.staticPath(
                    `img/labels/${_ctx.getImageAssetByCode(code, _ctx.labelAssetsMap)}`,
                  )
                ,
                alt: label
              }, null, 8, _hoisted_13)
            ])
          ]),
          _: 1
        }, 8, ["items", "title", "onChange"])
      ]),
      _createElementVNode("div", _hoisted_14, [
        _createVNode(_component_dropdown_filter, {
          items: _ctx.productPage.filters.brands,
          title: _ctx.translate('shop__filters__brands__title'),
          onChange: _ctx.toggleFilter
        }, {
          "before-checkbox": _withCtx(({ code, label }) => [
            _createElementVNode("figure", _hoisted_15, [
              _createElementVNode("img", {
                class: "product-filters__graphic",
                src: 
                  _ctx.staticPath(
                    `img/brands/${_ctx.getImageAssetByCode(code, _ctx.brandAssetsMap)}`,
                  )
                ,
                alt: label
              }, null, 8, _hoisted_16)
            ])
          ]),
          _: 1
        }, 8, ["items", "title", "onChange"])
      ]),
      _createElementVNode("div", _hoisted_17, [
        _createVNode(_component_dropdown_filter, {
          items: _ctx.productPage.filters.preparation,
          title: _ctx.translate('shop__filters__preparation__title'),
          onChange: _ctx.toggleFilter
        }, {
          "before-checkbox": _withCtx(({ code }) => [
            _createElementVNode("figure", _hoisted_18, [
              _createVNode(_component_icon, {
                name: _ctx.getIconAssetByCode(code, _ctx.preparationAssetsMap),
                size: "medium"
              }, null, 8, ["name"])
            ])
          ]),
          _: 1
        }, 8, ["items", "title", "onChange"])
      ]),
      _createElementVNode("div", _hoisted_19, [
        _createVNode(_component_dropdown_filter, {
          items: _ctx.productPage.filters.fermentation,
          title: _ctx.translate('shop__filters__fermentation__title'),
          onChange: _ctx.toggleFilter
        }, null, 8, ["items", "title", "onChange"])
      ]),
      _createElementVNode("div", _hoisted_20, [
        _createVNode(_component_dropdown_filter, {
          facets: ['vertical'],
          items: _ctx.productPage.filters.storageConditions,
          title: _ctx.translate('shop__filters__storage_condition__title'),
          onChange: _ctx.toggleFilter
        }, {
          "before-checkbox": _withCtx(({ code }) => [
            _createElementVNode("figure", _hoisted_21, [
              _createVNode(_component_icon, {
                name: _ctx.getIconAssetByCode(code, _ctx.storageConditionAssetsMap),
                size: "medium"
              }, null, 8, ["name"])
            ])
          ]),
          _: 1
        }, 8, ["items", "title", "onChange"])
      ]),
      _createElementVNode("div", _hoisted_22, [
        _createVNode(_component_dropdown_filter, {
          items: _ctx.productPage.filters.allergens,
          title: _ctx.translate('shop__filters__allergenes__title'),
          onChange: _ctx.toggleFilter
        }, {
          "before-checkbox": _withCtx(({ code }) => [
            _createElementVNode("figure", _hoisted_23, [
              _createVNode(_component_icon, {
                name: _ctx.getIconAssetByCode(code, _ctx.allergenAssetsMap),
                size: "medium"
              }, null, 8, ["name"])
            ])
          ]),
          _: 1
        }, 8, ["items", "title", "onChange"])
      ])
    ]),
    _createElementVNode("footer", _hoisted_24, [
      _createVNode(_component_simple_button, {
        facets: ['link', 'no-padding'],
        disabled: !_ctx.productPage.hasActiveFilters || _ctx.isLoading,
        onClick: _ctx.resetFilters
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.translate('shop__filters__reset')), 1)
        ]),
        _: 1
      }, 8, ["disabled", "onClick"]),
      _createVNode(_component_simple_button, {
        disabled: _ctx.isLoading,
        facets: ['primary', 'round'],
        onClick: _ctx.closeOverlay
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.productAmountLabel), 1)
        ]),
        _: 1
      }, 8, ["disabled", "onClick"])
    ])
  ]))
}