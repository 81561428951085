
  import {
    defineComponent,
    onMounted,
    onUnmounted,
    nextTick,
    computed,
    PropType,
  } from 'vue'
  import useOverlay from '../../../hooks/use-overlay/use-overlay'
  import useScrollLock from '../../../hooks/use-scroll-lock/use-scroll-lock'
  import usePanelsCloseQueue from '../../../hooks/use-panels-close-queue/use-panels-close-queue'

  export default defineComponent({
    props: {
      id: {
        type: String,
        required: true,
      },
      props: {
        type: Object,
        required: true,
      },
      component: {
        type: Object,
        required: true,
      },
      facets: {
        type: Array as PropType<string[]>,
        default: () => [],
      },
    },
    setup(props) {
      const [, { close }] = useOverlay()
      const [, { lock, unlock }] = useScrollLock()
      const panelsCloseQueue = usePanelsCloseQueue()
      const closeOverlay = () => {
        close(props.id)
      }

      // notice the use of nextTick here to proper trigger the watchEffect events in other views
      onMounted(() => {
        panelsCloseQueue.subscribe(closeOverlay)
        nextTick(() => lock())
      })
      onUnmounted(() => {
        panelsCloseQueue.unsubscribe(closeOverlay)
        nextTick(() => unlock())
      })

      return {
        closeOverlay,
        rootClasses: computed(() =>
          props.facets.map((facet) => `overlay-wrapper--${facet}`),
        ),
      }
    },
  })
