import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_spinner = _resolveComponent("spinner")!
  const _component_icon = _resolveComponent("icon")!
  const _component_simple_button = _resolveComponent("simple-button")!

  return (_openBlock(), _createBlock(_component_simple_button, {
    disabled: _ctx.isLoading,
    facets: _ctx.facets,
    "aria-label": _ctx.translate('shop__product__add_cart'),
    onClick: _ctx.add
  }, {
    default: _withCtx(() => [
      (_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_spinner, { key: 0 }))
        : (_openBlock(), _createBlock(_component_icon, {
            key: 1,
            name: "cart",
            size: "medium"
          }))
    ]),
    _: 1
  }, 8, ["disabled", "facets", "aria-label", "onClick"]))
}