import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "side-panel__backdrop-wrap"
}
const _hoisted_2 = {
  key: 0,
  class: "side-panel__content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["side-panel", _ctx.rootClasses])
  }, [
    _createVNode(_Transition, { name: "trs-simple-fade" }, {
      default: _withCtx(() => [
        (_ctx.panel)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("div", {
                class: "side-panel__backdrop",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
              })
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, { name: _ctx.transition }, {
      default: _withCtx(() => [
        (_ctx.isPanelVisible)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.panel.component), _normalizeProps(_guardReactiveProps(_ctx.panel.props)), null, 16))
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["name"])
  ], 2))
}