import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("details", {
    ref: "root",
    onToggle: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onToggle && _ctx.onToggle(...args)))
  }, [
    _createElementVNode("summary", null, [
      _renderSlot(_ctx.$slots, "trigger", { isOpen: _ctx.isOpen })
    ]),
    _createVNode(_Transition, { name: "trs-simple-fade" }, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("div", null, [
          _renderSlot(_ctx.$slots, "content", { isOpen: _ctx.isOpen })
        ], 512), [
          [_vShow, _ctx.isOpen]
        ])
      ]),
      _: 3
    })
  ], 544))
}