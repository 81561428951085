import { once } from 'lodash/fp'
import { ref, readonly, markRaw, nextTick } from 'vue'
import { SidePanel, UseSidePanelAPI } from './types'

export enum SidePanelPosition {
  LEFT = 'left',
  RIGHT = 'right',
}

const getSidePanelAPI = once((): UseSidePanelAPI => {
  const panel = ref<SidePanel | null>(null)
  const position = ref<SidePanelPosition>(SidePanelPosition.LEFT)

  function open<T extends Record<string, unknown>>(
    newPanel: SidePanel<T>,
    newPosition: SidePanelPosition,
  ) {
    position.value = newPosition

    nextTick(() => {
      panel.value = markRaw(newPanel)
    })
  }

  function close() {
    panel.value = null
  }

  return [
    {
      position,
      panel,
    },
    { open, close },
  ]
})

export default function useSidePanel() {
  return readonly(getSidePanelAPI())
}
