
  import { defineComponent, watch } from 'vue'
  import useScrollLock from '../../../hooks/use-scroll-lock/use-scroll-lock'

  export default defineComponent({
    props: {
      selector: {
        type: String,
        required: true,
      },
      activeClass: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      const [isLocked] = useScrollLock()
      const scrollRoot = document.querySelector(props.selector)

      watch(isLocked, () =>
        scrollRoot?.classList.toggle(props.activeClass, isLocked.value),
      )

      return {}
    },
    render() {
      return null
    },
  })
