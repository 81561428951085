import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "button-group button-group--space-between button-group--horizontal" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_simple_button = _resolveComponent("simple-button")!
  const _component_standard_overlay = _resolveComponent("standard-overlay")!

  return (_openBlock(), _createBlock(_component_standard_overlay, {
    title: _ctx.translate('shop__clear__cart__overlay__title'),
    id: _ctx.OverlayIds.CLEAR_CART
  }, {
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_simple_button, {
          facets: ['inline', 'no-padding'],
          onClick: _ctx.close
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.translate('shop__abort__button')), 1)
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_simple_button, {
          facets: ['error-background', 'round'],
          disabled: _ctx.isLoading,
          onClick: _ctx.onClearCartClick
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.translate('shop__clear__cart__button')), 1)
          ]),
          _: 1
        }, 8, ["disabled", "onClick"])
      ])
    ]),
    _: 1
  }, 8, ["title", "id"]))
}