
  import { defineComponent, PropType } from 'vue'
  import SimpleButton from '../buttons/simple-button/simple-button.vue'
  import Icon from '../icon/icon.vue'
  import translate from '../../../util/misc/translate'
  import { createAddProductToWatchlistOverlay } from '../../../util/misc/watchlist-overlay-factories'
  import useOverlay from '../../../hooks/use-overlay/use-overlay'

  export default defineComponent({
    components: { Icon, SimpleButton },
    props: {
      productId: {
        type: String,
        required: true,
      },
      unit: {
        type: String,
        required: true,
      },
      facets: {
        type: Array as PropType<string[]>,
        default: () => ['bubble', 'medium', 'tertiary'],
      },
    },
    setup(props) {
      const [, { open }] = useOverlay()

      return {
        add: () =>
          open(createAddProductToWatchlistOverlay(props.productId, props.unit)),
        translate,
      }
    },
  })
