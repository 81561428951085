import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "accordion" }
const _hoisted_2 = { class: "accordion__title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!
  const _component_simple_button = _resolveComponent("simple-button")!
  const _component_dropdown = _resolveComponent("dropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_dropdown, {
      "has-keyboard-bindings": false,
      "has-click-outside": false
    }, {
      trigger: _withCtx(({ isOpen }: { isOpen: boolean }) => [
        _createElementVNode("div", {
          class: _normalizeClass(["accordion__trigger", { 'accordion__trigger--is-open': isOpen }])
        }, [
          _createVNode(_component_simple_button, {
            tag: "div",
            class: "accordion__button",
            facets: ['lite', 'bubble', 'small']
          }, {
            default: _withCtx(() => [
              _createVNode(_component_icon, {
                size: "small",
                name: "chevron-right"
              })
            ]),
            _: 1
          }),
          _createElementVNode("span", _hoisted_2, [
            _renderSlot(_ctx.$slots, "title")
          ])
        ], 2)
      ]),
      content: _withCtx(({ isOpen }: { isOpen: boolean }) => [
        _createElementVNode("div", {
          class: _normalizeClass(["accordion__content", { 'accordion__content--is-open': isOpen }])
        }, [
          _renderSlot(_ctx.$slots, "content")
        ], 2)
      ]),
      _: 3
    })
  ]))
}