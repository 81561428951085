import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "button-group button-group--space-between button-group--horizontal" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_input_field = _resolveComponent("input-field")!
  const _component_simple_button = _resolveComponent("simple-button")!
  const _component_standard_overlay = _resolveComponent("standard-overlay")!

  return (_openBlock(), _createBlock(_component_standard_overlay, {
    title: _ctx.translate('shop__rename__watchlist__overlay', _ctx.watchlist),
    id: _ctx.OverlayIds.RENAME_WATCHLIST
  }, {
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_simple_button, {
          facets: ['inline', 'no-padding'],
          onClick: _ctx.close
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.translate('shop__abort__button')), 1)
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_simple_button, {
          facets: ['primary', 'round'],
          disabled: _ctx.isLoading,
          onClick: _ctx.renameWatchlist
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.translate('shop__create__button')), 1)
          ]),
          _: 1
        }, 8, ["disabled", "onClick"])
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("form", {
        ref: "form",
        onSubmit: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.renameWatchlist && _ctx.renameWatchlist(...args)), ["prevent"]))
      }, [
        _createVNode(_component_input_field, {
          required: "",
          name: "name",
          value: _ctx.watchlist.name,
          ref: "input"
        }, null, 8, ["value"])
      ], 544)
    ]),
    _: 1
  }, 8, ["title", "id"]))
}