
  import { computed, defineComponent, PropType, ref } from 'vue'
  import compareAsc from 'date-fns/compareAsc'
  import SimpleButton from '../../common/buttons/simple-button/simple-button.vue'
  import Icon from '../../common/icon/icon.vue'
  import translate from '../../../util/misc/translate'
  import Spinner from '../../common/spinner/spinner.vue'
  import useSnackbar, {
    SnackbarType,
  } from '../../../hooks/use-snackbar/use-snackbar'
  import useCart from '../../../hooks/use-cart/use-cart'
  import {
    CustomersOrder,
    OrderItem,
  } from '../../../types/paloma-api-customers'
  import pluralize from '../../../util/strings/pluralize'
  import ProductListItemLite from '../product-list/product-list-item/product-list-item-lite.vue'
  import formatDateToLocaleDateString from '../../../util/strings/format-date-to-locale-date-string'

  interface OrderBatch {
    targetDate: string | undefined
    comment?: string
    items: OrderItem[]
  }

  export default defineComponent({
    components: { ProductListItemLite, Spinner, Icon, SimpleButton },
    props: {
      order: {
        type: Object as PropType<CustomersOrder & { exportUrl: string }>,
        required: true,
      },
    },
    setup(props) {
      const [, { show }] = useSnackbar()
      const [, { addMultipleProducts }] = useCart()
      const isAddingItemsToCart = ref<boolean>(false)

      const onError = (error: Error) => {
        show({
          id: `order-detail-error-${new Date()}`,
          text: error.message,
          type: SnackbarType.ERROR,
        })
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const getQuantityDescription = (item: any) => {
        return item.unitCode === 'consumer_unit'
          ? item?.customData?.cu_quantity_description
          : item?.customData?.tu_quantity_description
      }

      return {
        translate,
        pluralize,
        isAddingItemsToCart,
        getQuantityDescription,
        formatDateToLocaleDateString,
        deliveryBatches: computed(() => {
          if (props.order.shipping.targetDate) {
            return [
              {
                targetDate: props.order.shipping.targetDate,
                items: props.order.items,
                comment: props.order.comment,
              },
            ]
          }

          const reducedBatches = props.order.items
            ? props.order.items.reduce((acc, currentItem) => {
                const index = acc.findIndex(
                  (item) => item.targetDate === currentItem.deliveryTargetDate,
                )
                if (index >= 0) {
                  acc[index].items.push(currentItem)
                  return acc
                }
                acc.push({
                  targetDate: currentItem.deliveryTargetDate,
                  items: [currentItem],
                })

                return acc
              }, [] as OrderBatch[])
            : []

          if (reducedBatches.length >= 0) {
            reducedBatches[0].comment = props.order.comment
          }

          return reducedBatches.sort((a, b) => {
            return compareAsc(
              new Date(a.targetDate ? a.targetDate : ''),
              new Date(b.targetDate ? b.targetDate : ''),
            )
          })
        }),
        productsAmount: computed(
          () =>
            props?.order?.items?.reduce<number>(
              (acc, item) => acc + (item.quantity as number),
              0,
            ) ?? 0,
        ),
        onAddItemsToCart() {
          // fix API inconsistencies, why should the order items be undefined?!
          if (!props.order.items) {
            return
          }

          const onDone = () => {
            isAddingItemsToCart.value = false
          }

          isAddingItemsToCart.value = true

          addMultipleProducts(
            props.order.items.map((item) => {
              return {
                id: item.sku as string,
                quantity: item.quantity as number,
                unit: item.unitCode || 'trade_unit',
              }
            }),
          )
            .catch(onError)
            .finally(onDone)
        },
      }
    },
  })
