import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!
  const _component_simple_button = _resolveComponent("simple-button")!

  return (_openBlock(), _createBlock(_component_simple_button, {
    facets: _ctx.facets,
    "aria-label": _ctx.translate('shop__product__add_list'),
    onClick: _ctx.add
  }, {
    default: _withCtx(() => [
      _createVNode(_component_icon, {
        name: "star",
        size: "medium"
      })
    ]),
    _: 1
  }, 8, ["facets", "aria-label", "onClick"]))
}