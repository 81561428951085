
  import { defineComponent } from 'vue'
  import SimpleButton from '../../buttons/simple-button/simple-button.vue'
  import Icon from '../../icon/icon.vue'
  import useOverlay from '../../../../hooks/use-overlay/use-overlay'

  export default defineComponent({
    components: { Icon, SimpleButton },
    props: {
      title: {
        type: String,
        required: true,
      },
      id: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      const [, { close }] = useOverlay()

      return {
        close: () => close(props.id),
      }
    },
  })
