import { Ref, unref } from 'vue'
import { debounce } from 'lodash/fp'
import {
  EnhancedProductPage,
  ProductFetchRequestObject,
  ProductListAPIPayload,
  ProductOverviewAPIPayload,
} from './types'
import {
  getProductsEndpoint,
  getProductsOverviewEndpoint,
} from '../../constants/api-endpoints'
import isANewQuery from '../../util/misc/is-a-new-query'
import parseResponse from '../../util/misc/parse-response'

const REQUEST_DEBOUNCE_DELAY = 20
const debouncedStateUpdate = debounce(REQUEST_DEBOUNCE_DELAY)

export const PAGE_QUERY_PARAM = 'p'
export const PRODUCT_LIST_RENDERING_PARAM = 'rendering'

export const createProductsFetchRequest = <Payload>(
  APIEndpoint: string,
  page?: number,
): ProductFetchRequestObject<Payload> => {
  const controller = new AbortController()
  const params = new URLSearchParams(window.location.search)
  const { signal } = controller

  // add the page param if it's needed
  if (page) {
    params.set(PAGE_QUERY_PARAM, String(page))
  }

  return {
    fetch: () =>
      fetch(`${APIEndpoint}?${params}`, {
        signal,
      }).then((res) => parseResponse<Payload>(res)),
    controller,
  }
}

export const createUpdateProductsCallback = ({
  onBeforeFetch,
  onSuccess,
  onError,
  onDone,
  abortController,
}: {
  onBeforeFetch: () => void
  onSuccess: (data: ProductOverviewAPIPayload) => void
  onError: (error: Error) => void
  onDone: () => void
  abortController: Ref<AbortController | null>
}) => {
  return debouncedStateUpdate((url?: string) => {
    // if the url query didn't change we skip this update
    if (!isANewQuery(url)) {
      return
    }

    // update the history if a url string will be provided
    if (url) {
      window.history.pushState({}, '', url)
    }

    // depending on the current window.location.search
    // we send the proper fetch request to the server providing
    // the custom search get params
    const { fetch, controller } =
      createProductsFetchRequest<ProductOverviewAPIPayload>(
        getProductsOverviewEndpoint(),
      )

    onBeforeFetch()
    abortController.value = controller

    fetch().then(onSuccess).catch(onError).finally(onDone)
  })
}

export const createLoadMoreCallback = ({
  currentPage,
  productPage,
  isLoadingMore,
}: {
  currentPage: Ref<number>
  productPage: Ref<EnhancedProductPage>
  isLoadingMore: Ref<boolean>
}) => {
  return debouncedStateUpdate(() => {
    const { fetch, controller } =
      createProductsFetchRequest<ProductListAPIPayload>(
        getProductsEndpoint(),
        // increment the current page number
        (currentPage.value += 1),
      )

    isLoadingMore.value = true

    const request = fetch()

    // add the products fetched to the current product page
    request
      .then((data) => {
        const oldProductPage = unref(productPage)
        const newProducts = data.productPage.content

        productPage.value = {
          ...oldProductPage,
          content: [...oldProductPage.content, ...newProducts],
        }
      })
      .finally(() => {
        isLoadingMore.value = false
      })

    return [request, controller]
  })
}
