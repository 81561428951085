import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, createBlock as _createBlock, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "input__wrap" }
const _hoisted_2 = {
  key: 0,
  class: "input__label"
}
const _hoisted_3 = { class: "input__field-box" }
const _hoisted_4 = { class: "input__field-wrap" }
const _hoisted_5 = {
  key: 0,
  class: "input__messages"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["input", _ctx.rootClasses])
  }, [
    _createElementVNode("label", _hoisted_1, [
      (_ctx.label)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.label), 1))
        : _createCommentVNode("", true),
      _createElementVNode("span", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.tag), _mergeProps({ class: "input__field" }, _ctx.$attrs), null, 16))
        ]),
        (_ctx.$slots.messages)
          ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
              _renderSlot(_ctx.$slots, "messages")
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ], 2))
}