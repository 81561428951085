
  import { computed, defineComponent, PropType, ref, toRefs, watch } from 'vue'
  import { noop } from 'lodash/fp'
  import { loadImage } from 'bianco.images-loader'
  import useIsVisible from '../../../../hooks/use-is-visible/use-is-visible'
  import staticPath from '../../../../util/misc/static-path'
  import ProductCounter from '../../../common/product-counter/product-counter.vue'
  import translate from '../../../../util/misc/translate'
  import { ProductImageIds } from '../../../../constants/product-image-ids'
  import getDevicePixelRatio from '../../../../util/misc/get-device-pixel-ratio'
  import useUser from '../../../../hooks/use-user/use-user'
  import ProductWatchlistButton from '../../../common/product-watchlist-button/product-watchlist-button.vue'
  import AddToCartButton from '../../../common/add-to-cart-button/add-to-cart-button.vue'
  import { ProductImageSource } from '../../../../types/paloma-api-catalog'
  import Icon from '../../../common/icon/icon.vue'
  import getProductImageById from '../../../../util/misc/get-product-image-by-id'

  type Price = {
    priceType: string
    currency: string
    unitPrice: string
    unit: string
  }

  export default defineComponent({
    components: {
      Icon,
      AddToCartButton,
      ProductWatchlistButton,
      ProductCounter,
    },
    props: {
      title: {
        type: String,
        required: true,
      },
      productUrl: {
        type: String,
        default: '',
      },
      currency: {
        type: String,
        default: null,
      },
      quantity: {
        type: Number,
        default: 1,
      },
      unit: {
        type: String,
        default: '',
      },
      unitCode: {
        type: String,
        default: 'trade_unit',
      },
      features: {
        type: Array as PropType<
          {
            url?: string
            title?: string
            text?: string
            icon?: { name: string; size: string }
          }[]
        >,
        default: () => [],
      },
      cuPrice: {
        type: Object as PropType<Price>,
        default: null,
      },
      price: {
        type: Object as PropType<Price>,
        default: null,
      },
      quantityDescription: {
        type: String,
        default: '',
      },
      priceSuffix: {
        type: String,
        default: '',
      },
      productNumber: {
        type: String,
        default: '',
      },
      batchNumber: {
        type: String,
        default: '',
      },
      expirationDate: {
        type: String,
        default: '',
      },
      isCounterLoading: {
        type: Boolean,
        default: false,
      },
      isCounterEnabled: {
        type: Boolean,
        default: false,
      },
      imageSources: {
        type: Array as PropType<ProductImageSource[]>,
        required: true,
      },
      showCounter: {
        type: Boolean,
        default: false,
      },
      onQuantityUpdated: {
        type: Function as PropType<(quantity: number) => void>,
        default: noop,
      },
      productId: {
        type: String,
        required: true,
      },
      totalPrice: {
        type: String,
        default: null,
      },
    },
    setup(props) {
      const root = ref(null)
      const isImageLoaded = ref(false)
      const { isVisible } = toRefs(useIsVisible(root))
      const userState = useUser()
      const getListingImages = (): [string, string] | null => {
        return props.imageSources
          ? [
              getProductImageById(ProductImageIds.LISTING, props.imageSources)
                .url,
              getProductImageById(
                ProductImageIds.LISTING_X2,
                props.imageSources,
              ).url,
            ]
          : null
      }
      const imageUrl = computed<string | null>(() => {
        const listingImages = getListingImages()

        if (!listingImages) {
          return null
        }

        return getDevicePixelRatio() > 1 ? listingImages[0] : listingImages[1]
      })
      const loadProductImage = () => {
        if (imageUrl.value) {
          loadImage(imageUrl.value).then(() => {
            isImageLoaded.value = true
          })
        }
      }

      watch(isVisible, loadProductImage)

      return {
        root,
        translate,
        staticPath,
        user: userState,
        isReady: computed(() => {
          return isVisible.value && [!imageUrl.value || isImageLoaded.value]
        }),
        imageUrl,
      }
    },
  })
