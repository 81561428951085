import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createBlock as _createBlock, TransitionGroup as _TransitionGroup, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "overlay-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_overlay_wrapper = _resolveComponent("overlay-wrapper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_TransitionGroup, { name: "trs-simple-fade" }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.overlays, (overlay) => {
          return (_openBlock(), _createBlock(_component_overlay_wrapper, _mergeProps(overlay, {
            key: overlay.id
          }), null, 16))
        }), 128))
      ]),
      _: 1
    })
  ]))
}