import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "cart-panel__product-figure" }
const _hoisted_2 = ["src", "srcset"]
const _hoisted_3 = { class: "cart-panel__product-content" }
const _hoisted_4 = { class: "cart-panel__product-info" }
const _hoisted_5 = {
  key: 0,
  class: "cart-panel__product-date"
}
const _hoisted_6 = { class: "cart-panel__product-name u-reset" }
const _hoisted_7 = { class: "cart-panel__product-meta u-reset" }
const _hoisted_8 = { class: "cart-panel__product-price u-reset" }
const _hoisted_9 = {
  key: 0,
  class: "cart-panel__product-minimum-quantity u-reset"
}
const _hoisted_10 = { class: "cart-panel__product-buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!
  const _component_spinner = _resolveComponent("spinner")!
  const _component_simple_button = _resolveComponent("simple-button")!
  const _component_product_counter = _resolveComponent("product-counter")!

  return (_openBlock(), _createElementBlock("li", null, [
    _createElementVNode("figure", _hoisted_1, [
      (_ctx.hasDeliveryIcon)
        ? (_openBlock(), _createBlock(_component_icon, {
            key: 0,
            class: "cart-panel__product-delivery-icon",
            name: "delivery",
            size: "medium"
          }))
        : _createCommentVNode("", true),
      _createElementVNode("img", {
        width: "82",
        class: "cart-panel__product-image",
        src: _ctx.images[0],
        srcset: `${_ctx.images[0]} 1x, ${_ctx.images[1]} 2x`,
        alt: "A product"
      }, null, 8, _hoisted_2)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        (_ctx.formattedDate)
          ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.formattedDate), 1))
          : _createCommentVNode("", true),
        _createElementVNode("h3", _hoisted_6, _toDisplayString(_ctx.item.title), 1),
        _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.item.sku) + " | " + _toDisplayString(_ctx.item.unitPricing.netPriceFormatted) + " / " + _toDisplayString(_ctx.item.unit), 1),
        _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.item.itemPricing.currency) + " " + _toDisplayString(_ctx.item.itemPricing.netPriceFormatted), 1)
      ]),
      (_ctx.isGuessedMinimumQuantityVisible)
        ? (_openBlock(), _createElementBlock("p", _hoisted_9, _toDisplayString(_ctx.translate('shop__cart__minimum_quantity', {
            minimumQuantity: _ctx.guessedMinimumQuantity,
          })), 1))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_10, [
        (!_ctx.isDisabled)
          ? (_openBlock(), _createBlock(_component_simple_button, {
              key: 0,
              disabled: _ctx.isDeleting,
              facets: ['inline', 'bubble', 'no-padding'],
              onClick: _ctx.onDelete
            }, {
              default: _withCtx(() => [
                (_ctx.isDeleting)
                  ? (_openBlock(), _createBlock(_component_spinner, { key: 0 }))
                  : (_openBlock(), _createBlock(_component_icon, {
                      key: 1,
                      size: "medium",
                      name: "trash"
                    }))
              ]),
              _: 1
            }, 8, ["disabled", "onClick"]))
          : _createCommentVNode("", true),
        _createVNode(_component_product_counter, {
          "on-change": _ctx.onQuantityChange,
          facets: ['horizontal'],
          "quantity-increment": _ctx.guessedQuantityIncrement,
          value: _ctx.item.quantity,
          "is-loading": _ctx.isUpdating,
          "is-enabled": !_ctx.isDisabled
        }, null, 8, ["on-change", "quantity-increment", "value", "is-loading", "is-enabled"])
      ])
    ])
  ]))
}