import { readonly, ref } from 'vue'
import { isEmpty } from 'lodash/fp'
import { getSearchEndpoint } from '../../constants/api-endpoints'
import { EnhancedSearchSuggestions, UseProductsSearchAPI } from './types'
import parseResponse from '../../util/misc/parse-response'

function getUseProductsSearchAPI(): UseProductsSearchAPI {
  const results = ref<EnhancedSearchSuggestions>({
    products: [],
  })
  const abortController = ref<AbortController | null>(null)
  const reset = () => {
    results.value = {
      products: [],
    }
  }
  const abort = () => {
    // abort the previous request
    if (abortController.value) {
      abortController.value?.abort()
    }
  }
  const search = (query: string) => {
    abort()
    // set up a new abort controller
    abortController.value = new AbortController()

    switch (true) {
      case isEmpty(query):
        reset()
        return Promise.resolve(results.value)
      default:
        return fetch(getSearchEndpoint(query), {
          signal: abortController.value?.signal,
        })
          .then((res) =>
            parseResponse<{ searchSuggestions: EnhancedSearchSuggestions }>(
              res,
            ),
          )
          .then((data) => {
            results.value = data.searchSuggestions

            return results.value
          })
    }
  }

  return [
    { results, abortController },
    { search, reset },
  ]
}

export default function useProductsSearch() {
  return readonly(getUseProductsSearchAPI())
}
