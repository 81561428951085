import { once } from 'lodash/fp'
import { ref, readonly, Ref } from 'vue'

const getMenuState = once((): [Ref<boolean>, (isOpen: boolean) => void] => {
  const isOpen = ref(false)

  return [
    isOpen,
    (newOpenState: boolean) => {
      isOpen.value = newOpenState
    },
  ]
})

export default function useMobileMenu() {
  return readonly(getMenuState())
}
