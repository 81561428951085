import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "inline-notification-list u-reset" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_notification = _resolveComponent("inline-notification")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notifications, (notification, index) => {
      return (_openBlock(), _createElementBlock("li", { key: index }, [
        _createVNode(_component_inline_notification, _normalizeProps(_guardReactiveProps(notification)), null, 16)
      ]))
    }), 128))
  ]))
}