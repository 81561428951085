<template>
  <section class="article-feed article-page-teaser-plugin grid__row">
    <div class="grid__content-row" v-if="!fixedTags">
      <h2 class="article-feed__tag-title u-reset">
        <slot name="title"></slot>
      </h2>
      <ul class="article-feed__tag-list u-reset" v-if="!fixedTags">
        <li
          class="article-feed__tag"
          :class="{
            'article-feed__tag--active': activeTags.includes(tag.slug),
          }"
          v-for="tag in tags"
          :key="tag.slug"
          @click="toggleFilter(tag.slug)"
        >
          {{ tag.title }}
        </li>
      </ul>
    </div>
    <ul
      class="article-feed__teaser-block article-page-teaser-plugin__teaser-list u-reset"
      v-for="(block, blockIdx) in teaserBlocks"
      :key="blockIdx"
    >
      <li
        class="article-page-teaser-plugin__teaser"
        v-for="(item, itemIdx) in block"
        :key="itemIdx"
      >
        <a
          class="article-page-teaser-plugin__teaser-link teaser-arrow image-zoom u-reset"
          :href="item.url || (host && `${host}${item.slug}`)"
          :title="item.title"
        >
          <img
            class="article-page-teaser-plugin__teaser-image image-zoom__image"
            :srcset="`${item.image.urls.default[0]} 1x,
                  ${item.image.urls.default[1]} 2x`"
            :alt="item.image.alt"
            :title="item.title"
          />
          <span class="article-page-teaser-plugin__teaser-copy-box">
            <span class="article-page-teaser-plugin__teaser-copy">
              <h3 class="article-page-teaser-plugin__teaser-title u-reset">
                {{ item.title }}
              </h3>
              <p class="article-page-teaser-plugin__teaser-text u-reset">
                {{ item.text }}
              </p>
              <span class="teaser-arrow__display"></span>
            </span>
          </span>
        </a>
      </li>
    </ul>
    <div class="article-feed__actions">
      <slot
        name="load-more-button"
        :load-more="loadMore"
        v-if="currentPage < numPages"
      ></slot>
    </div>
  </section>
</template>

<script>
  const prepareTeaserBlocks = (items) => {
    const stash = [...items]
    const blocks = []
    // eslint-disable-next-line
    let i = 3
    // eslint-disable-next-line
    while (stash.length) {
      // eslint-disable-next-line
      let take = Math.min((i % 4) + 1, stash.length)
      const block = []
      // eslint-disable-next-line
      while (take--) {
        block.push(stash.pop())
      }
      blocks.push(block)
      i += 1
    }
    return blocks
  }

  export default {
    props: {
      initialData: {
        type: String,
        required: true,
      },
    },
    data() {
      const data = JSON.parse(this.initialData)
      const params = new URLSearchParams(window.location.search)
      const filterParam = params.get('article_feed_filters') ?? ''
      return {
        items: data.items,
        teaserBlocks: prepareTeaserBlocks(data.items),
        currentPage: data.initial_page,
        numPages: data.number_of_pages,
        fixedTags: data.fixed_tags,
        tags: data.tags,
        activeTags: filterParam.split('|'),
        filter: {
          value: '',
        },
        host: null,
      }
    },
    methods: {
      async syncData() {
        // eslint-disable-next-line
        const lang = __js_bridge.currentLanguage
        const url = `/${lang}/content/api/article-feed?page=${
          this.currentPage
        }${
          this.activeTags.length ? `&filters=${this.activeTags.join('|')}` : 0
        }`
        try {
          const res = await fetch(url)
          const { items } = await res.json()
          this.syncHistory()
          this.teaserBlocks = prepareTeaserBlocks(items)
        } catch (e) {
          console.log(e)
        }
      },
      syncHistory() {
        const params = new URLSearchParams(window.location.search)
        params.set('article_feed_page', this.currentPage)
        params.set('article_feed_filters', this.activeTags.join('|'))
        // eslint-disable-next-line
        history.replaceState(null, null, '?' + params.toString())
      },
      toggleFilter(slug) {
        if (this.activeTags.includes(slug)) {
          this.activeTags = this.activeTags.filter((v) => v !== slug)
        } else {
          this.activeTags.push(slug)
        }
        this.syncData()
      },
      loadMore() {
        this.currentPage += 1
        this.syncData()
      },
    },
    mounted() {
      // eslint-disable-next-line
      this.host = `/${__js_bridge.currentLanguage}/inspiration/`
    },
  }
</script>
