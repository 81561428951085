
  import { defineComponent, PropType, ref } from 'vue'
  import StandardOverlay from '../standard-overlay/standard-overlay.vue'
  import translate from '../../../../util/misc/translate'
  import { OverlayIds } from '../../../../constants/overlay-ids'
  import SimpleButton from '../../buttons/simple-button/simple-button.vue'
  import useOverlay from '../../../../hooks/use-overlay/use-overlay'
  import useWatchlist from '../../../../hooks/use-watchlist/use-watchlist'
  import useSnackbar, {
    SnackbarType,
  } from '../../../../hooks/use-snackbar/use-snackbar'
  import { Watchlist } from '../../../../types/paloma-api-customers'

  export default defineComponent({
    components: { SimpleButton, StandardOverlay },
    props: {
      watchlist: {
        type: Object as PropType<Watchlist>,
        required: true,
      },
    },
    setup(props) {
      const [, overlayMethods] = useOverlay()
      const isLoading = ref<boolean>(false)
      const [, { deleteWatchlist }] = useWatchlist()
      const close = () => overlayMethods.close(OverlayIds.DELETE_WATCHLIST)
      const [, { show }] = useSnackbar()
      const onError = (error: Error) => {
        close()

        show({
          id: OverlayIds.DELETE_WATCHLIST,
          text: error.message,
          type: SnackbarType.ERROR,
        })
      }

      return {
        OverlayIds,
        translate,
        close,
        isLoading,
        deleteWatchlist() {
          isLoading.value = true
          deleteWatchlist(props.watchlist.watchlistId)
            .then(close)
            .catch(onError)
        },
      }
    },
  })
