import { add, remove } from 'bianco.events'
import { onUnmounted } from 'vue'

export default function useClickOutside(
  element: HTMLElement,
  callback: (event?: MouseEvent) => void,
) {
  const stopPropagation = (event: MouseEvent) => event.stopPropagation()

  add(element, 'click', stopPropagation)
  add(document, 'click', callback)

  onUnmounted(() => {
    remove(element, 'click', stopPropagation)
    remove(document, 'click', callback)
  })
}
