
  import { isNumber } from 'lodash/fp'
  import { defineComponent, computed, PropType } from 'vue'
  import iconSizes from '../../../../design-tokens/icon-sizes.json'

  export default defineComponent({
    props: {
      name: {
        type: String,
        required: true,
      },
      size: {
        type: [String, Number] as PropType<keyof typeof iconSizes | number>,
        required: true,
      },
      title: {
        type: String,
        default: null,
      },
    },
    setup(props) {
      const sizesToSemanticValues = Object.entries(iconSizes).reduce<
        Record<string, string>
      >((acc, [name, size]) => ({ ...acc, [size]: name }), {})

      const normalizeSize = (size: number | keyof typeof iconSizes) =>
        isNumber(size) ? size : iconSizes[size]

      const sizeToClass = (size: number | keyof typeof sizesToSemanticValues) =>
        isNumber(size) ? sizesToSemanticValues[size] : size

      return {
        ...props,
        className: ['icon', `icon--${sizeToClass(props.size)}`],
        iconPath: computed(
          () => `#ic_${normalizeSize(props.size)}_${props.name}`,
        ),
      }
    },
  })
