import { OverlayIds } from '../../constants/overlay-ids'
import { Watchlist } from '../../types/paloma-api-customers'

import CreateWatchlistOverlay from '../../global-components/common/overlay-types/create-watchlist-overlay/create-watchlist-overlay.vue'
import DeleteWatchlistOverlay from '../../global-components/common/overlay-types/delete-watchlist-overlay/delete-watchlist-overlay.vue'
import RenameWatchlistOverlay from '../../global-components/common/overlay-types/rename-watchlist-overlay/rename-watchlist-overlay.vue'
import AddProductToWatchlistOverlay from '../../global-components/common/overlay-types/add-product-to-watchlist-overlay/add-product-to-watchlist-overlay.vue'

import { Overlay } from '../../hooks/use-overlay/types'

export const createRenameWatchlistOverlay = (
  watchlist: Watchlist,
): Overlay => ({
  id: OverlayIds.RENAME_WATCHLIST,
  component: RenameWatchlistOverlay,
  props: {
    watchlist,
  },
})
export const creatDeleteWatchlistOverlay = (watchlist: Watchlist): Overlay => ({
  id: OverlayIds.DELETE_WATCHLIST,
  component: DeleteWatchlistOverlay,
  props: {
    watchlist,
  },
})

export const createCreateWatchlistOverlay = (): Overlay => ({
  id: OverlayIds.CREATE_WATCHLIST,
  component: CreateWatchlistOverlay,
  props: {},
})

export const createAddProductToWatchlistOverlay = (
  productId: string,
  unit: string,
): Overlay => ({
  id: OverlayIds.ADD_PRODUCT_TO_WATCHLIST,
  component: AddProductToWatchlistOverlay,
  props: {
    productId,
    unit,
  },
})
