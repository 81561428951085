import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, withCtx as _withCtx, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "products-finder" }
const _hoisted_2 = { class: "products-finder__container" }
const _hoisted_3 = ["accept"]
const _hoisted_4 = { class: "products-finder__dropzone-message" }
const _hoisted_5 = { class: "products-finder__form-fields" }
const _hoisted_6 = { class: "products-finder__textarea" }
const _hoisted_7 = { class: "products-finder__submit-button" }
const _hoisted_8 = {
  key: 0,
  class: "products-finder__toolbar u-reset"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!
  const _component_input_field = _resolveComponent("input-field")!
  const _component_spinner = _resolveComponent("spinner")!
  const _component_simple_button = _resolveComponent("simple-button")!
  const _component_inline_notification_list = _resolveComponent("inline-notification-list")!
  const _component_polling_export_button = _resolveComponent("polling-export-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("form", {
      class: "products-finder__form",
      ref: "form",
      onSubmit: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)), ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", {
          class: _normalizeClass(["products-finder__dropzone", _ctx.dropzoneClasses])
        }, [
          _createElementVNode("input", {
            class: "products-finder__file-input",
            type: "file",
            accept: _ctx.allowedFiles,
            name: "upload",
            onDrop: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onFileDrop && _ctx.onFileDrop(...args))),
            onDragenter: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onDragEnter && _ctx.onDragEnter(...args))),
            onDragleave: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onDragLeave && _ctx.onDragLeave(...args))),
            onChange: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onInputFileChange && _ctx.onInputFileChange(...args)))
          }, null, 40, _hoisted_3),
          _createElementVNode("div", _hoisted_4, [
            (_ctx.uploadedFileName)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createVNode(_component_icon, {
                    name: "add-document-done",
                    size: "medium"
                  }),
                  _createTextVNode(" " + _toDisplayString(_ctx.uploadedFileName), 1)
                ], 64))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createVNode(_component_icon, {
                    name: "add-document",
                    size: "medium"
                  }),
                  _createTextVNode(" " + _toDisplayString(_ctx.translate('shop__product_finder__drop_file__message')), 1)
                ], 64))
          ])
        ], 2),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_input_field, {
              rows: "10",
              name: "input",
              tag: "textarea",
              value: _ctx.textAreaValue,
              placeholder: 
                _ctx.translate('shop__product_finder__input_placeholder')
              ,
              onInput: _ctx.onTextareaValue
            }, null, 8, ["value", "placeholder", "onInput"])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_simple_button, {
              disabled: _ctx.isLoading || !_ctx.hasValuesToParse,
              facets: ['primary', 'outlined', 'round']
            }, {
              default: _withCtx(() => [
                (_ctx.isLoading)
                  ? (_openBlock(), _createBlock(_component_spinner, { key: 0 }))
                  : _createCommentVNode("", true),
                _createTextVNode(" " + _toDisplayString(_ctx.translate('shop__product_finder__submit_button')), 1)
              ]),
              _: 1
            }, 8, ["disabled"])
          ])
        ])
      ])
    ], 544),
    _createVNode(_component_inline_notification_list, { notifications: _ctx.notifications }, null, 8, ["notifications"]),
    (_ctx.isToolbarVisible)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_8, [
          _createElementVNode("li", null, [
            _createVNode(_component_simple_button, {
              facets: ['secondary', 'outlined', 'round'],
              onClick: _ctx.onReset
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.translate('shop__product_finder__reset_button')) + " ", 1),
                _createVNode(_component_icon, {
                  name: "close",
                  size: "medium"
                })
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _createElementVNode("li", null, [
            _createVNode(_component_polling_export_button, { "export-url": _ctx.fileExportUrl }, null, 8, ["export-url"])
          ]),
          _createElementVNode("li", null, [
            _createVNode(_component_simple_button, {
              disabled: _ctx.isAddingProductsToTheCart,
              facets: ['primary', 'round'],
              onClick: _ctx.addProductsToCart
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.translate('shop__product_finder__add_to_cart_button', {
              amount: _ctx.totalProductsAmount,
            })) + " ", 1),
                (_ctx.isAddingProductsToTheCart)
                  ? (_openBlock(), _createBlock(_component_spinner, { key: 0 }))
                  : (_openBlock(), _createBlock(_component_icon, {
                      key: 1,
                      name: "cart",
                      size: "medium"
                    }))
              ]),
              _: 1
            }, 8, ["disabled", "onClick"])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}