
  import { computed, defineComponent, PropType } from 'vue'
  import { InlineNotificationType } from './types'
  import Icon from '../icon/icon.vue'

  export default defineComponent({
    components: { Icon },
    props: {
      message: {
        type: String,
        required: true,
      },
      type: {
        type: String as PropType<InlineNotificationType>,
        required: true,
      },
    },
    setup(props) {
      return {
        icon: computed(() => ({
          name:
            props.type === InlineNotificationType.SUCCESS
              ? 'check'
              : 'error-outline',
          size: 'medium',
        })),
        rootClasses: computed(() => `inline-notification--${props.type}`),
      }
    },
  })
